import roles, { Administrators } from "../roles";

export const Loyalty = {
    Customers: {
        Data: Administrators,
        Search: Administrators,
        Filter: Administrators,
        Export: [roles.SuperAdministrator],
        SetupColumn: Administrators,
        AccruePoints: Administrators,
        WriteOffPoints: Administrators,
        PointsToLoan: Administrators,
        PointsToDeposit: Administrators,
        DonatePoints: Administrators
    },
    Settings: {
        All: Administrators,
        Data: Administrators,
        Add: Administrators
    }
};
