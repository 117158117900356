import { addItem, editItem, getItem } from "./crud.js";

const url = "/loanApplication";

export default url;

export function create(data)
{
    return addItem(url, data);
}

export function getDetails(id)
{
    return getItem(url, id);
}

export function assign(id)
{
    const formData = new FormData();

    formData.append("id", id);

    return editItem(`${url}/assign`, formData);
}

export function status(status)
{
    return editItem(`${url}/status`, status);
}
