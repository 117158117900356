import { useState } from "react";
import { connect } from "react-redux";
import { Redirect, Link, Switch, Route } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { Form, Input, Button, message, Spin, Menu } from "antd";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { restorePassword } from "@/api/auth.js";
import CountdownButton from "@/components/CountdownButton";
import onCatchRequestError from "@/components/OnCatchRequestError";
import "./index.less";

const title = "Восстановление пароля";

const Recovery = props =>
{
    const [loading, setLoading] = useState(false);
    const [codeSended, setCodeSended] = useState(false);
    const [userName, setUserName] = useState("");
    const [code, setCode] = useState("");

    const onFinish = values =>
    {
        setLoading(true);

        const { username } = values;

        restorePassword({ username })
            .then(() =>
            {
                message.success("Код подтверждения отправлен");
                setCodeSended(true);
                setUserName(username);
            })
            .catch(onCatchRequestError)
            .finally(() => setLoading(false));
    };

    const rerequestCode = () =>
    {
        onFinish({ username: userName });
    };

    const onCodeInput = () =>
    {
        const { history } = props;
        const searchParams = new URLSearchParams();

        searchParams.append("username", userName);
        searchParams.append("code", code);

        history.push({
            pathname: "/recovery/confirmed",
            search: searchParams.toString()
        });
    };

    const { location } = props;
    const { pathname } = location;
    const byPhone = pathname.endsWith("bysms");

    return (
        <DocumentTitle title={title}>
            <div className="auth-container">
                {
                    codeSended
                        ? (<div className="content">
                            <h2 className="title">
                                {
                                    byPhone
                                        ? "Код восстановления пароля отправлен на Ваш номер телефона"
                                        : "Ссылка восстановления пароля отправлена Вам на эл. почту"
                                }
                            </h2>
                            <div
                                className="centred"
                                style={{ marginBottom: `${byPhone ? 30 : 65}px` }}
                            >
                                {
                                    byPhone
                                        ? `Введите 6-значный код из SMS, которое мы отправили Вам на номер ${userName}`
                                        : "Перейдите по ней из письма для установки нового пароля"
                                }
                            </div>
                            {
                                byPhone &&
                                    <Input
                                        onInput={e => setCode(e.target.value)}
                                        onPressEnter={onCodeInput}
                                        placeholder="6-значный код из СМС"
                                        style={{ marginBottom: "39px" }}
                                        value={code}
                                    />
                            }
                            <div className="centred">
                                {
                                    byPhone
                                        ? "Код не пришёл?"
                                        : "Письмо со ссылкой не пришло?"
                                }
                                <br/>
                                <CountdownButton
                                    handler={rerequestCode}
                                    seconds={30}
                                    text="Отправить повторно"
                                />
                            </div>
                        </div>)
                        : (
                            <Form
                                className="content"
                                onFinish={onFinish}
                            >
                                <h2 className="title-without-margin">{title}</h2>
                                <Spin
                                    spinning={loading}
                                    tip="подождите..."
                                >
                                    <Menu
                                        className="recovery-menu"
                                        defaultSelectedKeys={[pathname]}
                                        mode="horizontal"
                                    >
                                        <Menu.Item key="/recovery/bymail">
                                            <Link
                                                to={{
                                                    pathname: "/recovery/bymail"
                                                }}
                                            >
                                                <span>По эл. почте</span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="/recovery/bysms">
                                            <Link
                                                to={{
                                                    pathname: "/recovery/bysms"
                                                }}
                                            >
                                                <span>По СМС</span>
                                            </Link>
                                        </Menu.Item>
                                    </Menu>

                                    <Switch location={location}>
                                        <Route
                                            key="/recovery/bymail"
                                            path="/recovery/bymail"
                                        >
                                            <Form.Item
                                                name="username"
                                                rules={[
                                                    {
                                                        isRequired: true,
                                                        message: "Пожалуйста, введите e-mail"
                                                    },
                                                    {
                                                        type: "email",
                                                        message: "Недопустимое значение для e-mail"
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder="E-mail"
                                                    prefix={<UserOutlined className="placeholder" />}
                                                />
                                            </Form.Item>
                                        </Route>
                                        <Route
                                            key="/recovery/bysms"
                                            path="/recovery/bysms"
                                        >
                                            <Form.Item
                                                name="username"
                                                rules={[
                                                    {
                                                        isRequired: true,
                                                        whitespace: true,
                                                        message: "Пожалуйста, введите номер телефона"
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Номер телефона"
                                                    prefix={<PhoneOutlined className="placeholder" />}
                                                />
                                            </Form.Item>
                                        </Route>
                                        <Redirect to="/404" />
                                    </Switch>

                                    <Form.Item>
                                        <Button
                                            className="full-width"
                                            htmlType="submit"
                                            type="primary"
                                        >
                                            Восстановить пароль
                                        </Button>
                                    </Form.Item>
                                    <div
                                        style={{ textAlign: "center" }}
                                    >
                                        <Link to={{ pathname: "/login" }}>Назад к авторизации</Link>
                                    </div>
                                </Spin>
                            </Form>
                        )

                }
            </div>
        </DocumentTitle>
    );
};

export default connect(
    state => state.user
)(Recovery);
