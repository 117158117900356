import { withRouter } from "react-router-dom";
import { Button, Radio, DatePicker, Col, Row, Spin, Input } from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";

import { dateFormat, firstDate, genderType } from "@/config/constants";
import Filter from "@/components/Filter";

const { RangePicker } = DatePicker;

class EmployeesFilter extends Filter
{
    constructor(props)
    {
        super(props);

        this.state = {
            ...this.state,

            loading: false,
            preloadedThreshold: 0,
            singles: ["gender"],
            ranges: ["birthday", "hiredAt"],
            multiples: ["office:Name", "office:Region", "office:City", "lastName", "firstName", "middleName", "position"]
        };
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render()
    {
        const {
            loading,
            gender,
            birthday, hiredAt,
            lastName, firstName, middleName, position
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={22}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Фамилии</Col>
                            <Col span={16}>
                                {
                                    lastName
                                        ? lastName.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("lastName", event.target.value, index)}
                                                    placeholder="Введите фамилию"
                                                    value={lastName[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("lastName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("lastName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Имена</Col>
                            <Col span={16}>
                                {
                                    firstName
                                        ? firstName.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("firstName", event.target.value, index)}
                                                    placeholder="Введите имя"
                                                    value={firstName[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("firstName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("firstName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Отчества</Col>
                            <Col span={16}>
                                {
                                    middleName
                                        ? middleName.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("middleName", event.target.value, index)}
                                                    placeholder="Введите отчество"
                                                    value={middleName[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("middleName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("middleName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Офисы</Col>
                            <Col span={16}>
                                {
                                    this.state["office:Name"]
                                        ? this.state["office:Name"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("office:Name", event.target.value, index)}
                                                    placeholder="Введите офис"
                                                    value={this.state["office:Name"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("office:Name", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("office:Name")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Регионы</Col>
                            <Col span={16}>
                                {
                                    this.state["office:Region"]
                                        ? this.state["office:Region"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("office:Region", event.target.value, index)}
                                                    placeholder="Введите регион"
                                                    value={this.state["office:Region"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("office:Region", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("office:Region")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Города</Col>
                            <Col span={16}>
                                {
                                    this.state["office:City"]
                                        ? this.state["office:City"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("office:City", event.target.value, index)}
                                                    placeholder="Введите город"
                                                    value={this.state["office:City"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("office:City", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("office:City")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Должности</Col>
                            <Col span={16}>
                                {
                                    position
                                        ? position.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("position", event.target.value, index)}
                                                    placeholder="Введите город"
                                                    value={position[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("position", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("position")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Пол</Col>
                            <Col span={16}>
                                <Radio.Group
                                    className="full-width d-flex"
                                    onChange={event => this.onSelectorChange("gender", event.target.value)}
                                    value={gender}
                                >
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={genderType.female}
                                    >
                                        Женский
                                    </Radio.Button>
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={genderType.male}
                                    >
                                        Мужской
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Дата рождения</Col>
                            <Col span={16}>
                                <RangePicker
                                    className="full-width"
                                    disabledDate={date => !date || date.isAfter(new Date()) || date.isSameOrBefore(firstDate)}
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("birthday", dateString)}
                                    value={
                                        birthday?.length && birthday.every(date => date)
                                            ? [moment(birthday[0], dateFormat), moment(birthday[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Работает с</Col>
                            <Col span={16}>
                                <RangePicker
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("hiredAt", dateString)}
                                    value={
                                        hiredAt?.length && hiredAt.every(date => date)
                                            ? [moment(hiredAt[0], dateFormat), moment(hiredAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={2} />
                </Row>

                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(EmployeesFilter);
