import { Component } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { Redirect, withRouter, Route, Switch } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { Layout } from "antd";

import { toggleMode } from "@/store/actions";
import { USER_PANEL, ADMIN_PANEL } from "@/store/toggleMode-types.js";
import { projectName } from "@/config/constants";
import routeList from "@/config/menu/routeMap.js";
import adminRouteList from "@/config/menu/adminPanel/routeMap.js";
import menuList from "@/config/menu/menuConfig.jsx";
import adminMenuList from "@/config/menu/adminPanel/menuConfig.jsx";
import clientMenuList from "@/config/menu/clientMenuConfig.js";
import { Customer } from "@/config/permissions";
import Profile from "@/views/profile";
import Update from "@/views/profile/Update.jsx";

const { Content } = Layout;

const CLIENT_PATHS = routeList.map(({ path }) => path);
const ADMIN_PATHS = adminRouteList.map(({ path }) => path);

class LayoutContent extends Component
{
    static displayName = "LayoutContent";

    constructor(props)
    {
        super(props);

        this.unlisten = Function;

        const { modeType } = this.props;

        this.state = {
            modeType
        };

        this.handleToggleMode = this.handleToggleMode.bind(this);
    }

    handleToggleMode(modeType) {
        const { toggleMode } = this.props;

        const isUserPanel = modeType === USER_PANEL;
        const isAdminPanel = modeType === ADMIN_PANEL;
        const isIncludeAdminPaths = ADMIN_PATHS.some(path => matchPath(location.pathname, { path }));
        const isIncludeClientPaths = CLIENT_PATHS.some(path => matchPath(location.pathname, { path }));

        if (isUserPanel && isIncludeAdminPaths || isAdminPanel && isIncludeClientPaths)
        {
            toggleMode(false);
        }

        console.info({ modeType, isUserPanel, isAdminPanel, isIncludeAdminPaths, isIncludeClientPaths });
    }

    getUserPanelDefaultRoute()
    {
        const { role } = this.props;

        return !Customer.includes(role) ? "/customer" : "/deposit";
    }

    getPageTitle(menuList, pathname)
    {
        let title = projectName;

        menuList.map(el =>
        {
            if (pathname.includes(el.path))
            {
                title = el.title;
            }

            return el;
        });

        const urlPath = pathname.split("/");
        if (urlPath[1] === "customers" && urlPath.length >= 4)
        {
            clientMenuList.map(el =>
            {
                if (pathname.includes(el.path))
                {
                    title = el.title;
                }

                return el;
            });
        }

        return title;
    }

    componentDidMount() {
        const { history, modeType } = this.props;

        this.handleToggleMode(modeType);

        this.unlisten = history.listen((location, action) =>
        {
            if (action === "PUSH" || action === "POP")
            {
                const { modeType } = this.props;

                this.handleToggleMode(modeType);
            }
        });
    }

    componentDidUpdate()
    {
        const { modeType } = this.state;
        const newMode = this.props.modeType;

        if (modeType !== newMode)
        {
            this.setState({ modeType: newMode });

            const { redirectToRoot } = this.props;

            if (redirectToRoot)
            {
                const { history } = this.props;

                history.push({
                    pathname: newMode === USER_PANEL
                        ? this.getUserPanelDefaultRoute()
                        : "/product"
                });
            }
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render()
    {
        const { modeType, location, role } = this.props;
        const { pathname } = location;

        const isCustomer = Customer.includes(role);
        const allRoutes = [...routeList, ...adminRouteList];

        return (
            <DocumentTitle title={this.getPageTitle(modeType === USER_PANEL ? menuList : adminMenuList, pathname)}>
                <Content>
                    <Switch location={location}>
                        {isCustomer ?
                            <Redirect exact from="/" to="/profile" />
                            :
                            modeType === USER_PANEL
                                ?
                                <Redirect exact from="/" to={this.getUserPanelDefaultRoute()} />
                                :
                                <Redirect exact from="/" to="/product" />
                        }
                        {
                            allRoutes.map(route =>
                                <Route
                                    component={route.component}
                                    key={route.path}
                                    path={route.path}
                                />)
                        }
                        <Route
                            component={Profile}
                            exact
                            key="/profile"
                            path="/profile"
                        />
                        <Route
                            component={Update}
                            key="/profile/update"
                            path="/profile/update"
                        />
                        <Redirect to="/404" />
                    </Switch>
                </Content>
            </DocumentTitle>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.app }),
    { toggleMode }
)(withRouter(LayoutContent));
