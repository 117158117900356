import { useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, Layout, Avatar, Button } from "antd";
import { CaretDownOutlined, UserOutlined } from "@ant-design/icons";

import { logout, toggleMode } from "@/store/actions";
import { USER_PANEL } from "@/store/toggleMode-types.js";
import UserMenu from "./UserMenu";
import { Customer, Administration } from "@/config/permissions";

import "./index.less";

const { Header } = Layout;

const userPanelName = "Управление клиентами";
const userCabinet = "Личный кабинет";
const adminPanelName = "Администрирование";

const LayoutHeader = props =>
{
    const {
        history,
        avatar, name, email, role,
        toggleMode, modeType
    } = props;

    const openNotifications = () => history.push({ pathname: "notifications" });

    const handleToggleMode = useCallback(toggleMode, [toggleMode]);

    const isUserPanel = modeType === USER_PANEL;
    const includesRole = !Customer.includes(role) ? userPanelName : userCabinet;

    return (
        <Header>
            <span className="header-name">
                {
                    isUserPanel
                        ? includesRole
                        : adminPanelName
                }
            </span>
            <div className="right-menu">
                {
                    !!Administration.Section.includes(role) && (
                        <Button onClick={handleToggleMode}>
                            {
                                isUserPanel
                                    ? adminPanelName
                                    : userPanelName
                            }
                        </Button>
                    )
                }
                {
                    Customer.includes(role) && (
                        <Button onClick={openNotifications}>Уведомления</Button>
                    )
                }
                {
                    // <Button onClick={() => buttonLogger("Сообщения")}>Сообщения</Button>
                }
                <div className="dropdown-wrap">
                    <Dropdown overlay={<UserMenu {...props} />} trigger={["click"]}>
                        <div>
                            <Avatar
                                icon={<UserOutlined />}
                                shape="square"
                                size="medium"
                                src={avatar}
                            />
                            <div className="header-username-wrapper">
                                <span className="header-username">{name}</span>
                                <span>{email}</span>
                            </div>
                            <CaretDownOutlined />
                        </div>
                    </Dropdown>
                </div>
            </div>
        </Header>
    );
};

export default connect(
    state => ({ ...state.user, ...state.app }),
    { logout, toggleMode }
)(withRouter(LayoutHeader));

LayoutHeader.displayName = "LayoutHeader";
