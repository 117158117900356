import { useState, useEffect, useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import { Input, Button, message, Spin } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { FormItem, useForm, Form } from "@/components/Form";
import { login } from "@/store/actions";
import "./index.less";

const title = "Авторизация";

const Login = props =>
{
    const { token, login } = props;

    const [loading, setLoading] = useState(false);

    const formRef = useRef(null);

    const { errors, onChange, onCatchRequestError } = useForm();

    useEffect(
        () =>
        {
            formRef.current?.validateFields();
        },
        [errors, formRef]
    );

    if (token)
    {
        return <Redirect to="/" />;
    }

    const onFinish = values =>
    {
        setLoading(true);

        const { username, password } = values;

        login(username, password)
            .then(data =>
            {
                message.success("Успех");
            })
            .catch(onCatchRequestError)
            .finally(() => setLoading(false));
    };

    return (
        <DocumentTitle title={title}>
            <div className="auth-container">
                <Form
                    className="content"
                    initialValues={{ remember: true }}
                    ref={formRef}
                    onFinish={onFinish}
                    wrapperCol={{
                        span: 30
                    }}
                >
                    <h2 className="title">{title}</h2>
                    <Spin
                        spinning={loading}
                        tip="Авторизуемся..."
                    >
                        <FormItem
                            name="username"
                            errors={errors?.UserName}
                            onChange={() => onChange("userName")}
                            rules={[
                                {
                                    isRequired: true,
                                    whitespace: true,
                                    message: "Пожалуйста, введите e-mail"
                                }
                            ]}
                        >
                            <Input
                                placeholder="E-mail"
                                prefix={<UserOutlined className="placeholder" />}
                            />
                        </FormItem>
                        <FormItem
                            name="password"
                            errors={errors?.Password}
                            onChange={() => onChange("password")}
                            rules={[
                                {
                                    isRequired: true,
                                    whitespace: true,
                                    message: "Пожалуйста, введите пароль"
                                }
                            ]}
                        >
                            <Input
                                placeholder="Пароль"
                                prefix={<LockOutlined className="placeholder" />}
                                type="password"
                            />
                        </FormItem>
                        <FormItem
                            className="small-indent"
                        >
                            <Button
                                className="full-width"
                                htmlType="submit"
                                type="primary"
                            >
                                Войти
                            </Button>
                        </FormItem>
                        <FormItem
                            className="forgot"
                        >
                            <Link to={{ pathname: "/recovery/bymail" }}>Восстановить пароль</Link>
                        </FormItem>
                        {/* или <Link to={{ pathname: "/registration" }}>зарегистрируйтесь сейчас!</Link> */}
                    </Spin>
                </Form>
            </div>
        </DocumentTitle>
    );
};

export default connect(
    state => state.user,
    { login }
)(Login);
