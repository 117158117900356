import { message } from "antd";
import onCatchRequestError from "@/components/OnCatchRequestError";
import request from "@/utils/request.js";

const isPromise = v => typeof v === "object" && typeof v.then === "function";

export const downloadStaticPromise = fileResponce =>
    (isPromise(fileResponce)
        ? fileResponce
        : request({ url: fileResponce, method: "get" }))
        .then(response =>
        {
            const { meta: { status }, data, errors } = response.data;

            if (status === 200)
            {
                const link = document.createElement("a");
                link.href = `${process.env.REACT_APP_BASE_API}${data.Path}`;
                link.setAttribute("download", data.Name);

                document.body.appendChild(link);

                link.click();

                link.remove();
            }
            else
            {
                message.error(errors[0]);
            }
        })
        .catch(onCatchRequestError);

export default function downloadStatic(fileResponce, setState)
{
    setState({ loading: true });

    downloadStaticPromise(fileResponce)
        .finally(() => setState({ loading: false }));
}
