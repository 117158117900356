import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
    easing: "ease",
    showSpinner: false,
    speed: 200,
    minimum: 0.15
});

export default NProgress;
