import { withRouter } from "react-router-dom";
import { Button, Radio, DatePicker, Col, Row, Spin, Input } from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";
import { dateFormat } from "@/config/constants";
import Filter from "@/components/Filter";

const { RangePicker } = DatePicker;

class LoyaltiesFilter extends Filter
{
    constructor(props)
    {
        super(props);

        this.state = {
            ...this.state,

            loading: false,
            preloadedThreshold: 0,
            singles: ["isInCooperation"],
            ranges: ["createdAt"],
            multiples: ["lastName", "firstName", "middleName"]
        };
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render()
    {
        const {
            loading,
            isInCooperation,
            createdAt,
            lastName, firstName, middleName
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={22}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Фамилии</Col>
                            <Col span={16}>
                                {
                                    lastName
                                        ? lastName.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("lastName", event.target.value, index)}
                                                    placeholder="Введите фамилию"
                                                    value={lastName[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("lastName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("lastName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Имена</Col>
                            <Col span={16}>
                                {
                                    firstName
                                        ? firstName.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("firstName", event.target.value, index)}
                                                    placeholder="Введите имя"
                                                    value={firstName[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("firstName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("firstName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Отчества</Col>
                            <Col span={16}>
                                {
                                    middleName
                                        ? middleName.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("middleName", event.target.value, index)}
                                                    placeholder="Введите отчество"
                                                    value={middleName[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("middleName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("middleName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Состоит ли в кооперативе</Col>
                            <Col span={16}>
                                <Radio.Group
                                    className="full-width d-flex"
                                    onChange={event => this.onSelectorChange("isInCooperation", event.target.value)}
                                    value={isInCooperation}
                                >
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={true}
                                    >
                                        Да
                                    </Radio.Button>
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={false}
                                    >
                                        Нет
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Дата регистрации</Col>
                            <Col span={16}>
                                <RangePicker
                                    className="full-width"
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("createdAt", dateString)}
                                    value={
                                        createdAt?.length && createdAt.every(date => date)
                                            ? [moment(createdAt[0], dateFormat), moment(createdAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={2} />
                </Row>

                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(LoyaltiesFilter);
