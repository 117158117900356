import { Component } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { toggleSettingPanel } from "@/store/actions";

class TableCustomization extends Component
{
    constructor(props)
    {
        super(props);

        const { data: { columns } } = this.props;

        this.state = {
            columns
        };
    }

    setCustomization = () =>
    {
        const { toggleSettingPanel } = this.props;

        toggleSettingPanel();
    }

    onColumnChange(event, key)
    {
        this.setState(prevState =>
        {
            prevState.columns.filter(column => column.key === key)[0].isVisible = event.target.checked;

            return { prevState };
        });
    }

    render()
    {
        const { columns } = this.state;

        return <>
            <Row
                className="row"
                style={{ marginBottom: 20 }}
            >
                {
                    columns
                        .filter(({ title }) => !!title)
                        .map(({ key, isVisible, title }) => (
                            <Col key={key} span={24}>
                                <Checkbox
                                    defaultChecked={isVisible}
                                    onChange={event => this.onColumnChange(event, key)}
                                    style={{ marginBottom: 10 }}
                                >
                                    {title}
                                </Checkbox>
                            </Col>
                        ))
                }
            </Row>
            <Row className="row">
                <Button
                    className="green-button"
                    icon={<CheckOutlined />}
                    onClick={this.setCustomization}
                    type="primary"
                >
                    Применить
                </Button>
            </Row>
        </>;
    }
}

export default connect(
    null,
    { toggleSettingPanel }
)(TableCustomization);
