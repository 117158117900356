import { withRouter } from "react-router-dom";
import { Button, DatePicker, Col, Row, Spin, Input, Select } from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";
import { dateFormat } from "@/config/constants";
import Filter from "@/components/Filter";

const { RangePicker } = DatePicker;

class RoomsFilter extends Filter
{
    constructor(props)
    {
        super(props);

        this.state = {
            ...this.state,

            loading: false,
            preloadedThreshold: 0,
            singles: ["category", "appealType", "status"],
            ranges: ["createdAt", "closedAt"],
            multiples: ["customer:LastName", "customer:FirstName", "customer:MiddleName"]
        };
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render()
    {
        const {
            loading,
            createdAt, closedAt, category, appealType, status
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={22}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Фамилии</Col>
                            <Col span={16}>
                                {
                                    this.state["customer:LastName"]
                                        ? this.state["customer:LastName"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("customer:LastName", event.target.value, index)}
                                                    placeholder="Введите фамилию"
                                                    value={this.state["customer:LastName"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("customer:LastName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("customer:LastName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Имена</Col>
                            <Col span={16}>
                                {
                                    this.state["customer:FirstName"]
                                        ? this.state["customer:FirstName"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("customer:FirstName", event.target.value, index)}
                                                    placeholder="Введите имя"
                                                    value={this.state["customer:FirstName"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("customer:FirstName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("customer:FirstName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Отчества</Col>
                            <Col span={16}>
                                {
                                    this.state["customer:MiddleName"]
                                        ? this.state["customer:MiddleName"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("customer:MiddleName", event.target.value, index)}
                                                    placeholder="Введите отчество"
                                                    value={this.state["customer:MiddleName"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("customer:MiddleName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("customer:MiddleName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Дата открытия</Col>
                            <Col span={16}>
                                <RangePicker
                                    className="full-width"
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("createdAt", dateString)}
                                    value={
                                        createdAt?.length && createdAt.every(date => date)
                                            ? [moment(createdAt[0], dateFormat), moment(createdAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Дата закрытия</Col>
                            <Col span={16}>
                                <RangePicker
                                    className="full-width"
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("closedAt", dateString)}
                                    value={
                                        closedAt?.length && closedAt.every(date => date)
                                            ? [moment(closedAt[0], dateFormat), moment(closedAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Категории</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => this.onSelectorChange("category", value)}
                                    placeholder="Выберите категорию"
                                    value={category}
                                >
                                    <Select.Option value={1}>Запрос</Select.Option>
                                    <Select.Option value={2}>Обращение</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Типы</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => this.onSelectorChange("appealType", value)}
                                    placeholder="Выберите тип"
                                    value={appealType}
                                >
                                    <Select.Option value="Общие">Общие</Select.Option>
                                    <Select.Option value="По сбережения">По сбережениям</Select.Option>
                                    <Select.Option value="По займам">По займам</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Статусы</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => this.onSelectorChange("status", value)}
                                    placeholder="Выберите категорию"
                                    value={status}
                                >
                                    <Select.Option value="Закрыто">Закрыто</Select.Option>
                                    <Select.Option value="Открыто">Открыто</Select.Option>
                                    <Select.Option value="Новое">Новое</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={2} />
                </Row>

                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(RoomsFilter);
