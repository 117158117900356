import {
    DefaultTableFunctionality,
    Employees,
    AllExceptCustomerAndScoringAndLoanManager,
    SuperAdministratorAndCustomerAndDepositManager
} from "../roles";

export const Applications = {
    All: {
        Data: Employees
    },
    New: {
        Data: Employees,
        Details: Employees,
        Assign: Employees,
        Approve: Employees,
        Abort: Employees,
        Archive: Employees,
        ...DefaultTableFunctionality
    },
    Archive: {
        Data: Employees,
        Open: Employees,
        Restore: Employees,
        ...DefaultTableFunctionality
    },
    Deposit: {
        Data: AllExceptCustomerAndScoringAndLoanManager,
        Search: AllExceptCustomerAndScoringAndLoanManager,
        Filter: AllExceptCustomerAndScoringAndLoanManager,
        Add: AllExceptCustomerAndScoringAndLoanManager,
        SetupColumn: AllExceptCustomerAndScoringAndLoanManager
    },
    Loan: {
        Data: SuperAdministratorAndCustomerAndDepositManager,
        Search: SuperAdministratorAndCustomerAndDepositManager,
        Filter: SuperAdministratorAndCustomerAndDepositManager,
        Add: SuperAdministratorAndCustomerAndDepositManager,
        SetupColumn: SuperAdministratorAndCustomerAndDepositManager
    }
};
