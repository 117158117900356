import { Button } from "antd";

const NotFound = props =>
{
    const { history } = props;

    const goHome = () =>
        history.replace("/");

    return <>
        <h1>404</h1>
        <h2>not found</h2>
        <div>
            <Button onClick={goHome} type="primary">
                Перейти на домашнюю страницу
            </Button>
        </div>
    </>;
};

export default NotFound;
