import upperFirst from "lodash/upperFirst";
import {
    Form,
    Button,
    Space,
    Input
} from "antd";
import {
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";

import { useForm, FormItem } from "@/components/Form";

import ValidationRules from "./ValidationRules";

export function MultipleFormList({
    addBtnLabel = "",
    fileds = [],
    errors = [],
    name = "",
    isRequired = false
})
{
    const { onChange } = useForm();

    return (
        <Form.List
            name={name}
            rules={ValidationRules(errors)}
        >
            {(fields, { add, remove }, { errors }) =>
                <>
                    {
                        fields.map((field, index) =>
                            <Space
                                align="baseline"
                                className="d-flex"
                                key={field.key}
                                style={{ marginBottom: 20 }}
                            >
                                {
                                    fileds.map((_, j) =>
                                    {
                                        const { label, placeholder, property, suffix } = fileds[j];

                                        return (
                                            <FormItem
                                                label={label}
                                                name={[index, property]}
                                            >
                                                <Input
                                                    {...field}
                                                    onChange={() => onChange(`${upperFirst(name)}[0]`)}
                                                    placeholder={placeholder}
                                                    rules={[{ isRequired }]}
                                                    suffix={suffix}
                                                />
                                            </FormItem>
                                        );
                                    })
                                }
                                <Button
                                    danger
                                    icon={<MinusCircleOutlined />}
                                    onClick={() => remove(field.name)}
                                    size="small"
                                    type="link"
                                />
                            </Space>
                        )
                    }
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                        size="small"
                        type="dashed"
                    >
                        {addBtnLabel}
                    </Button>
                    <Form.ErrorList errors={errors} />
                </>
            }
        </Form.List>
    );
}
