import { Component } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import routeList from "@/config/menu/routeMap.js";
import adminRouteList from "@/config/menu/adminPanel/routeMap.js";
import { Link } from "react-router-dom";

class SmartLink extends Component
{
    constructor(props)
    {
        super(props);

        const { role } = this.props;

        this.state = {
            allPaths: [...routeList, ...adminRouteList].filter(x => !x.roles || x.roles.includes(role))
                .map(x => x.path)
        };
    }

    render()
    {
        const { to: { pathname } } = this.props;
        const { allPaths } = this.state;

        return allPaths.some(path => matchPath(pathname, { path })) ?
            <Link
                onClick={this.updatePeriod}
                { ...this.props }
            /> :
            <span
                { ...this.props }
                className=""
            />;
    }
}

export default connect(
    state => state.user
)(withRouter(SmartLink));
