import roles,
{
    SuperAdminAndAdminAndOfficeManager,
    Administrators
} from "../roles";

export const Employees = {
    All: {
        Data: SuperAdminAndAdminAndOfficeManager,
        Search: Administrators,
        Filter: Administrators,
        SetupColumn: Administrators,
        Add: Administrators,
        Export: [roles.SuperAdministrator]
    },
    Archive: {
        Data: SuperAdminAndAdminAndOfficeManager,
        Search: Administrators,
        Filter: Administrators,
        SetupColumn: Administrators,
        Add: Administrators
    },
    Employee: {
        Profile: {
            Data: SuperAdminAndAdminAndOfficeManager,
            Edit: SuperAdminAndAdminAndOfficeManager,
            Archive: SuperAdminAndAdminAndOfficeManager,
            Recover: SuperAdminAndAdminAndOfficeManager
        },
        Journal: {
            Data: SuperAdminAndAdminAndOfficeManager,
            Search: SuperAdminAndAdminAndOfficeManager,
            Filter: SuperAdminAndAdminAndOfficeManager,
            SetupColumn: SuperAdminAndAdminAndOfficeManager
        }
    }
};
