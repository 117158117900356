/* eslint-disable */

import Loadable from "react-loadable";
import Loading from "@/components/Loading";
import { Administration } from "@/config/permissions";

/*
 * Заглушка для ещё нереализованных страниц
 */
const NotImplemented = Loadable({
    loader: () => import("@/views/notImplemented"),
    loading: Loading,
});


/*
 * Сотрудники
 */
const EmployeeCreate = Loadable({
    loader: () => import("@/views/adminPanel/employees/employee/Create.jsx"),
    loading: Loading,
});
const EmployeeUpdate = Loadable({
    loader: () => import("@/views/adminPanel/employees/employee/Create.jsx"),
    loading: Loading,
});
const Employee = Loadable({
    loader: () => import("@/views/adminPanel/employees/employee"),
    loading: Loading,
});
const Employees = Loadable({
    loader: () => import("@/views/adminPanel/employees"),
    loading: Loading,
});
const EmployeesArchive = Loadable({
    loader: () => import("@/views/adminPanel/employees/EmployeesArchive.jsx"),
    loading: Loading,
});


/*
 * Офисы
 */
const OfficeCreate = Loadable({
    loader: () => import("@/views/adminPanel/offices/office/Create.jsx"),
    loading: Loading,
});
const OfficeUpdate = Loadable({
    loader: () => import("@/views/adminPanel/offices/office/Create.jsx"),
    loading: Loading,
});
const Office = Loadable({
    loader: () => import("@/views/adminPanel/offices/office"),
    loading: Loading,
});
const Offices = Loadable({
    loader: () => import("@/views/adminPanel/offices"),
    loading: Loading,
});


/*
 * Новости
 */
const NewsCreate = Loadable({
    loader: () => import("@/views/adminPanel/news/oneNews/Create.jsx"),
    loading: Loading,
});
const NewsUpdate = Loadable({
    loader: () => import("@/views/adminPanel/news/oneNews/Create.jsx"),
    loading: Loading,
});
//const OneNews = Loadable({
//    loader: () => import("@/views/adminPanel/news/oneNews/Details"),
//    loading: Loading,
//});
const News = Loadable({
    loader: () => import("@/views/adminPanel/news"),
    loading: Loading,
});


/*
 * Статичные страницы (статика)
 */
const StaticPageCreate = Loadable({
    loader: () => import("@/views/adminPanel/staticPages/staticPage/Create.jsx"),
    loading: Loading,
});
const StaticPageUpdate = Loadable({
    loader: () => import("@/views/adminPanel/staticPages/staticPage/Create.jsx"),
    loading: Loading,
});
const StaticPages = Loadable({
    loader: () => import("@/views/adminPanel/staticPages"),
    loading: Loading,
});


/*
 * FAQ
 */
const FAQCreate = Loadable({
    loader: () => import("@/views/adminPanel/faq/oneFaq/Create.jsx"),
    loading: Loading,
});
const FAQUpdate = Loadable({
    loader: () => import("@/views/adminPanel/faq/oneFaq/Create.jsx"),
    loading: Loading,
});
const FAQs = Loadable({
    loader: () => import("@/views/adminPanel/faq"),
    loading: Loading,
});


/*
 * Продукты
 */
const Products = Loadable({
    loader: () => import("@/views/adminPanel/products/common"),
    loading: Loading,
});
const DepositProductCreate = Loadable({
    loader: () => import("@/views/adminPanel/products/deposit/Create.jsx"),
    loading: Loading,
});
const DepositProduct = Loadable({
    loader: () => import("@/views/adminPanel/products/deposit/Details.jsx"),
    loading: Loading,
});
const DepositProducts = Loadable({
    loader: () => import("@/views/adminPanel/products/deposit"),
    loading: Loading,
});
const LoanProductCreate = Loadable({
    loader: () => import("@/views/adminPanel/products/loan/Create.jsx"),
    loading: Loading,
});
const LoanProduct = Loadable({
    loader: () => import("@/views/adminPanel/products/loan/Details.jsx"),
    loading: Loading,
});
const LoanProducts = Loadable({
    loader: () => import("@/views/adminPanel/products/loan"),
    loading: Loading,
});


/*
 * Шаблоны
 */
const TemplateCreate = Loadable({
    loader: () => import("@/views/adminPanel/templates/template/Create.jsx"),
    loading: Loading,
});
const TemplateUpdate = Loadable({
    loader: () => import("@/views/adminPanel/templates/template/Create.jsx"),
    loading: Loading,
});
const Template = Loadable({
    loader: () => import("@/views/adminPanel/templates/template/Details.jsx"),
    loading: Loading,
});
const Templates = Loadable({
    loader: () => import("@/views/adminPanel/templates"),
    loading: Loading,
});


/*
 * Программа лояльности Благодарю
 */
const LoyaltySettingCreate = Loadable({
    loader: () => import("@/views/adminPanel/loyalties/settings/setting/Create.jsx"),
    loading: Loading,
});
const LoyaltySetting = Loadable({
    loader: () => import("@/views/adminPanel/loyalties/settings/setting/Details.jsx"),
    loading: Loading,
});
const LoyaltySettings = Loadable({
    loader: () => import("@/views/adminPanel/loyalties/settings"),
    loading: Loading,
});
const LoyaltyCustomers = Loadable({
    loader: () => import("@/views/adminPanel/loyalties/customers"),
    loading: Loading,
});


const NotificationSettings = Loadable({
    loader: () => import("@/views/adminPanel/notificationSettings"),
    loading: Loading,
});


const Journal = Loadable({
    loader: () => import("@/views/adminPanel/journal"),
    loading: Loading,
});

const BonusesAndFines = Loadable({
    loader: () => import("@/views/adminPanel/bonusesAndFines"),
    loading: Loading,
});

const Period = Loadable({
    loader: () => import("@/views/adminPanel/bonusesAndFines/period"),
    loading: Loading,
});

/*
 * Карта маршрутов
 */
const routeMap = [
    { path: "/employee/archive",            component: EmployeesArchive,            roles: Administration.Employees.Archive.Data },
    { path: "/employee/create",             component: EmployeeCreate,              roles: Administration.Employees.All.Add },
    { path: "/employee/:id/update",         component: EmployeeUpdate,              roles: Administration.Employees.Employee.Profile.Edit },
    { path: "/employee/:id",                component: Employee,                    roles: Administration.Employees.Employee.Profile.Data },
    { path: "/employee",                    component: Employees,                   roles: Administration.Employees.All.Data },

    { path: "/office/create",               component: OfficeCreate,                roles: Administration.Offices.All.Add },
    { path: "/office/:officeId/update",     component: OfficeUpdate,                roles: Administration.Offices.Office.Edit },
    { path: "/office/:officeId",            component: Office,                      roles: Administration.Offices.Office.Data },
    { path: "/office",                      component: Offices,                     roles: Administration.Offices.All.Data },

    { path: "/news/create",                 component: NewsCreate,                  roles: Administration.News.All.Add },
    { path: "/news/:newsId/update",         component: NewsUpdate,                  roles: Administration.News.News.Data },
    //{ path: "/news/:newsId",                component: OneNews,                     roles: Administration },
    { path: "/news",                        component: News,                        roles: Administration.News.All.Data },

    { path: "/staticpage/create",           component: StaticPageCreate,            roles: Administration.Section },
    { path: "/staticpage/:pageId/update",   component: StaticPageUpdate,            roles: Administration.Section },
    { path: "/staticpage",                  component: StaticPages,                 roles: Administration.Section },

    { path: "/faq/create",                  component: FAQCreate,                   roles: Administration.Section },
    { path: "/faq/:pageId/update",          component: FAQUpdate,                   roles: Administration.Section },
    { path: "/faq",                         component: FAQs,                        roles: Administration.Section },

    { path: "/product",                     component: Products,                    roles: Administration.Products.All.Data },
    { path: "/depositproduct/create",       component: DepositProductCreate,        roles: Administration.Products.Deposit.Add },
    { path: "/depositproduct/:productId",   component: DepositProduct,              roles: Administration.Products.Deposit.Data },
    { path: "/depositproduct",              component: DepositProducts,             roles: Administration.Products.Deposit.Data },
    { path: "/loanproduct/create",          component: LoanProductCreate,           roles: Administration.Products.Loan.Add },
    { path: "/loanproduct/:productId",      component: LoanProduct,                 roles: Administration.Products.Loan.Data },
    { path: "/loanproduct",                 component: LoanProducts,                roles: Administration.Products.Loan.Data },

    { path: "/template/create",             component: TemplateCreate,              roles: Administration.Templates.Add },
    { path: "/template/:templateId/update", component: TemplateUpdate,              roles: Administration.Templates.Add },
    { path: "/template/:templateId",        component: Template,                    roles: Administration.Templates.Data },
    { path: "/template",                    component: Templates,                   roles: Administration.Templates.Data },

    { path: "/loyalty/create",              component: LoyaltySettingCreate,        roles: Administration.Loyalty.Settings.Add },
    { path: "/loyalty/:id/details",         component: LoyaltySetting,              roles: Administration.Loyalty.Settings.Data },
    { path: "/loyalty/customers",           component: LoyaltyCustomers,            roles: Administration.Loyalty.Customers.Data },
    { path: "/loyalty",                     component: LoyaltySettings,             roles: Administration.Loyalty.Settings.All },

    { path: "/notificationsettings",        component: NotificationSettings,        roles: Administration.Section },

    { path: "/journal",                     component: Journal,                     roles: Administration.Journal.Data },

    //{ path: "/integrations",                component: NotImplemented,              roles: Administration },

    { path: "/bonusesandfines/period",      component: Period,                       roles: Administration.Loyalty.Data },
    { path: "/bonusesandfines",             component: BonusesAndFines,              roles: Administration.Loyalty.Data },

    //{ path: "/analytics",                   component: NotImplemented,              roles: Administration }
];  

export default routeMap;    
