import * as types from "../action-types.js";

const initState = {
    data: []
};

export default function breadcrumbs(state = initState, action)
{
    switch (action.type)
    {
    case types.SET_BREADCRUMBS:
        let newBreadcrumbs = action.splice
            ? state.data.splice(0, action.index)
            : state.data;

        if (action.splice)
        {
            newBreadcrumbs.length = action.index;
        }

        newBreadcrumbs.splice(
            action.index,
            1,
            {
                path: action.path,
                title: action.title
            });

        return {
            data: [...newBreadcrumbs]
        };

    default:
        return state;
    }
}
