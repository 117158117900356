import * as types from "../action-types";

export const setBreadcrumbs =
    (path, title, index, splice = true) =>
        ({
            type: types.SET_BREADCRUMBS,
            path,
            title,
            index,
            splice
        });
