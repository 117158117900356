import request from "@/utils/request.js";
import { postViaForm, patchViaForm, getItem } from "./crud.js";

const url = "/office";

export const catalogUrl = `${url}/catalog`;

export default url;

export function create(data)
{
    return postViaForm(url, data);
}

export function update(data)
{
    return patchViaForm(url, data);
}

export function getDetails(id)
{
    return getItem(url, id);
}

export function getEmployees(id)
{
    return request({
        url: `${url}/${id}/employee`,
        method: "get"
    });
}

export function getName(id)
{
    return request({
        url: `${url}/${id}/name`,
        method: "get"
    });
}

export function deactivate(id)
{
    return patchViaForm(url, { IsActive: false, Id: id });
}
