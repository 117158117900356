import { Administration } from "@/config/permissions";

const {
    Employees,
    Offices,
    News,
    Products,
    Templates,
    Loyalty,
    Journal
} = Administration;

const menuList = [
    {
        title: "Сотрудники",
        path: "/employee",
        roles: Employees.All.Data,
        children: [
            {
                title: "Все сотрудники",
                path: "/employee",
                roles: Employees.All.Data
            },
            {
                title: "Архив сотрудников",
                path: "/employee/archive",
                roles: Employees.Archive.Data
            }
        ]
    },
    {
        title: "Офисы",
        path: "/office",
        roles: Offices.All.Data
    },
    {
        title: "Новости",
        path: "/news",
        roles: News.All.Data
    },
    {
        title: "Статика",
        path: "/staticpage",
        roles: News.All.Data
    },
    {
        title: "FAQ",
        path: "/faq",
        roles: News.All.Data
    },
    {
        title: "Продукты",
        roles: Products.All.Data,
        children: [
            {
                title: "Все продукты",
                path: "/product",
                roles: Products.All.Data
            },
            {
                title: "Продукты сбережений",
                path: "/depositproduct",
                roles: Products.Deposit.Data
            },
            {
                title: "Продукты займов",
                path: "/loanproduct",
                roles: Products.Loan.Data
            }
        ]
    },
    {
        title: "Шаблоны документов",
        path: "/template",
        roles: Templates.Data
    },
    {
        title: "Благодарю",
        roles: Loyalty.Settings.All,
        children: [
            {
                title: "Условия",
                path: "/loyalty",
                roles: Loyalty.Settings.All
            },
            {
                title: "Клиенты",
                path: "/loyalty/customers",
                roles: Loyalty.Customers.Data
            }
        ]
    },
    {
        title: "Уведомления",
        path: "/notificationsettings",
        roles: News.All.Data
    },
    {
        title: "Журнал",
        path: "/journal",
        roles: Journal.Data
    },
    // {
    //    title: "Интеграции",
    //    path: "/integrations",
    //    roles: [admin]
    // },
    {
        title: "Премии и штрафы",
        path: "/bonusesandfines",
        roles: Loyalty.Customers.Data // ToDo Фатих обновить права на права премий и штрафов, когда дима внесёт в таблицу
    }
    // {
    //    title: "Аналитика",
    //    path: "/analytics",
    //    roles: [admin]
    // }
];

export default menuList;
