import { getUser } from "@/utils/auth.js";
import * as types from "../action-types.js";

const getUserInfo =
    ({ Name, Email, Phone, Role, Avatar, Token, Id }) =>
        ({
            name: Name,
            email: Email,
            phone: Phone,
            role: Role,
            avatar: Avatar,
            token: Token,
            userId: Id
        });

const initUserInfo = getUserInfo(getUser());

export default function user(state = initUserInfo, action)
{
    switch (action.type)
    {
    case types.USER_SET_USER_INFO:
        return {
            ...state,
            ...getUserInfo(action)
        };

    case types.USER_RESET_USER:
        return {};

    default:
        return state;
    }
}
