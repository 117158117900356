import { withRouter } from "react-router-dom";
import { Button, Select, DatePicker, Col, Row, Spin, Input } from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";
import { dateFormat } from "@/config/constants";
import Filter from "@/components/Filter";

const { RangePicker } = DatePicker;

class OperationsFilter extends Filter
{
    constructor(props)
    {
        super(props);

        this.state = {
            ...this.state,

            loading: false,
            preloadedThreshold: 0,
            singles: ["operationType:OperationClass"],
            ranges: ["createdAt", "amount"],
            multiples: ["deposit:Product:Name", "operationType:Name", "executionMode"],

            amount: []
        };
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render()
    {
        const {
            loading,
            createdAt, amount,
            executionMode
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={22}>
                        <Row className="row">
                            <Col className="bold" span={8}>Дата совершения</Col>
                            <Col span={16}>
                                <RangePicker
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("createdAt", dateString)}
                                    value={
                                        createdAt?.length && createdAt.every(date => date)
                                            ? [moment(createdAt[0], dateFormat), moment(createdAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col className="bold" span={8}>Продукты</Col>
                            <Col span={16}>
                                {
                                    this.state["deposit:Product:Name"]
                                        ? this.state["deposit:Product:Name"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("deposit:Product:Name", event.target.value, index)}
                                                    placeholder="Введите продукт"
                                                    value={this.state["deposit:Product:Name"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("deposit:Product:Name", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("deposit:Product:Name")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col className="bold" span={8}>Класс операции</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => this.onSelectorChange("operationType:OperationClass", value)}
                                    placeholder="Выберите класс операции"
                                    value={this.state["operationType:OperationClass"]}
                                >
                                    <Select.Option value={1}>Приход</Select.Option>
                                    <Select.Option value={2}>Расход</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col className="bold" span={8}>Типы операции</Col>
                            <Col span={16}>
                                {
                                    this.state["operationType:Name"]
                                        ? this.state["operationType:Name"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("operationType:Name", event.target.value, index)}
                                                    placeholder="Введите тип операции"
                                                    value={this.state["operationType:Name"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("operationType:Name", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("operationType:Name")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col className="bold" span={8}>Способы проведения</Col>
                            <Col span={16}>
                                {
                                    executionMode
                                        ? executionMode.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("executionMode", event.target.value, index)}
                                                    placeholder="Введите способ проведения"
                                                    value={executionMode[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("executionMode", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("executionMode")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col className="bold" span={8}>Сумма</Col>
                            <Col span={16}>
                                <Row>
                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("amount", event.target.value, 0)}
                                            placeholder="от"
                                            value={amount[0]}
                                        />
                                    </Col>

                                    <Col span={2}/>

                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("amount", event.target.value, 1)}
                                            placeholder="до"
                                            value={amount[1]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={2} />
                </Row>

                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(OperationsFilter);
