import { Layout } from "antd";

import "./index.less";

const { Footer } = Layout;

const LayoutFooter = () =>
{
    const currentYear = new Date().getFullYear();

    return (
        <Footer>
            <span>&copy; { currentYear }</span>
        </Footer>
    );
};

export default LayoutFooter;
