import { APP_TOGGLE_SETTINGPANEL, APP_TOGGLE_MODE, APP_RESET } from "../action-types.js";
import { closePanel } from "../toggleSettingPanel-types.js";

export const toggleSettingPanel =
    (data = {}, modalType = closePanel) =>
        ({
            type: APP_TOGGLE_SETTINGPANEL,
            data,
            modalType
        });

export const toggleMode =
    (redirectToRoot = true) => ({
        type: APP_TOGGLE_MODE,
        redirectToRoot
    });

export const reset =
    () =>
        ({
            type: APP_RESET
        });
