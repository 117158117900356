import { withRouter } from "react-router-dom";
import { Button, Radio, Col, Row, Spin, Input } from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined, ClearOutlined } from "@ant-design/icons";
import Filter from "@/components/Filter";

class OfficesFilter extends Filter
{
    constructor(props)
    {
        super(props);

        this.state = {
            ...this.state,

            loading: false,
            preloadedThreshold: 0,
            singles: ["isActive"],
            ranges: [],
            multiples: ["city"]
        };
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render()
    {
        const {
            loading,
            isActive,
            city
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={22}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Активность</Col>
                            <Col span={16}>
                                <Radio.Group
                                    className="full-width d-flex"
                                    onChange={event => this.onSelectorChange("isActive", event.target.value)}
                                    value={isActive}
                                >
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={true}
                                    >
                                        Активен
                                    </Radio.Button>
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={false}
                                    >
                                        Не активен
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Города</Col>
                            <Col span={16}>
                                {
                                    city
                                        ? city.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("city", event.target.value, index)}
                                                    placeholder="Введите город"
                                                    value={city[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("city", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("city")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={2} />
                </Row>

                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(OfficesFilter);
