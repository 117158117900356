import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Row,
    Spin,
    Input,
    Space,
    Radio
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { toggleSettingPanel } from "@/store/actions";
import useNotificationSettingForm from "./hooks/useNotificationSettingForm.js";

const EditNotificationSetting = props =>
{
    const { data: { Id } } = props;

    const {
        handleRadioChange,
        handleInputChange,
        loading,
        type,
        channel,
        isEnabled,
        title,
        body,
        onChange
    } = useNotificationSettingForm(Id, props);

    return (
        <Spin spinning={loading}>
            <Row className="row">
                <Col span={24}>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Событие</Col>
                        <Col span={16}>{type}</Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Канал</Col>
                        <Col span={16}>{channel}</Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Состояние</Col>
                        <Col span={16}>
                            <Radio.Group
                                className="full-width d-flex"
                                name="isEnabled"
                                onChange={handleRadioChange}
                                value={isEnabled}
                            >
                                <Radio.Button
                                    className="flex-1 centred"
                                    value={true}
                                >
                                    Активен
                                </Radio.Button>
                                <Radio.Button
                                    className="flex-1 centred"
                                    value={false}
                                >
                                    Не активен
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Заголовок</Col>
                        <Col span={16}>
                            <Input
                                name="title"
                                onChange={handleInputChange}
                                placeholder="Введите заголовок"
                                value={title}
                            />
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Сообщение</Col>
                        <Col span={16}>
                            <Input
                                name="body"
                                onChange={handleInputChange}
                                placeholder="Введите тело сообщения"
                                value={body}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="row" gutter={20}>
                <Col align="end" span={4}>
                    <Space>
                        <Button
                            className="green-button"
                            icon={<CheckOutlined />}
                            onClick={onChange}
                            type="primary"
                        >
                            Сохранить
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Spin>
    );
};

export default connect(
    null,
    { toggleSettingPanel }
)(withRouter(EditNotificationSetting));
