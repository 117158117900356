import { useCallback } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounceFn } from "ahooks";

import { FormItem, useForm } from "@/components/Form";
import { searchPing, maskChar } from "@/config/constants";
import MaskedInput from "@/components/Form/MaskedInput";

function PhoneInput({byPhone})
{
    const { errors } = useForm();

    const typingTimeout = 0;

    const { run: findByPhone } = useDebounceFn(
        phone =>
        {
            if (phone.includes(maskChar))
            {
                return;
            }

            byPhone(phone)
        },
        {
            wait: searchPing
        }
    );

    const phoneChange = useCallback(
        event =>
        {
            const { value } = event.target;

            if (typingTimeout)
            {
                clearTimeout(typingTimeout);
            }

            findByPhone(value);
        },
        [typingTimeout]
    );

    return (
        <FormItem
            errors={errors?.Phone}
            label="Телефон"
            name="Phone"
            onChange={phoneChange}
        >
            <MaskedInput
                placeholder="Поиск по номеру телефона"
                suffix={<SearchOutlined className="placeholder" />}
            />
        </FormItem>
    );
}

export default PhoneInput;
