import { TableFunctionalityExceptLoanManagerAndCustomer, AllExceptLoanManager, Employees } from "../roles";

export const HelpDesk = {
    All: {
        Data: AllExceptLoanManager,
        Open: AllExceptLoanManager,
        ...TableFunctionalityExceptLoanManagerAndCustomer
    },
    Room: {
        Assign: Employees
    }
};
