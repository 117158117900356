import { useState, useCallback } from "react";
import upperFirst from "lodash/upperFirst";

import OnCatchRequestErrorDecorator from "@/components/OnCatchRequestErrorDecorator";

export * from "./AutoComplete";
export * from "./Form";
export * from "./Label";
export * from "./FormList";
export * from "./FormList/MultipleFormList.jsx";

export function useForm()
{
    const [errors, setErrors] = useState({});

    const onChange = useCallback(
        fieldName =>
        {
            setErrors(prevErrors =>
            {
                const errors = { ...prevErrors };

                // NOTE: After validation by the server, returns error object with keys started from capital and with camel case, but submit without capital
                delete errors[upperFirst(fieldName)];
                delete errors[fieldName];

                return errors;
            });
        },
        []
    );

    function onCatchRequestError(error)
    {
        OnCatchRequestErrorDecorator(error, setErrors);
    }

    return {
        errors,
        onChange,
        onCatchRequestError
    };
}
