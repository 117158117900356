import { message } from "antd";

import isEmpty from "lodash/isEmpty";

export default function OnCatchRequestErrorDecorator(error, setErrors)
{
    const { response } = error;

    if (!response)
    {
        return;
    }

    const { data, status } = response;

    const { errors } = data;

    switch (status)
    {
    case 500:
        message.error(errors ?? "Ошибка сервера. Попробуйте позже.");
        break;

    case 400:
        if (!isEmpty(errors))
        {
            setErrors(errors);
        }

        message.error(data?.title);
        break;

    default:
        message.error(errors);
        break;
    }
}
