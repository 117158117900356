import { filterType } from "@/config/constants";
import {
    toTransportableDate,
    getDate,
    capitalizeFirstLetter,
    uncapitalizeFirstLetter
} from "@/utils/converters.js";
import InputEntity from "@/components/InputEntity";
import "./index.less";

class Filter extends InputEntity
{
    constructor(props)
    {
        super(props);

        this.state = {
            singles: [],
            ranges: [],
            multiples: [],
            intersections: []
        };

        this.updateUrl = this.updateUrl.bind(this);
        this.clearAll = this.clearAll.bind(this);
    }

    setFromUrl()
    {
        this.setState({ loading: true });

        const { filters } = this.props.data;

        if (filters)
        {
            this.setState({
                ...this.preset(
                    JSON.parse(
                        filters))
            });
        }

        this.setState({ loading: false });
    }

    preset(source)
    {
        const result = {};

        // извлекаем установленные значения фильтров
        source.forEach(filter =>
        {
            const { type, name, body } = filter;

            switch (type)
            {
            case filterType.equals:
                result[uncapitalizeFirstLetter(name)] = body.value;
                break;

            case filterType.fromTo:
                result[uncapitalizeFirstLetter(name)] =
                            body.from.match(/^\d{4}-\d{2}-\d{2}$/s) // если дата, то необходимо распарсить
                                ? [getDate(body.from), getDate(body.to)]
                                : [body.from, body.to];
                break;

            case filterType.contains:
            case filterType.keysIntersection:
                result[uncapitalizeFirstLetter(name)] = body.values;
                break;

            default:
                break;
            }
        });

        const { singles, ranges, multiples, intersections } = this.state;
        const allNames = [...singles, ...ranges, ...multiples, ...intersections];

        // инициализируем поля без истории
        allNames.forEach(name =>
        {
            if (result[name] === undefined)
            {
                result[name] = singles.includes(name)
                    ? undefined
                    : [];
            }
        });

        return result;
    }

    getParams()
    {
        const { singles, ranges, multiples, intersections } = this.state;
        const allNames = [...singles, ...ranges, ...multiples, ...intersections];

        const result = {};
        allNames.forEach(name =>
        {
            const value = this.state[name];
            // в результирующую сущность добавляются только заполненные из полей
            if (value !== undefined)
            {
                result[name] = value;
            }
        });

        return result;
    }

    toTransportable(source)
    {
        const { multiples, ranges, intersections } = this.state;
        const result = [];

        Object.keys(source)
            .forEach(key =>
            {
                // Тип B (from-to)
                if (ranges.includes(key))
                {
                    if (source[key].length && source[key].every(value => value))
                    {
                        result.push({
                            type: filterType.fromTo,
                            name: capitalizeFirstLetter(key),
                            body: {
                                from: toTransportableDate(source[key][0]),
                                to: toTransportableDate(source[key][1])
                            }
                        });
                    }
                }
                // Типы C (массив значений) и D (пересечение ключей отношений М:М)
                else if (multiples.includes(key) || intersections.includes(key))
                {
                    const values = source[key].filter(value => value);
                    if (values.length)
                    {
                        result.push({
                            type: multiples.includes(key) ? filterType.contains : filterType.keysIntersection,
                            name: capitalizeFirstLetter(key),
                            body: {
                                values: [...new Set(values)]
                            }
                        });
                    }
                }
                // Тип A (одиночное значение)
                else
                {
                    result.push({
                        type: filterType.equals,
                        name: capitalizeFirstLetter(key),
                        body: {
                            value: source[key]
                        }
                    });
                }
            });

        return result;
    }

    updateUrl()
    {
        this.setState({ loading: true });

        const {
            history,
            location: { pathname, search },
            toggleSettingPanel,
            data
        } = this.props;

        const query = {
            ...data,
            page: 1,
            filters: this.toTransportable(this.getParams())
        };

        const searchParams = new URLSearchParams();

        Object.keys(query)
            .forEach(key =>
            {
                const value = query[key];

                if (value)
                {
                    if (Array.isArray(value) && !value.length)
                    {
                        return;
                    }

                    if (key === "filters")
                    {
                        searchParams.append(key, JSON.stringify(query[key]));
                    }
                    else
                    {
                        searchParams.append(key, query[key]);
                    }
                }
            });

        if (searchParams.toString() !== search.slice(1))
        {
            history.push({
                pathname,
                search: searchParams.toString()
            });
        }

        this.setState({ loading: false });

        toggleSettingPanel();
    }

    clearAll(callback)
    {
        const { singles, ranges, multiples, intersections } = this.state;
        const allNames = [...singles, ...ranges, ...multiples, ...intersections];

        // сбрасываем все фильтры
        let result = {};
        allNames.forEach(name =>
        {
            result[name] = singles.includes(name)
                ? undefined
                : [];
        });

        this.setState({ ...result }, callback);
    }
}

export default Filter;
