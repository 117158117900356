import cx from "classnames";

import "@/styles/form.less";

export function Label({ text = "", className = "", isBold = true})
{
    return (
        <span className={cx("form-label", { bold: isBold }, className)}>
            {text}
        </span>
    );
}
