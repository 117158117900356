import { withRouter } from "react-router-dom";
import { Menu } from "antd";

const UserMenu = props =>
{
    const { logout } = props;

    const onClick = ({ key }) =>
    {
        switch (key)
        {
        case "logout":
            logout();
            break;

        case "profile":
            props.history.push({ pathname: "/profile" });
            break;

        case "update":
            props.history.push({ pathname: "/profile/update" });
            break;

        default:
            break;
        }
    };

    return (
        <Menu className="user-menu" onClick={onClick}>
            <Menu.Item key="profile">Профиль</Menu.Item>
            <Menu.Item key="update">Редактировать</Menu.Item>
            <Menu.Item key="logout">Выход</Menu.Item>
        </Menu>
    );
};

export default withRouter(UserMenu);
