import { Layout } from "antd";
import BreadCrumb from "@/components/BreadCrumb";
import Header from "./Header";
import Menu from "./Menu";
import Content from "./Content";
import RightPanel from "./RightPanel";
import Footer from "./Footer";

const Main = () =>
{
    return <>
        <Header />
        <Layout>
            <Menu />
            <Layout>
                <BreadCrumb />
                <Content />
                <RightPanel />
            </Layout>
        </Layout>
        <Footer />
    </>;
};

export default Main;
