import request from "@/utils/request.js";
import { addItem, getItem } from "./crud.js";

const url = "/loyaltySetting";

export default url;

export function create(data)
{
    return addItem(url, data);
}

export function getPresets()
{
    return request({
        url: `${url}/presets`,
        method: "get"
    });
}

export function getActual()
{
    return request({
        url: `${url}/actual`,
        method: "get"
    });
}

export function getDetails(id)
{
    return getItem(url, id);
}
