import { useReducer, useCallback } from "react";

const LOADING_SET = "LOADING_SET";
const LOADING_RESET = "LOADING_RESET";
const DATA_LOADED = "DATA_LOADED";
const FIELD_INNER_APPEND = "FIELD_INNER_APPEND";
const FIELD_INNER_CHANGE = "FIELD_INNER_CHANGE";
const FIELD_INNER_REMOVE = "FIELD_INNER_REMOVE";
const FIELD_CHANGE = "FIELD_CHANGE";
const FIELD_BULK_CHANGE = "FIELD_BULK_CHANGE";

const init = (initialState = {}) => ({
    loading: true,
    preloaded: 0,
    preloadedThreshold: 1,
    ...initialState
});

const reducer = (state, action) =>
{
    switch (action.type)
    {
        case LOADING_SET: {
            return {
                ...state,
                loading: true
            };
        }
        case LOADING_RESET: {
            return {
                ...state,
                loading: false
            };
        }
        case DATA_LOADED: {
            const resultPreloaded = state.preloaded + 1;

            return {
                ...state,
                preloaded: resultPreloaded,
                loading: resultPreloaded !== state.preloadedThreshold
            };
        }
        case FIELD_INNER_APPEND: {
            const { name, defaultValue = "" } = action.payload;
            const curFieldValue = state[name];

            return {
                ...state,
                [name]: curFieldValue
                    ? curFieldValue.concat(defaultValue)
                    : [defaultValue]
            };
        }
        case FIELD_CHANGE: {
            const { name, value } = action.payload;

            return {
                ...state,
                [name]: value
            };
        }
        case FIELD_INNER_CHANGE: {
            const { name, value: newValue, id } = action.payload;
            const curFieldValue = state[name];

            return {
                ...state,
                [name]: curFieldValue.map((value, index) => index === id ? newValue : value)
            };
        }
        case FIELD_INNER_REMOVE: {
            const { name, id } = action.payload;
            const curFieldValue = state[name];

            return {
                ...state,
                [name]: [
                    ...curFieldValue.slice(0, id),
                    ...curFieldValue.slice(id + 1)
                ]
            };
        }
        case FIELD_BULK_CHANGE: {
            return {
                ...state,
                ...action.payload.data
            };
        }
        default:
            return state;
    }
};

const useInputEntity = initialState =>
{
    const [state, dispatch] = useReducer(reducer, initialState, init);

    const handleLoadingSet = useCallback(() => dispatch({ type: LOADING_SET }), [dispatch]);
    const handleLoadingReset = useCallback(() => dispatch({ type: LOADING_RESET }), [dispatch]);
    const handleDataLoaded = useCallback(() => dispatch({ type: DATA_LOADED }), [dispatch]);
    const handleFieldInnerAppend = useCallback((name, defaultValue) => dispatch({
        type: FIELD_INNER_APPEND,
        payload: { name, defaultValue }
    }), [dispatch]);
    const handleFieldInnerChange = useCallback((name, value, id) => dispatch({
        type: FIELD_INNER_CHANGE,
        payload: { name, value, id }
    }), [dispatch]);
    const handleFieldBulkChange = useCallback(data => dispatch({
        type: FIELD_BULK_CHANGE,
        payload: { data }
    }), [dispatch]);
    const handleFieldInnerRemove = useCallback((name, id) => dispatch({
        type: FIELD_INNER_REMOVE,
        payload: { name, id }
    }), [dispatch]);
    const handleInputChange = useCallback(event => dispatch({
        type: FIELD_CHANGE,
        payload: {
            name: event.target.name,
            value: event.target.value
        }
    }), [dispatch]);
    const handleFieldChange = useCallback((name, value) => dispatch({
        type: FIELD_CHANGE,
        payload: { name, value }
    }), [dispatch]);
    const handleCheckboxChange = useCallback(event => dispatch({
        type: FIELD_CHANGE,
        payload: {
            name: event.target.name,
            value: event.target.checked
        }
    }), [dispatch]);
    const handleRadioChange = useCallback(event => dispatch({
        type: FIELD_CHANGE,
        payload: {
            name: event.target.name,
            value: event.target.value
        }
    }), [dispatch]);

    return {
        state,
        handleLoadingSet,
        handleLoadingReset,
        handleDataLoaded,
        handleFieldInnerAppend,
        handleFieldInnerChange,
        handleFieldInnerRemove,
        handleFieldChange,
        handleFieldBulkChange,
        handleInputChange,
        handleCheckboxChange,
        handleRadioChange
    };
};

export default useInputEntity;
