import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Select,
    Button,
    Space,
    Upload,
    Spin,
    Empty
} from "antd";
import {
    CheckOutlined,
    PlusOutlined,
    FileOutlined
} from "@ant-design/icons";

import { toggleSettingPanel } from "@/store/actions";
import { useForm } from "@/components/Form";
import { useOffice } from "@/components/OfficeOptions";
import useDivvyForm from "./hooks/useDivvyForm";

const Create = props =>
{
    const { data: { id, isInCooperation } } = props;
    const { onCatchRequestError } = useForm();
    const {
        loading,
        handleInputChange,
        handleFieldChange,
        officesCatalog,
        operationClass,
        executionMode,
        officeId,
        divvyAmount,
        templates,
        cashiersCatalog,
        cashierId,
        handleGetDoc,
        handleCreate,
        documentUpload
    } = useDivvyForm(id, isInCooperation, props, onCatchRequestError);
    const {
        officeRegion,
        officeCity,
        officeSelectOptions
    } = useOffice(officesCatalog, officeId);

    const templatesEntries = Object.entries(templates ?? {});

    return (
        <div className="app-container">
            <Spin spinning={loading}>
                <Row className="row-simple">
                    <Col span={8}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Кассовый ордер</Col>
                            <Col span={16}>
                                <Upload {...documentUpload}>
                                    <Button
                                        icon={<PlusOutlined />}
                                        size="small"
                                        type="dashed"
                                    >
                                        Прикрепить документ
                                    </Button>
                                </Upload>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Класс операции</Col>
                            <Col span={16}>
                                <span>{operationClass}</span>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Сумма</Col>
                            <span>{divvyAmount} руб.</span>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Способ проведения</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    disabled
                                    name="executionMode"
                                    onChange={handleInputChange}
                                    placeholder="Укажите способ проведения"
                                    value={executionMode}
                                >
                                    {/* FIXME: Need to set correct variants below */}
                                    {[]}
                                </Select>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Кассир</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => handleFieldChange("cashierId", value)}
                                    placeholder="Выберите кассира"
                                    value={cashierId}
                                >
                                {
                                    Object.keys(cashiersCatalog)
                                        .map(key => (
                                            <Select.Option
                                                key={key}
                                                value={cashiersCatalog[key].Id}
                                            >
                                                {cashiersCatalog[key].Name}
                                            </Select.Option>
                                        ))
                                }
                                </Select>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Офис</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => handleFieldChange("officeId", value)}
                                    placeholder="Выберите офис"
                                    value={officeId}
                                >
                                    {officeSelectOptions}
                                </Select>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Регион</Col>
                            <Col span={16}>
                                <span className="placeholder">
                                    {officeRegion}
                                </span>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Город</Col>
                            <Col span={16}>
                                <span className="placeholder">
                                    {officeCity}
                                </span>
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col className="bold" span={8}>Шаблоны</Col>
                            <Col span={16}>
                                {
                                    templatesEntries.length ? templatesEntries.map(([key, value]) =>
                                        <a
                                            className="hovered-link"
                                            onClick={() => handleGetDoc(key)}
                                            title={value}
                                        >
                                            <FileOutlined className="prefix" />
                                            <span>
                                                {value}
                                            </span>
                                        </a>
                                    ) : (
                                        <span className="placeholder">
                                            Нет данных
                                        </span>
                                    )
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="row">
                    <Col span={4}>
                        <Space>
                            <Button
                                className="green-button"
                                icon={<CheckOutlined />}
                                onClick={handleCreate}
                                type="primary"
                            >
                                Создать
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default connect(
    null,
    { toggleSettingPanel }
)(withRouter(Create));
