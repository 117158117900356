import { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import { Form, Input, Button, message, Spin } from "antd";
import onCatchRequestError from "@/components/OnCatchRequestError";
import { confirmPassword } from "@/store/actions";
import "./index.less";

const title = "Установите новый пароль";

const Confirmed = props =>
{
    const { token, confirmPassword } = props;
    const { location: { search } } = props;

    const [loading, setLoading] = useState(false);

    const code = new URLSearchParams(search).get("code");
    const username = new URLSearchParams(search).get("username");
    if (!code || !username)
    {
        return <Redirect to="/recovery/bymail" />;
    }

    if (token)
    {
        return <Redirect to="/" />;
    }

    const onFinish = values =>
    {
        setLoading(true);

        const { password } = values;

        confirmPassword({ username, password }, { code })
            .then(() =>
            {
                message.success("Пароль успешно обновлён");
            })
            .catch(onCatchRequestError)
            .finally(() => setLoading(false));
    };

    return (
        <DocumentTitle title={title}>
            <div className="auth-container">
                <Form
                    className="content"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <h2 className="title">{title}</h2>
                    <Spin
                        spinning={loading}
                        tip="обновляем пароль..."
                    >
                        <Form.Item
                            hasFeedback
                            name="password"
                            rules={[
                                {
                                    isRequired: true,
                                    message: "Пожалуйста, введите пароль"
                                }
                            ]}
                        >
                            <Input.Password placeholder="Новый пароль"/>
                        </Form.Item>
                        <Form.Item
                            dependencies={["password"]}
                            hasFeedback
                            name="confirm"
                            rules={[
                                {
                                    isRequired: true,
                                    message: "Пожалуйста, подтвердите пароль"
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value)
                                    {
                                        if (!value || getFieldValue("password") === value)
                                        {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error("Пароли должны совпадать"));
                                    }
                                })
                            ]}
                        >
                            <Input.Password placeholder="Повторите новый пароль"/>
                        </Form.Item>
                        <Form.Item
                            className="small-indent"
                        >
                            <Button
                                className="full-width"
                                htmlType="submit"
                                type="primary"
                            >
                                Обновить пароль и авторизоваться
                            </Button>
                        </Form.Item>
                    </Spin>
                </Form>
            </div>
        </DocumentTitle>
    );
};

export default connect(
    state => state.user,
    { confirmPassword }
)(Confirmed);
