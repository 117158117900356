import request from "@/utils/request.js";
import { getItem, fromForm, upload as uploadCrud, editItem, deleteItem, getList } from "./crud.js";
import { download, upload, downloadArchive } from "./document.js";
import { getTemplates as getEmployeesTemplates, generateTemplate } from "./template.js";

const url = "/employee";

export const archiveUrl = `${url}/archive`;

export default url;

export function getRolesCatalog()
{
    return getList(`${url}/roles-catalog`);
}

export function getName(id)
{
    return request({
        url: `${url}/${id}/name`,
        method: "get"
    });
}

export function create(data, passportScans)
{
    let formData = fromForm(passportScans, data, "passportScans");

    return uploadCrud(url, formData);
}

export function update(data)
{
    return editItem(url, data);
}

export function dearchive(id)
{
    return editItem(`${url}/${id}/dearchive`);
}

export function remove(id)
{
    return deleteItem(url, id);
}

export function getProfile(id)
{
    return getItem(url, id);
}

export function getTemplates()
{
    return getEmployeesTemplates(url);
}

export function uploadDocument(ownerId, file, data)
{
    return upload(url, ownerId, file, data);
}

export function downloadDocument(guid)
{
    return download(url, guid);
}

export function downloadAllInArchive(guid)
{
    return downloadArchive(url, guid);
}

export function downloadTemplate(id, guid)
{
    return generateTemplate(url, id, guid);
}

export function getNamesCatalog()
{
    return getList(`${url}/names`);
}
