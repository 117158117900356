import * as types from "../action-types.js";
import * as modes from "../toggleMode-types.js";
import { closePanel } from "../toggleSettingPanel-types.js";

const initState = {
    data: {},
    modalType: closePanel,
    settingPanelVisible: false,

    modeType: modes.USER_PANEL,
    redirectToRoot: true
};

export default function app(state = initState, action)
{
    switch (action.type)
    {
    case types.APP_TOGGLE_SETTINGPANEL:
        return {
            ...state,
            data: action.data,
            modalType: action.modalType,
            settingPanelVisible: !state.settingPanelVisible
        };

    case types.APP_TOGGLE_MODE:
        return {
            ...state,
            modeType: state.modeType === modes.USER_PANEL
                ? modes.ADMIN_PANEL
                : modes.USER_PANEL,
            redirectToRoot: action.redirectToRoot
        };

    case types.APP_RESET:
        return initState;

    default:
        return state;
    }
}
