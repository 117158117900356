import { withRouter } from "react-router-dom";
import { message, Button, Select, DatePicker, Col, Row, Spin, Input } from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";

import { getCatalog } from "@/api/operation.js";
import { dateFormat } from "@/config/constants";
import Filter from "@/components/Filter";

const { RangePicker } = DatePicker;

class OperationsFilter extends Filter
{
    constructor(props)
    {
        super(props);

        this.state = {
            ...this.state,

            loading: false,
            preloadedThreshold: 0,
            singles: ["operationType"],
            ranges: ["createdAt", "amount"],
            multiples: ["productName", "executionMode"],
            intersections: [],
            operationTypes: [],

            amount: []
        };

        getCatalog()
            .then(response =>
            {
                const { meta, data, errors } = response.data;

                if (meta.status === 200)
                {
                    this.setState({ operationTypes: data });
                }
                else
                {
                    message.error(errors[0]);
                }
            })
            .catch(error => message.error(error.message))
            .finally(() => this.Preloaded());
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render()
    {
        const {
            loading,
            createdAt, amount,
            executionMode, operationTypes, operationType
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={22}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Дата совершения</Col>
                            <Col span={16}>
                                <RangePicker
                                    className="full-width"
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("createdAt", dateString)}
                                    value={
                                        createdAt?.length && createdAt.every(date => date)
                                            ? [moment(createdAt[0], dateFormat), moment(createdAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Продукты</Col>
                            <Col span={16}>
                                {
                                    this.state.productName
                                        ? this.state.productName.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("productName", event.target.value, index)}
                                                    placeholder="Введите продукт"
                                                    value={this.state.productName[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("productName", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("productName")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Тип операции</Col>
                            <Col span={16}>
                                {
                                    <Select
                                        className="full-width"
                                        onChange={value => this.onSelectorChange("operationType", value)}
                                        placeholder="Выберите тип операции"
                                        value={operationType}
                                    >
                                        {
                                            Object.entries(operationTypes).map(([key, value]) =>
                                                <Select.Option
                                                    key={key}
                                                    value={key}
                                                >
                                                    {value}
                                                </Select.Option>
                                            )
                                        }
                                    </Select>
                                }
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Способы проведения</Col>
                            <Col span={16}>
                                {
                                    executionMode
                                        ? executionMode.map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("executionMode", event.target.value, index)}
                                                    placeholder="Введите способ проведения"
                                                    value={executionMode[index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("executionMode", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("executionMode")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Сумма</Col>
                            <Col span={16}>
                                <Row>
                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("amount", event.target.value, 0)}
                                            placeholder="от"
                                            value={amount[0]}
                                        />
                                    </Col>

                                    <Col span={2}/>

                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("amount", event.target.value, 1)}
                                            placeholder="до"
                                            value={amount[1]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(OperationsFilter);
