export const closePanel = "closePanel";

export const columnsCustomization = "columnsCustomization";

export const applicationsFilter = "applicationsFilter";
export const operationsFilter = "operationsFilter";
export const depositOpsFilter = "depositOpsFilter";
export const loanOpsFilter = "depositOpsFilter";
export const customersFilter = "customersFilter";
export const employeesFilter = "employeesFilter";
export const loyaltiesFilter = "loyaltiesFilter";
export const depositsFilter = "depositsFilter";
export const loansFilter = "loansFilter";
export const officesFilter = "officesFilter";
export const journalFilter = "journalFilter";
export const roomsFilter = "roomsFilter";
export const newsFilter = "newsFilter";

export const notifyCustomer = "notifyCustomer";

export const depositApplicationDetails = "depositApplicationDetails";
export const loanApplicationDetails = "loanApplicationDetails";
export const operationDetails = "operationDetails";
export const depositOpDetails = "depositOpDetails";
export const loanOpDetails = "loanOpDetails";

export const createDivvy = "createDivvy";
export const editLoyalty = "editLoyalty";
export const editNotificationSetting = "editNotificationSetting";

export const confirmCode = "confirmCode";
