import axios from "axios";
import { store } from "@/store";
import { apiVersion } from "@/config/constants";

const service = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API}api/${apiVersion}/`,
    timeout: 0
});

service.interceptors
    .request
    .use(config =>
    {
        const token = store.getState().user.token;

        if (token)
        {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers["Accept-Language"] = "ru";

        return config;
    },
    error =>
    {
        console.error(error);
        Promise.reject(error);
    });

export default service;
