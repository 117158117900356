const ValidationRules = errors =>
[
    () =>
    ({
        validator(_, value)
        {
            if (errors.length)
            {
                return Promise.reject(errors);
            }

            if (value)
            {
                if (errors.length)
                {
                    return Promise.reject(errors);
                }

                return Promise.resolve();
            }

            return Promise.resolve();
        }
    })
];

export default ValidationRules;
