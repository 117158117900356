import { useState, useEffect } from "react";
import "@/styles/text.less";
import "@/styles/links.less";

const CountdownButton = ({ seconds, text, handler }) =>
{
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() =>
    {
        // досрочный выход по достижению 0
        if (!timeLeft)
        {
            return;
        }

        // сохраняем intervalId, чтобы сбросить таймер на перерисовке
        const intervalId =
            setInterval(() =>
            {
                setTimeLeft(timeLeft - 1);
            }, 1000);

        // сбрасываем таймер на перерисовке, чтобы не допускать утечек памяти
        return () => clearInterval(intervalId);

        // добавляем timeLeft в зависимости, чтобы перезапускать эффект на его обновлении
    }, [timeLeft]);

    const onClick = () =>
    {
        setTimeLeft(seconds);
        handler();
    };

    return (
        timeLeft
            ? <span
                    className="placeholder"
            >
                Повторить можно будет через {timeLeft}с.
            </span>
            : <span
                    className="clickable hovered-link"
                    onClick={onClick}
            >
                {text}
            </span>
    );
};

export default CountdownButton;
