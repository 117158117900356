import request from "@/utils/request.js";

export function reqLogin(data)
{
    return request({
        url: "/user/login",
        method: "post",
        data
    });
}

export function reqRegister(data)
{
    return request({
        url: "/user/register",
        method: "post",
        data
    });
}

export function restorePassword(data)
{
    return request({
        url: "/user/password/restore",
        method: "post",
        data
    });
}
