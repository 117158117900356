import { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { message, Spin, Row, Col, Button } from "antd";
import { FileOutlined, CloseOutlined, UserOutlined } from "@ant-design/icons";

import { getDetails, downloadDocument, remove } from "@/api/operation.js";
import downloadStatic from "@/api/downloadStatic.js";
import { toggleSettingPanel } from "@/store/actions";
import FormattedDateTime from "@/components/FormattedDateTime";
import SmartLink from "@/components/SmartLink";
import onCatchRequestError from "@/components/OnCatchRequestError";
import { Administrators } from "@/config/permissions";

import "./Details.less";

class Details extends Component
{
    static propTypes = {
        toggleSettingPanel: PropTypes.func,
        data: PropTypes.object,
        role: PropTypes.string
    }

    constructor(props)
    {
        super(props);

        const { data: { id }, role } = this.props;

        this.state = {
            id,
            loading: true,
            role
        };

        getDetails(this.state.id)
            .then(response =>
            {
                const { meta, data, errors } = response.data;

                if (meta.status === 200)
                {
                    this.setState(data);
                }
                else
                {
                    message.error(errors[0]);
                }
            })
            .catch(onCatchRequestError)
            .finally(() => this.setState({ loading: false }));

        this.onDelete = this.onDelete.bind(this);
    }

    getStatusClass(operationClass)
    { // TODO: Move to utils group ?
        return operationClass === "Приход"
            ? "status-on-label"
            : "status-off-label";
    }

    onDelete()
    {
        this.setState({ loading: true });

        const { id } = this.state;

        remove(id)
            .then(response =>
            {
                const { status, statusText } = response;

                if (status === 200)
                {
                    message.success("Операция удалена");

                    const { toggleSettingPanel, data: { fetchData } } = this.props;
                    toggleSettingPanel();

                    // обновление родительской таблицы
                    fetchData();
                }
                else
                {
                    message.error(statusText);
                }
            })
            .catch(onCatchRequestError)
            .finally(() => this.setState({ loading: false }));
    }

    render()
    {
        const {
            loading, id, role,
            ProductName, Customer, DepositId, Employee,
            CreatedAt, OperationClass, OperationType, Amount, IncomeTax, OverallBalance, ExecutionMode, Document, InitialBalance,
            Region, City, Office
        } = this.state;

        return <>
            <Spin spinning={loading}>
                <Row>
                    <Col span={24}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>ОП-</Col>
                            <Col span={16}>{id}</Col>
                        </Row>
                        {
                            !!CreatedAt && (
                                <Row className="row-simple">
                                    <Col className="bold" span={8}>Дата и время</Col>
                                    <Col span={16}>
                                        <FormattedDateTime dateTime={CreatedAt} />
                                    </Col>
                                </Row>
                            )
                        }
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Продукт</Col>
                            <Col span={16}>{ProductName}</Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Сбережение</Col>
                            <Col span={16}>
                                <SmartLink
                                    className="hovered-link"
                                    to={{ pathname: `/customer/${Customer?.Key}/deposit/${DepositId}/details` }}
                                >
                                    <span>Договор №{DepositId}</span>
                                </SmartLink>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Класс операции</Col>
                            <Col span={16}>
                                <span className={this.getStatusClass(OperationClass)}>
                                    {OperationClass}
                                </span>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Тип операции</Col>
                            <Col span={16}>
                                <span className={this.getStatusClass(OperationClass)}>
                                    {OperationType}
                                </span>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Исходный баланс</Col>
                            <Col span={16}>{InitialBalance}</Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Сумма операции</Col>
                            <Col span={16}>
                                <span className={this.getStatusClass(OperationClass)}>
                                    {Amount}
                                </span>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>НДФЛ</Col>
                            <Col span={16}>{IncomeTax}</Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Баланс по итогу</Col>
                            <Col span={16}>{OverallBalance}</Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Способ проведения</Col>
                            <Col span={16}>{ExecutionMode}</Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Кассир</Col>
                            <Col span={16}>
                                {
                                    Employee &&
                                        <SmartLink
                                            className="hovered-link"
                                            to={{ pathname: `/employee/${Employee.Id}/profile` }}
                                        >
                                            <UserOutlined className="prefix" />
                                            <span>{Employee.Value}</span>
                                        </SmartLink>
                                }
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Документ</Col>
                            <Col span={16}>
                                {
                                    Document &&
                                        <Row className="row">
                                            <Col span={22}>
                                                <span
                                                    className="clickable hovered-link"
                                                    onClick={() => downloadStatic(
                                                        downloadDocument(Document?.Key),
                                                        state => this.setState(state)
                                                    )}
                                                >
                                                    <FileOutlined className="prefix" />
                                                    <span>
                                                        {Document?.Value}
                                                    </span>
                                                </span>
                                            </Col>
                                        </Row>
                                }
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Регион</Col>
                            <Col span={16}>{Region}</Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Город</Col>
                            <Col span={16}>{City}</Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Офис</Col>
                            <Col span={16}>
                                {
                                    Office &&
                                        <SmartLink
                                            className="hovered-link"
                                            to={{ pathname: `/office/${Office.Key}/details` }}
                                        >
                                            <span>
                                                {Office.Value}
                                            </span>
                                        </SmartLink>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    Administrators.includes(role) &&
                        <Row className="row">
                            <Button
                                danger
                                icon={<CloseOutlined />}
                                onClick={this.onDelete}
                                type="primary"
                            >
                                Удалить операцию
                            </Button>
                        </Row>
                }
            </Spin>
        </>;
    }
}

export default connect(
    state => state.user,
    { toggleSettingPanel }
)(withRouter(Details));
