import roles, {
    DefaultTableFunctionality,
    All,
    Employees
} from "../roles";

export const Loans = {
    Section: All,
    All: {
        Data: All,
        Export: [roles.SuperAdministrator],
        ...DefaultTableFunctionality

    },
    Overdue: {
        Data: Employees,
        Export: [roles.SuperAdministrator],
        ...DefaultTableFunctionality
    },
    Archive: {
        Data: All,
        Export: [roles.SuperAdministrator],
        ...DefaultTableFunctionality
    },
    UpcomingPayments: {
        Data: All,
        SetupColumn: Employees
    },
    CommittedOperations: {
        Data: All,
        SetupColumn: Employees,
        Open: All,
        Download: All
    }
};
