import React from "react";
import { ClearOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Button, Select, Spin, Col, Row, message } from "antd";
import { withRouter } from "react-router-dom";

import { getNamesCatalog } from "@/api/employee.js";
import Filter from "@/components/Filter";
import onCatchRequestError from "@/components/OnCatchRequestError";

class ApplicationsFilter extends Filter
{
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            loading: true,
            preloadedThreshold: 0,
            singles: ["status", "employee", "source"],
            ranges: [],
            multiples: [],
            intersections: [],
            namesCatalog: []
        };

        getNamesCatalog()
            .then(response =>
            {
                const { meta, data, errors } = response.data;

                if (meta.status === 200)
                {
                    this.setState({ namesCatalog: data });
                }
                else
                {
                    message.error(errors[0]);
                }
            })
            .catch(onCatchRequestError)
            .finally(() => this.setState({ loading: false }));
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render() {
        const {
            loading,
            status,
            employee,
            source,
            namesCatalog
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row-simple">
                    <Col span={22}>
                        <Row className="row-simple" gutter={10}>
                            <Col className="bold" span={8}>Статус</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => this.onSelectorChange("status", value)}
                                    value={status}
                                >
                                    <Select.Option value="">Любой</Select.Option>
                                    <Select.Option value="Новая">Новая</Select.Option>
                                    <Select.Option value="Одобрена">Одобрена</Select.Option>
                                    <Select.Option value="В обработке">В обработке</Select.Option>
                                    <Select.Option value="Отклонена">Отклонена</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className="row-simple" gutter={10}>
                            <Col className="bold" span={8}>Менеджер</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => this.onSelectorChange("employee", value)}
                                    value={employee}
                                >
                                    <Select.Option value="">Любой</Select.Option>
                                    {
                                        Object.values(namesCatalog).map(name => <Select.Option key={name} value={name}>{name}</Select.Option>)
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Row className="row-simple" gutter={10}>
                            <Col className="bold" span={8}>Источник</Col>
                            <Col span={16}>
                                <Select
                                    className="full-width"
                                    onChange={value => this.onSelectorChange("source", value)}
                                    value={source}
                                >
                                    <Select.Option value="">Любой</Select.Option>
                                    <Select.Option value="Приложение">Приложение</Select.Option>
                                    <Select.Option value="Сайт">Сайт</Select.Option>
                                    <Select.Option value="Офис">Офис</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            className="d-flex items-center"
                            icon={<CheckCircleOutlined className="d-flex" />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix d-flex items-center"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(ApplicationsFilter);
