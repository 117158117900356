import { getItem, editItem } from "./crud.js";

const url = "/messageSettings";

export default url;

export function update(data)
{
    return editItem(url, data);
}

export function getDetails(id)
{
    return getItem(url, id);
}
