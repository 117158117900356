import { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { message, Spin, Row, Col } from "antd";
import { FileOutlined, UserOutlined } from "@ant-design/icons";

import { getDetails, downloadDocument } from "@/api/loanOperation.js";
import downloadStatic from "@/api/downloadStatic.js";
import { toggleSettingPanel } from "@/store/actions";
import FormattedDateTime from "@/components/FormattedDateTime";
import onCatchRequestError from "@/components/OnCatchRequestError";
import SmartLink from "@/components/SmartLink";

import "./Details.less";

class Details extends Component
{
    static propTypes = {
        data: PropTypes.object
    }

    constructor(props)
    {
        super(props);

        const { data: { id } } = this.props;

        this.state = {
            id,
            loading: true
        };

        getDetails(this.state.id)
            .then(response =>
            {
                const { meta, data, errors } = response.data;

                if (meta.status === 200)
                {
                    this.setState(data);
                }
                else
                {
                    message.error(errors[0]);
                }
            })
            .catch(onCatchRequestError)
            .finally(() => this.setState({ loading: false }));
    }

    getStatusClass(operationClass)
    { // TODO: Move to utils group ?
        return operationClass === "Приход"
            ? "status-on-label"
            : "status-off-label";
    }

    render()
    {
        const {
            loading, id,
            ProductName, Customer, LoanId, Employee,
            CreatedAt, OperationClass, OperationType, Amount, ExecutionMode, Document,
            Region, City, Office
        } = this.state;

        return <>
            <Spin spinning={loading}>
                <Col span={20}>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Операция</Col>
                        <Col span={16}>ОП-{id}</Col>
                    </Row>
                    {
                        !!CreatedAt && (
                            <Row className="row-simple">
                                <Col className="bold" span={8}>Дата и время</Col>
                                <Col span={16}>
                                    <FormattedDateTime dateTime={CreatedAt} />
                                </Col>
                            </Row>
                        )
                    }
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Продукт</Col>
                        <Col span={16}>{ProductName}</Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Займ</Col>
                        <Col span={16}>
                            <SmartLink
                                className="hovered-link"
                                to={{ pathname: `/customer/${Customer?.Key}/loan/${LoanId}/details` }}
                            >
                                <span>
                                    Договор №{LoanId}
                                </span>
                            </SmartLink>
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Класс операции</Col>
                        <Col span={16}>
                            <span className={this.getStatusClass(OperationClass)}>
                                {OperationClass}
                            </span>
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Тип операции</Col>
                        <Col span={16}>
                            <span className={this.getStatusClass(OperationClass)}>
                                {OperationType}
                            </span>
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Сумма операции</Col>
                        <Col span={16}>
                            <span className={this.getStatusClass(OperationClass)}>
                                {Amount}
                            </span>
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Способ проведения</Col>
                        <Col span={16}>{ExecutionMode}</Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Кассир</Col>
                        <Col span={16}>
                            {
                                !!Employee?.Value &&
                                    <SmartLink
                                        className="hovered-link"
                                        to={{ pathname: `/employee/${Employee.Key}/profile` }}
                                    >
                                        <UserOutlined className="prefix" />
                                        <span>
                                            {Employee.Value}
                                        </span>
                                    </SmartLink>
                            }
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Документ</Col>
                        <Col span={16}>
                            {
                                Document &&
                                    <Row className="row">
                                        <Col span={22}>
                                            <span
                                                className="clickable hovered-link"
                                                onClick={() => downloadStatic(
                                                    downloadDocument(Document?.Key),
                                                    state => this.setState(state)
                                                )}
                                            >
                                                <FileOutlined className="prefix" />
                                                <span>
                                                    {Document?.Value}
                                                </span>
                                            </span>
                                        </Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Офис</Col>
                        <Col span={16}>
                            {
                                Office &&
                                    <SmartLink
                                        className="hovered-link"
                                        to={{ pathname: `/office/${Office.Key}/details` }}
                                    >
                                        <span>
                                            {Office.Value}
                                        </span>
                                    </SmartLink>
                            }
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Регион</Col>
                        <Col span={16}>{Region}</Col>
                    </Row>
                    <Row className="row">
                        <Col className="bold" span={8}>Город</Col>
                        <Col span={16}>{City}</Col>
                    </Row>
                </Col>
                <Col span={4} />
            </Spin>
        </>;
    }
}

export default connect(
    state => state.user,
    { toggleSettingPanel }
)(withRouter(Details));
