import React, { useCallback } from "react";
import { Button, message, Row, Input } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import onCatchRequestError from "@/components/OnCatchRequestError";
import useInputEntity from "@/hooks/useInputEntity";

const ConfirmCode = props =>
{
    const {
        data: {
            onSuccess,
            confirmationRequest,
            data
        }
    } = props;

    const {
        state: { code },
        handleInputChange
    } = useInputEntity({ code: "" });

    const handleConfirm = useCallback(() =>
    {
        confirmationRequest(data, { code })
            .then(() =>
            {
                message.success("Успех");

                onSuccess();
            })
            .catch(onCatchRequestError);
    }, [confirmationRequest, data, code, onSuccess]);

    return (
        <>
            <Input
                name="code"
                onChange={handleInputChange}
                placeholder="Введите код"
                value={code}
            />
            <Row className="row">
                <Button
                    className="green-button"
                    icon={<CheckOutlined />}
                    onClick={handleConfirm}
                    type="primary"
                >
                    Подтвердить
                </Button>
            </Row>
        </>
    );
};

export default ConfirmCode;
