import { withRouter } from "react-router-dom";
import { Button, Radio, DatePicker, Col, Row, Spin } from "antd";
import { CheckCircleOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";

import { dateFormat } from "@/config/constants";
import Filter from "@/components/Filter";

const { RangePicker } = DatePicker;

class NewsFilter extends Filter
{
    constructor(props)
    {
        super(props);

        this.state = {
            ...this.state,

            loading: false,
            preloadedThreshold: 0,
            singles: ["isPublished"],
            ranges: ["createdAt", "publishedAt"]
        };
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render()
    {
        const {
            loading,
            isPublished,
            createdAt, publishedAt
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={22}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Опубликовано?</Col>
                            <Col span={16}>
                                <Radio.Group
                                    className="full-width d-flex"
                                    onChange={event => this.onSelectorChange("isPublished", event.target.value)}
                                    value={isPublished}
                                >
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={true}
                                    >
                                        Да
                                    </Radio.Button>
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={false}
                                    >
                                        Нет
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Дата создания</Col>
                            <Col span={16}>
                                <RangePicker
                                    className="full-width"
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("createdAt", dateString)}
                                    value={
                                        createdAt?.length && createdAt.every(date => date)
                                            ? [moment(createdAt[0], dateFormat), moment(createdAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Дата публикации</Col>
                            <Col span={16}>
                                <RangePicker
                                    className="full-width"
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("publishedAt", dateString)}
                                    value={
                                        publishedAt?.length && publishedAt.every(date => date)
                                            ? [moment(publishedAt[0], dateFormat), moment(publishedAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={2} />
                </Row>

                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(NewsFilter);
