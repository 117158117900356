import * as types from "../action-types.js";

export const setUserInfo =
    userInfo =>
        ({
            type: types.USER_SET_USER_INFO,
            ...userInfo
        });

export const resetUser =
    () =>
        ({
            type: types.USER_RESET_USER
        });
