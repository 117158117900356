import { useMemo } from "react";
import { Radio, Input } from "antd";

const PresetedInput = props =>
{
    const {
        presets,
        onInputChange,
        amount,
        suffix,
        disabled
    } = props;

    const presetsEl = useMemo(
        () => presets ?
            <Radio.Group
                disabled={disabled}
                className="full-width d-flex"
                name="amount"
                onChange={onInputChange}
                value={amount}
            >
                {
                    presets?.map(value =>
                        <Radio.Button
                            className="flex-1 centred"
                            key={value}
                            value={value}
                        >
                            {value.toLocaleString()}
                        </Radio.Button>)
                }
            </Radio.Group>
            : null,
        [presets, amount, onInputChange]
    );

    return (
        <>
            {presetsEl}
            {
                !props.disabled &&
                    <Input
                        name="amount"
                        onChange={onInputChange}
                        placeholder="Укажите количество баллов"
                        style={{ marginTop: "5px" }}
                        suffix={suffix}
                        value={amount}
                    />
            }
        </>
    );
};

export default PresetedInput;
