import { transform } from "lodash";
import { useEffect, useCallback } from "react";
import { message } from "antd";

import { getDetails, update } from "@/api/messageSettings.js";
import onCatchRequestError from "@/components/OnCatchRequestError";
import useInputEntity from "@/hooks/useInputEntity";

const KEYS = new Set([
    "id", "type", "channel", "isEnabled", "title", "body"
]);

const transformToServer = source => transform(source, (result, value, key) =>
{
    if (!KEYS.has(key))
    {
        return;
    }

    result[key] = value;
});

const useNotificationSettingForm = (Id, props) =>
{
    const {
        state,
        handleLoadingSet,
        handleLoadingReset,
        handleFieldChange,
        handleInputChange,
        handleRadioChange
    } = useInputEntity({
        preloadedThreshold: 1,
        id: Id,
        type: "",
        channel: "",
        isEnabled: false,
        title: "",
        body: ""
    });

    useEffect(
        () =>
        {
            getDetails(Id)
                .then(response =>
                {
                    handleLoadingSet();
                    const { meta, data, errors } = response.data;

                    if (meta.status === 200)
                    {
                        handleFieldChange("type", data.Type);
                        handleFieldChange("channel", data.Channel);
                        handleFieldChange("isEnabled", data.IsEnabled);
                        handleFieldChange("title", data.Title);
                        handleFieldChange("body", data.Body);
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingReset, handleLoadingSet, Id]
    );

    const onChange = useCallback(
        () =>
        {
            handleLoadingSet();

            const { id, isEnabled, title, body } = transformToServer(state);

            update({ id, isEnabled, title, body })
                .then(response =>
                {
                    const { status, statusText } = response;

                    if (status === 200)
                    {
                        message.success("Настройки обновлены");

                        const { toggleSettingPanel } = props;
                        toggleSettingPanel();

                        window.location.reload();
                    }
                    else
                    {
                        message.error(statusText);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, handleLoadingReset, state]
    );

    return {
        loading: state.loading,
        handleLoadingSet,
        handleLoadingReset,
        handleFieldChange,
        handleInputChange,
        handleRadioChange,

        type: state.type,
        channel: state.channel,
        isEnabled: state.isEnabled,
        title: state.title,
        body: state.body,

        getDetails,
        onChange
    };
};

export default useNotificationSettingForm;
