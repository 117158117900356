import OnCatchRequestErrorDecorator from "@/components/OnCatchRequestErrorDecorator";

function setErrors(errors)
{
    this.setState(errors)
}

// ToDo избавиться после перехода на хуки
export default function onCatchRequestError(error)
{
    OnCatchRequestErrorDecorator(error, setErrors);
}
