import { Component } from "react";
import "@/styles/dateTime.less";

class FormattedDateTime extends Component
{
    render()
    {
        const { dateTime } = this.props;

        const parts = dateTime?.split(" ");

        return !!dateTime && (
            <>
                <span className="date">{parts[0]} </span>
                <span className="time">{parts[1]} {parts[2]}</span>
            </>
        );
    }
}

export default FormattedDateTime;
