import { Form, Button, Space, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { createElement } from "react";

import { FormItem } from "@/components/Form";
import { AutoComplete } from "@/components/Form/AutoComplete";
import MaskedInput from "@/components/Form/MaskedInput";
import ValidationRules from "./ValidationRules";

const fieldTypes = {
    Input,
    AutoComplete,
    MaskedInput
};

export function FormList({
    addBtnLabel,
    errors,
    formItemType,
    isMustHave,
    isRequired,
    name,
    onChange,
    placeholder,
    suffix,
    ...rest
})
{
    return (
        <Form.List
            name={name}
            rules={ValidationRules(errors)}
        >
            {(fields, { add, remove }, { errors }) =>
                <>
                    {
                        fields.map(field =>
                            <Space
                                align="baseline"
                                className="d-flex"
                                key={field.key}
                                style={{ marginBottom: 20 }}
                            >
                                <FormItem
                                    {...field}
                                    rules={[{ isRequired }]}
                                >
                                    {
                                        createElement(
                                            fieldTypes[formItemType],
                                            {
                                                classname: cx({ "multiple-element": !isMustHave || fields.length > 1 }),
                                                field,
                                                onChange,
                                                placeholder,
                                                isRequired,
                                                suffix,
                                                ...rest
                                            }
                                        )
                                    }
                                </FormItem>
                                {
                                    (!isMustHave || fields.length > 1) &&
                                        <Button
                                            danger
                                            icon={<MinusCircleOutlined />}
                                            onClick={() => remove(field.name)}
                                            size="small"
                                            type="link"
                                        />
                                }
                            </Space>
                        )
                    }
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                        size="small"
                        type="dashed"
                    >
                        {addBtnLabel}
                    </Button>
                    <Form.ErrorList errors={errors} />
                </>
            }
        </Form.List>
    );
}

FormList.propTypes = {
    addBtnLabel: PropTypes.string,
    errors: PropTypes.array,
    formItemType: PropTypes.string,
    isMustHave: PropTypes.bool,
    isRequired: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    suffix: PropTypes.string
};

FormList.defaultProps = {
    addBtnLabel: "",
    errors: [],
    formItemType: "Input",
    isMustHave: false,
    isRequired: false,
    name: "",
    onChange: () => Function,
    placeholder: "",
    suffix: ""
};

FormList.displayName = "FormList";
