import { combineReducers } from "redux";
import app from "./app.js";
import user from "./user.js";
import breadcrumbs from "./breadcrumbs.js";

export default combineReducers({
    user,
    app,
    breadcrumbs
});
