import { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Menu, Layout } from "antd";

import { USER_PANEL, ADMIN_PANEL } from "@/store/toggleMode-types.js";
import menuList from "@/config/menu/menuConfig.jsx";
import adminMenuList from "@/config/menu/adminPanel/menuConfig.jsx";

import "./index.less";

const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

const menuMode = {
    [USER_PANEL]: menuList,
    [ADMIN_PANEL]: adminMenuList,
    get(type) {
        return this[type] || this[USER_PANEL];
    }
}

class CustomMenu extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            openKeys: []
        };
    }

    getMenuNodes(menu)
    {
        return menu.reduce((result, item) =>
        {
            if (this.filterMenuItem(item))
            {
                if (!item.notInMenu)
                {
                    if (!item.children)
                    {
                        result.push(
                            <Menu.Item key={item.path}>
                                <Link to={item.path}>
                                    {item.icon}
                                    <span>
                                        {item.title}
                                    </span>
                                </Link>
                            </Menu.Item>
                        );

                        return result;
                    }

                    result.push(
                        <SubMenu
                            key={item.path}
                            title={
                                <span>
                                    {item.icon}
                                    <span>
                                        {item.title}
                                    </span>
                                </span>
                            }
                        >
                            {this.getMenuNodes(item.children)}
                        </SubMenu>
                    );
                }
            }

            return result;
        },
        []);
    }

    filterMenuItem(item)
    {
        const { roles } = item;
        const { role } = this.props;

        if (roles.includes(role))
        {
            return true;
        }
        else if (item.children)
        {
            return !!item.children.find(child => roles.includes(child.role));
        }

        return false;
    }

    render()
    {
        const { modeType, location } = this.props;
        const path = location.pathname;
        const menuTree = this.getMenuNodes(menuMode.get(modeType));

        return (
            <Sider theme="light">
                <div className="sidebar-menu-container">
                    <Scrollbars autoHide>
                        <Menu
                            mode="inline"
                            onOpenChange={openKeys => this.setState({ openKeys })}
                            openKeys={this.state.openKeys}
                            selectedKeys={[path]}
                            theme="light"
                        >
                            {menuTree}
                        </Menu>
                    </Scrollbars>
                </div>
            </Sider>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.app })
)(withRouter(CustomMenu));

CustomMenu.displayName = "CustomMenu";
