import {
    reqLogin, reqRegister
} from "@/api/auth.js";
import {
    reqConfirmPhone, reqConfirmEmailUpdate, reqConfirmPassword
} from "@/api/user.js";
import { setUser, removeUser } from "@/utils/auth.js";
import { setUserInfo, resetUser } from "./user.js";
import { reset } from "./app.js";

export const login =
    (username, password) =>
        dispatch =>
            new Promise((resolve, reject) =>
                reqLogin({ username: username.trim(),
                    password })
                    .then(response =>
                    {
                        const { meta, data, errors } = response.data;
                        if (meta.status === 200)
                        {
                            setUser(data);
                            dispatch(setUserInfo(data));
                            resolve(data);
                        }
                        else
                        {
                            reject(errors[0].message);
                        }
                    })
                    .catch(error => reject(error)));

export const logout =
    () =>
        dispatch =>
            new Promise((resolve, reject) =>
            {
                removeUser();
                dispatch(resetUser());
                dispatch(reset());
                resolve();
            });

export const register =
    data =>
        dispatch =>
            new Promise((resolve, reject) =>
                reqRegister(data)
                    .then(response =>
                    {
                        const { meta, data, errors } = response.data;
                        if (meta.status === 200)
                        {
                            setUser(data);
                            dispatch(setUserInfo(data));
                            resolve(data);
                        }
                        else
                        {
                            reject(errors[0].message);
                        }
                    })
                    .catch(error => reject(error)));

export const confirmEmailUpdate =
    (data, params) =>
        dispatch =>
            new Promise((resolve, reject) =>
                reqConfirmEmailUpdate(data, params)
                    .then(response =>
                    {
                        const { meta, data, errors } = response.data;
                        if (meta.status === 200)
                        {
                            setUser(data);
                            dispatch(setUserInfo(data));
                            resolve(data);
                        }
                        else
                        {
                            reject(errors[0].message);
                        }
                    })
                    .catch(error => reject(error)));

export const confirmPhone =
    (data, params) =>
        dispatch =>
            new Promise((resolve, reject) =>
                reqConfirmPhone(data, params)
                    .then(response =>
                    {
                        const { meta, data, errors } = response.data;
                        if (meta.status === 200)
                        {
                            setUser(data);
                            dispatch(setUserInfo(data));
                            resolve(data);
                        }
                        else
                        {
                            reject(errors[0].message);
                        }
                    })
                    .catch(error => reject(error)));

export const confirmPassword =
    (data, params) =>
        dispatch =>
            new Promise((resolve, reject) =>
                reqConfirmPassword(data, params)
                    .then(response =>
                    {
                        const { meta, data, errors } = response.data;
                        if (meta.status === 200)
                        {
                            setUser(data);
                            dispatch(setUserInfo(data));
                            resolve(data);
                        }
                        else
                        {
                            reject(errors[0].message);
                        }
                    })
                    .catch(error => reject(error)));
