import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reduxThunk from "redux-thunk";
import reducer from "./reducers";

const persistConfig = { // configuration object for redux-persist
    key: "root",
    storage, // define which storage to use
    whitelist: ["app"] // only will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, applyMiddleware(reduxThunk));

const persistor = persistStore(store);

export { store, persistor };
