import { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/ru_RU";
import { store, persistor } from "./store";
import Router from "./router";

export default class App extends Component
{
    render()
    {
        return (
            <ConfigProvider locale={locale}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router />
                    </PersistGate>
                </Provider>
            </ConfigProvider>
        );
    }
}
