const roles = {
    SuperAdministrator: "SuperAdministrator",
    Administrator: "Administrator",
    OfficeManager: "OfficeManager",
    DepositManager: "DepositManager",
    LoanManager: "LoanManager",
    ScoringManager: "ScoringManager",
    Customer: "Customer"
};

const Administrators = [
    roles.SuperAdministrator,
    roles.Administrator
];

const SuperAdminAndAdminAndOfficeManager = [
    roles.OfficeManager,
    ...Administrators
];

const AllExceptAdminAndCustomerAndScoringAndLoanManager = [
    roles.SuperAdministrator,
    roles.OfficeManager,
    roles.DepositManager
];

const AllExceptCustomerAndScoringAndLoanManager = [
    roles.Administrator,
    ...AllExceptAdminAndCustomerAndScoringAndLoanManager
];

const AllExceptScoringAndLoanManager = [
    roles.Customer,
    ...AllExceptCustomerAndScoringAndLoanManager
];

const AllExceptLoanManager = [
    roles.ScoringManager,
    ...AllExceptScoringAndLoanManager
];

const AllExceptLoanManagerAndCustomer = [
    roles.ScoringManager,
    ...AllExceptCustomerAndScoringAndLoanManager
];

const AllExceptCustomerAndAdmin = [
    roles.SuperAdministrator,
    roles.OfficeManager,
    roles.DepositManager,
    roles.LoanManager,
    roles.ScoringManager
];

const Employees = [
    roles.Administrator,
    ...AllExceptCustomerAndAdmin
];

const All = [
    roles.Customer,
    ...Employees
];

const SuperAdministratorAndCustomerAndDepositManager = [
    roles.SuperAdministrator,
    roles.Customer,
    roles.DepositManager
];

const DefaultTableFunctionality = {
    Search: Employees,
    Filter: Employees,
    SetupColumn: Employees
};
const TableFunctionalityExceptLoanManagerAndCustomer = {
    Search: AllExceptLoanManagerAndCustomer,
    Filter: AllExceptLoanManagerAndCustomer,
    SetupColumn: AllExceptLoanManagerAndCustomer
};

const AdministratorsTableFunctionality = {
    Data: Administrators,
    Search: Administrators,
    Filter: Administrators,
    SetupColumn: Administrators
};

const Customer = [roles.Customer];

export default roles;
export {
    Customer,
    DefaultTableFunctionality,
    Administrators,
    AdministratorsTableFunctionality,
    All,
    TableFunctionalityExceptLoanManagerAndCustomer,
    Employees,
    AllExceptCustomerAndAdmin,
    AllExceptScoringAndLoanManager,
    AllExceptCustomerAndScoringAndLoanManager,
    AllExceptAdminAndCustomerAndScoringAndLoanManager,
    SuperAdminAndAdminAndOfficeManager,
    AllExceptLoanManager,
    AllExceptLoanManagerAndCustomer,
    SuperAdministratorAndCustomerAndDepositManager,
    roles
};
