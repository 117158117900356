import { useState } from "react";
import progress from "@/utils/progress.js";

const Loading = () =>
{
    useState(() =>
    {
        progress.remove(); // чтобы убрать незавершившийся лоадер предыдущего окна
        progress.done(true);
    });

    return null;
};

export default Loading;
