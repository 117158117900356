import roles, {
    DefaultTableFunctionality,
    All,
    Employees,
    AllExceptCustomerAndAdmin,
    AllExceptScoringAndLoanManager,
    AllExceptCustomerAndScoringAndLoanManager,
    AllExceptAdminAndCustomerAndScoringAndLoanManager,
    SuperAdminAndAdminAndOfficeManager,
    Administrators
} from "../roles";

const Default = {
    Data: Employees,
    Add: Employees,
    Export: [roles.SuperAdministrator],
    ...DefaultTableFunctionality
};

export const Customers = {
    All: {
        ...Default,
        Notify: Administrators
    },
    Free: Default,
    Archive: Default,
    Customer: {
        Profile: {
            Data: All,
            Assign: Employees,
            Exit: Employees,
            Join: Employees,
            Edit: Employees,
            Archive: Employees,
            Recover: Employees
        },
        Operations: {
            Data: All,
            Export: AllExceptCustomerAndAdmin,
            ...DefaultTableFunctionality
        },
        Applications: {
            Data: Employees,
            ...DefaultTableFunctionality
        },
        Deposits: {
            Data: AllExceptScoringAndLoanManager,
            Search: AllExceptCustomerAndScoringAndLoanManager,
            Filter: AllExceptCustomerAndScoringAndLoanManager,
            Export: AllExceptAdminAndCustomerAndScoringAndLoanManager,
            SetupColumn: AllExceptCustomerAndScoringAndLoanManager,
            Add: AllExceptCustomerAndScoringAndLoanManager
        },
        Loans: {
            Data: All,
            Export: AllExceptCustomerAndAdmin,
            Add: Employees,
            ...DefaultTableFunctionality
        },
        Loyalty: {
            Data: AllExceptCustomerAndScoringAndLoanManager,
            Filter: AllExceptCustomerAndScoringAndLoanManager,
            Export: AllExceptAdminAndCustomerAndScoringAndLoanManager,
            SetupColumn: AllExceptCustomerAndScoringAndLoanManager,
            Add: AllExceptCustomerAndScoringAndLoanManager
        },
        Appeals: {
            Data: All,
            Add: All,
            ...DefaultTableFunctionality
        },
        Notifications: {
            Data: All,
            SetupColumn: Employees
        },
        Journal: {
            Data: SuperAdminAndAdminAndOfficeManager,
            Search: SuperAdminAndAdminAndOfficeManager,
            Filter: SuperAdminAndAdminAndOfficeManager,
            SetupColumn: SuperAdminAndAdminAndOfficeManager
        }
    }
};
