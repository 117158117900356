import request from "@/utils/request.js";
import { editItem, editByString, confirmation } from "./crud.js";

const url = "user";

export function getProfile()
{
    return request({
        url: `${url}/profile`,
        method: "get"
    });
}

export function update(data)
{
    return editItem(url, data);
}

export function updateEmail(data)
{
    return editByString(`${url}/email/update`, data);
}

export function updatePhone(data)
{
    return editByString(`${url}/phone/update`, data);
}

export function updatePassword(data)
{
    return editItem(`${url}/password/update`, data);
}

export function reqConfirmPassword(data, params)
{
    return confirmation(`${url}/password/confirm`, data, params);
}

export function reqConfirmEmailUpdate(data, params)
{
    return confirmation(`${url}/email-update/confirm`, data, params);
}

export function reqConfirmPhone(data, params)
{
    return confirmation(`${url}/phone/confirm`, data, params);
}
