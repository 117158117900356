import roles,
{
    TableFunctionalityExceptLoanManagerAndCustomer,
    AllExceptLoanManager,
    AllExceptLoanManagerAndCustomer
} from "../roles";

const Default = {
    Data: AllExceptLoanManager,
    Export: [roles.SuperAdministrator],
    ...TableFunctionalityExceptLoanManagerAndCustomer
};

export const Deposits = {
    Section: AllExceptLoanManager,
    Deposit: {
        Information: {
            Data: AllExceptLoanManager,
            GenerateDocByTemple: AllExceptLoanManagerAndCustomer,
            AddTag: AllExceptLoanManagerAndCustomer,
            RemoveTag: AllExceptLoanManagerAndCustomer,
            AddNote: AllExceptLoanManagerAndCustomer,
            RemoveNote: AllExceptLoanManagerAndCustomer,
            AddDoc: AllExceptLoanManagerAndCustomer,
            RemoveDoc: AllExceptLoanManagerAndCustomer,
            Search: AllExceptLoanManagerAndCustomer
        }
    },
    All: Default,
    Archive: Default,
    UpcomingPayments: {
        Data: AllExceptLoanManager,
        SetupColumn: AllExceptLoanManagerAndCustomer
    },
    CommittedOperations: {
        Data: AllExceptLoanManager,
        SetupColumn: AllExceptLoanManagerAndCustomer,
        Open: AllExceptLoanManager,
        Download: AllExceptLoanManager
    }
};
