import { Component } from "react";
import "./index.less";

class InputEntity extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            loading: true,
            preloaded: 0,
            preloadedThreshold: 1
        };

        this.appendField = this.appendField.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onRemoveField = this.onRemoveField.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.onSelectorChange = this.onSelectorChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    Preloaded()
    {
        this.setState({ preloaded: this.state.preloaded + 1 });

        const { preloaded, preloadedThreshold } = this.state;

        if (preloaded === preloadedThreshold)
        {
            this.setState({ loading: false });
        }
    }

    appendField(fieldName, defaultValue = "")
    {
        this.setState({
            [fieldName]:
                this.state[fieldName]
                    ? this.state[fieldName].concat(defaultValue)
                    : [defaultValue]

        });
    }

    onFieldChange(fieldName, value, id)
    {
        this.state[fieldName].splice(id, 1, value);
        this.setState({ [fieldName]: this.state[fieldName] });
    }

    onRemoveField(fieldName, id)
    {
        this.state[fieldName].splice(id, 1);
        this.setState({ [fieldName]: this.state[fieldName] });
    }

    onInputChange(event)
    {
        this.setState({ [event.target.name]: event.target.value });
    }

    onSelectorChange(fieldName, value)
    {
        this.setState({ [fieldName]: value });
    }

    onCheckboxChange(event)
    {
        this.setState({ [event.target.name]: event.target.checked });
    }
}

export default InputEntity;
