/**
 * Преобразовывает дату в корректный для передачи на сервер формат.
 *
 * @param {string} date - Передаваемая дата.
 * @returns {string} Дата в формате YYYY-MM-DD.
 */
export function toTransportableDate(date)
{
    return date.split(".")
        .reverse()
        .join("-");
}

/**
 * Преобразовывает дату и время в корректный для передачи на сервер формат.
 *
 * @param {string} dateTime - Передаваемые дата-время.
 * @returns {string} Дата в формате YYYY-MM-DD HH:mm.
 */
export function toTransportableDateTime(dateTime)
{
    const tmp = dateTime.split(" ");
    const date =
        tmp[0].split(".")
            .reverse()
            .join("-");

    return `${date} ${tmp[1]}`;
}

/**
 * Преобразует дату из формата передачи в рабочий вид.
 *
 * @param {string} date - Полученная дата.
 * @returns {string} Дата в формате DD.MM.YYYY.
 */
export function getDate(date)
{
    return date.split("-")
        .reverse()
        .join(".");
}

/**
 * Заменяет в исходной строке первую букву на заглавную.
 *
 * @param {string} string - Изменяемая строка.
 * @returns {string} Исходная строка с первой заглавной буквой.
 */
export function capitalizeFirstLetter(string)
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Заменяет в исходной строке первую букву на прописную.
 *
 * @param {string} string - Изменяемая строка.
 * @returns {string} Исходная строка с первой прописной буквой.
 */
export function uncapitalizeFirstLetter(string)
{
    return string.charAt(0).toLowerCase() + string.slice(1);
}

/**
 * Преобразует число к более удобочитаемому виду, расставляя запятые на тысячные разряды.
 *
 * @param {number|string} number - Преобразуемое число.
 * @returns {string} Число с разбиением по тысячным разрядам (1234567.1234 -> 1,234,567.1234).
 */
export function numberWithCommas(number)
{
    return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Накладывает на номер телефона в "чистом" виде маску для отображения.
 *
 * @param {number|string} phone - Номер телефона в чистом виде (7xxxxxxxxxx).
 * @returns {string} Номер телефона с применённой маской (+7 xxx xxx-xx-xx).
 */
export function applyPhoneMask(phone)
{
    return `+7 ${phone.substring(1, 4)} ${phone.substring(4, 7)}-${phone.substring(7, 9)}-${phone.substring(9, 11)}`;
}
