import { addItem, getItem, deleteItem, getList } from "./crud.js";
import { download, upload } from "./document.js";

const url = "/operations";

export default url;

export const upcomingUrl = "/upcoming";

const opTypes = `${url}/operation-types`;

export function create(data)
{
    return addItem(url, data);
}

export function getDetails(id)
{
    return getItem(url, id);
}

export function getCatalog()
{
    return getList(opTypes);
}

export function remove(id)
{
    return deleteItem(url, id);
}

export function uploadDocument(ownerId, file, data)
{
    return upload(url, ownerId, file, data);
}

export function downloadDocument(guid)
{
    return download(url, guid);
}
