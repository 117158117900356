import TableCustomization from "@/components/Table/TableCustomization.jsx";

import ApplicationsFilter from "@/views/applications/ApplicationsFilter.jsx";
import OperationsFilter from "@/views/operations/OperationsFilter.jsx";
import DepositOpsFilter from "@/views/deposits/operations/OperationsFilter.jsx";
import LoanOpsFilter from "@/views/loans/operations/OperationsFilter.jsx";
import CustomersFilter from "@/views/customers/CustomersFilter.jsx";
import EmployeesFilter from "@/views/adminPanel/employees/EmployeesFilter.jsx";
import LoyaltiesFilter from "@/views/adminPanel/loyalties/customers/LoyaltiesFilter.jsx";
import DepositsFilter from "@/views/deposits/DepositsFilter.jsx";
import LoansFilter from "@/views/loans/LoansFilter.jsx";
import OfficesFilter from "@/views/adminPanel/offices/OfficesFilter.jsx";
import JournalFilter from "@/views/journal/JournalFilter.jsx";
import RoomsFilter from "@/views/helpDesk/RoomsFilter.jsx";
import NewsFilter from "@/views/adminPanel/news/NewsFilter.jsx";

import DepositApplicationDetails from "@/views/applications/deposit/Details.jsx";
import LoanApplicationDetails from "@/views/applications/loan/Details.jsx";
import OperationDetails from "@/views/operations/operation/Details.jsx";
import DepositOpDetails from "@/views/deposits/operations/operation/Details.jsx";
import LoanOpDetails from "@/views/loans/operations/operation/Details.jsx";

import NotifyCustomer from "@/views/notifications/Notify/Create.jsx";

import CreateDivvy from "@/views/customers/customer/divvy/Create.jsx";
import EditLoyalty from "@/views/adminPanel/loyalties/customers/EditLoyalty.jsx";
import EditNotificationSetting from "@/views/adminPanel/notificationSettings/EditNotificationSetting.jsx";

import ConfirmCode from "@/views/auth/confirmCode";

import * as types from "@/store/toggleSettingPanel-types.js";

export function Selector(modalType, data, toggleSettingPanel)
{
    switch (modalType)
    {
    case types.closePanel:
        return {};

    case types.columnsCustomization:
        return {
            title: "Настройка столбцов",
            body: <TableCustomization data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.applicationsFilter:
        return {
            title: "Фильтрация",
            body: <ApplicationsFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.operationsFilter:
        return {
            title: "Фильтрация",
            body: <OperationsFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.depositOpsFilter:
        return {
            title: "Фильтрация",
            body: <DepositOpsFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.loanOpsFilter:
        return {
            title: "Фильтрация",
            body: <LoanOpsFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.customersFilter:
        return {
            title: "Фильтрация",
            body: <CustomersFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.employeesFilter:
        return {
            title: "Фильтрация",
            body: <EmployeesFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.loyaltiesFilter:
        return {
            title: "Фильтрация",
            body: <LoyaltiesFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.depositsFilter:
        return {
            title: "Фильтрация",
            body: <DepositsFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.loansFilter:
        return {
            title: "Фильтрация",
            body: <LoansFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.officesFilter:
        return {
            title: "Фильтрация",
            body: <OfficesFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.journalFilter:
        return {
            title: "Фильтрация",
            body: <JournalFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.roomsFilter:
        return {
            title: "Фильтрация",
            body: <RoomsFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.newsFilter:
        return {
            title: "Фильтрация",
            body: <NewsFilter data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.depositApplicationDetails:
        return {
            title: "Заявка на сбережение",
            body: <DepositApplicationDetails data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.loanApplicationDetails:
        return {
            title: "Заявка на займ",
            body: <LoanApplicationDetails data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.operationDetails:
        return {
            title: "Операция",
            body: <OperationDetails data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.depositOpDetails:
        return {
            title: "Операция",
            body: <DepositOpDetails data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.loanOpDetails:
        return {
            title: "Операция",
            body: <LoanOpDetails data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.notifyCustomer:
        return {
            title: "Уведомление пользователя",
            body: <NotifyCustomer data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.createDivvy:
        return {
            title: "Операция с паем",
            body: <CreateDivvy data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.editLoyalty:
        return {
            title: "Новая операция с баллами",
            body: <EditLoyalty data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.editNotificationSetting:
        return {
            title: "Настройка оповещения",
            body: <EditNotificationSetting data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    case types.confirmCode:
        return {
            title: "Введите код подтверждения",
            body: <ConfirmCode data={data} toggleSettingPanel={toggleSettingPanel} />
        };

    default:
        console.warn(`Панель с ключом '${modalType}' не определена. Определите ключ в '@/store/toggleSettingPanel-types.js' и привяжите к нему компонент в '@/view/layout/RightPanel/Selector.js'.`);

        return {};
    }
}
