import { useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Input,
    Button,
    Spin,
    Checkbox,
    Form,
    Card,
    Modal,
    Tooltip
} from "antd";
import { SaveOutlined } from "@ant-design/icons";

import { useForm } from "@/components/Form";
import { setBreadcrumbs, toggleSettingPanel, confirmEmailUpdate, confirmPhone } from "@/store/actions";
import "./Update.less";
import useProfileForm from "./hooks/useProfileForm";
import MaskedInput from "@/components/Form/MaskedInput";

const { confirm } = Modal;

const Update = props =>
{
    const {
        setBreadcrumbs
    } = props;
    const { onCatchRequestError } = useForm();
    const {
        loading,
        handleInputChange,
        onEmailChanged,
        onPhoneChanged,
        onPasswordChanged,
        onNotifyEnableChanged,
        onNotifyChanged,
        email,
        phone,
        notifyAbout,
        passwordForm
    } = useProfileForm(props, onCatchRequestError);

    useEffect(
        () =>
        {
            setBreadcrumbs("/profile", "Профиль", 0);
            setBreadcrumbs("/profile/update", "Редактирование", 1);
        },
        [setBreadcrumbs]
    );

    const handleSaveEmail = useCallback(() =>
    {
        confirm({
            title: "Вы уверены, что хотите изменить эл. почту?",
            onOk: onEmailChanged, // TODO: Need to use onCancel to reset prev value
            okText: "Изменить эл. почту",
            okButtonProps: {
                icon: <SaveOutlined />
            }
        });
    }, [onEmailChanged]);

    const handleSavePhone = useCallback(() =>
    {
        confirm({
            title: "Вы уверены, что хотите изменить телефон?",
            onOk: onPhoneChanged,
            okText: "Изменить телефон",
            okButtonProps: {
                icon: <SaveOutlined />
            }
        });
    }, [onPhoneChanged]);

    return (
        <div className="app-container">
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={16}>
                        <h2 className="bold">
                            Редактирование профиля
                        </h2>
                    </Col>
                </Row>
                <Row className="row-simple" gutter={[20, 20]}>
                    <Col span={24}>
                        <Row gutter={20}>
                            <Col span={8}>
                                <Row gutter={[20, 20]}>
                                    <Col span={24}>
                                        <Card title="Общая информация">
                                            <Row className="row-simple">
                                                <Col className="bold" span={12}>Электронная почта</Col>
                                                <Col span={12}>
                                                    <Input.Group compact>
                                                        <Input
                                                            className="grouped-input"
                                                            name="email"
                                                            onChange={handleInputChange}
                                                            value={email}
                                                        />
                                                        <Tooltip title="Сохранить E-mail">
                                                            <Button icon={<SaveOutlined />} onClick={handleSaveEmail} />
                                                        </Tooltip>
                                                    </Input.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="bold" span={12}>Телефон</Col>
                                                <Col span={12}>
                                                    <Input.Group compact>
                                                        <MaskedInput
                                                            className="grouped-input"
                                                            name="phone"
                                                            onChange={handleInputChange}
                                                            value={phone}
                                                        />
                                                        <Tooltip title="Сохранить телефон">
                                                            <Button icon={<SaveOutlined />} onClick={handleSavePhone} />
                                                        </Tooltip>
                                                    </Input.Group>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        <Card title="Уведомления">
                                            <Row className="row">
                                                <Col className="bold" span={12}>Оповещения включены</Col>
                                                <Col span={12}>
                                                    <Checkbox
                                                        checked={notifyAbout?.IsEnabled}
                                                        name="IsEnabled"
                                                        onChange={onNotifyEnableChanged}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="row">
                                                <Col className="bold" span={12}>Уведомлять об операциях</Col>
                                                <Col span={12}>
                                                    <Checkbox
                                                        checked={notifyAbout?.Operations}
                                                        disabled={!notifyAbout?.IsEnabled}
                                                        name="Operations"
                                                        onChange={onNotifyChanged}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="row">
                                                <Col className="bold" span={12}>Напоминать о платежах</Col>
                                                <Col span={12}>
                                                    <Checkbox
                                                        checked={notifyAbout?.Payments}
                                                        disabled={!notifyAbout?.IsEnabled}
                                                        name="Payments"
                                                        onChange={onNotifyChanged}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="row">
                                                <Col className="bold" span={12}>Оповещать об акциях</Col>
                                                <Col span={12}>
                                                    <Checkbox
                                                        checked={notifyAbout?.Promotions}
                                                        disabled={!notifyAbout?.IsEnabled}
                                                        name="Promotions"
                                                        onChange={onNotifyChanged}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="row">
                                                <Col className="bold" span={12}>Оповещать о новостях</Col>
                                                <Col span={12}>
                                                    <Checkbox
                                                        checked={notifyAbout?.News}
                                                        disabled={!notifyAbout?.IsEnabled}
                                                        name="News"
                                                        onChange={onNotifyChanged}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="row">
                                                <Col className="bold" span={12}>Оповещать о сообщениях</Col>
                                                <Col span={12}>
                                                    <Checkbox
                                                        checked={notifyAbout?.Messages}
                                                        disabled={!notifyAbout?.IsEnabled}
                                                        name="Messages"
                                                        onChange={onNotifyChanged}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        <Card title="Пароль">
                                            <Row className="row" gutter={[20, 20]}>
                                                <Col span={24}>
                                                    <Form
                                                        onFinish={onPasswordChanged}
                                                        ref={passwordForm}
                                                    >
                                                        <Form.Item name="CurrentPassword">
                                                            <Input.Password
                                                                onChange={handleInputChange}
                                                                placeholder="Введите старый пароль"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="NewPassword">
                                                            <Input.Password
                                                                onChange={handleInputChange}
                                                                placeholder="Введите новый пароль"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            dependencies={["NewPassword"]}
                                                            name="ConfirmPassword"
                                                            rules={[
                                                                ({ getFieldValue }) => ({
                                                                    validator(_, value)
                                                                    {
                                                                        if (!value || getFieldValue("NewPassword") === value)
                                                                        {
                                                                            return Promise.resolve();
                                                                        }

                                                                        return Promise.reject(new Error("Пароли должны совпадать"));
                                                                    }
                                                                })
                                                            ]}
                                                        >
                                                            <Input.Password
                                                                onChange={handleInputChange}
                                                                placeholder="Подтвердите новый пароль"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item key="password">
                                                            {
                                                                passwordForm.current && (
                                                                    <Button
                                                                        className="confirmButton"
                                                                        disabled={
                                                                            !passwordForm.current.isFieldsTouched(true)
                                                                                || passwordForm.current
                                                                                    .getFieldsError()
                                                                                    .filter(({ errors }) => errors.length)
                                                                                    .length > 0
                                                                                || !passwordForm.current.getFieldValue("CurrentPassword")
                                                                                || !passwordForm.current.getFieldValue("NewPassword")
                                                                                || passwordForm.current.getFieldValue("NewPassword") !== passwordForm.current.getFieldValue("ConfirmPassword")
                                                                        }
                                                                        htmlType="submit"
                                                                        icon={<SaveOutlined />}
                                                                        type="primary"
                                                                    >
                                                                        Изменить пароль
                                                                    </Button>
                                                                )
                                                            }
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={6}/>
                </Row>
            </Spin>
        </div>
    );
};
export default connect(
    state => state.user,
    { setBreadcrumbs, toggleSettingPanel, confirmEmailUpdate, confirmPhone }
)(withRouter(Update));

Update.displayName = "ProfileUpdate";
