import { withRouter } from "react-router-dom";
import { Button, Radio, DatePicker, Col, Row, Spin, Input } from "antd";
import { CheckCircleOutlined, PlusOutlined, MinusCircleOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";
import { dateFormat } from "@/config/constants";
import Filter from "@/components/Filter";

const { RangePicker } = DatePicker;

class DepositsFilter extends Filter
{
    constructor(props)
    {
        super(props);

        this.state = {
            ...this.state,

            "loading": false,
            "preloadedThreshold": 0,
            "singles": ["product:Capitalization"],
            "ranges": ["openedAt", "duration", "amount", "product:InterestRate"],
            "multiples": ["product:Name", "office:Name", "office:Region", "office:City"],

            "duration": [],
            "amount": [],
            "product:InterestRate": []
        };
    }

    componentDidMount()
    {
        this.setFromUrl();
    }

    render()
    {
        const {
            loading,
            openedAt, duration, amount
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row className="row">
                    <Col span={22}>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Капитализация</Col>
                            <Col span={16}>
                                <Radio.Group
                                    className="full-width d-flex"
                                    onChange={event => this.onSelectorChange("product:Capitalization", event.target.value)}
                                    value={this.state["product:Capitalization"]}
                                >
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={true}
                                    >
                                        Да
                                    </Radio.Button>
                                    <Radio.Button
                                        className="flex-1 centred"
                                        value={false}
                                    >
                                        Нет
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Дата открытия</Col>
                            <Col span={16}>
                                <RangePicker
                                    className="full-width"
                                    format={dateFormat}
                                    onChange={(_, dateString) => this.onSelectorChange("openedAt", dateString)}
                                    value={
                                        openedAt?.length && openedAt.every(date => date)
                                            ? [moment(openedAt[0], dateFormat), moment(openedAt[1], dateFormat)]
                                            : undefined
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Срок</Col>
                            <Col span={16}>
                                <Row>
                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("duration", event.target.value, 0)}
                                            placeholder="от"
                                            value={duration[0]}
                                        />
                                    </Col>

                                    <Col span={2}/>

                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("duration", event.target.value, 1)}
                                            placeholder="до"
                                            value={duration[1]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Сумма</Col>
                            <Col span={16}>
                                <Row>
                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("amount", event.target.value, 0)}
                                            placeholder="от"
                                            value={amount[0]}
                                        />
                                    </Col>

                                    <Col span={2}/>

                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("amount", event.target.value, 1)}
                                            placeholder="до"
                                            value={amount[1]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Ставка</Col>
                            <Col span={16}>
                                <Row>
                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("product:InterestRate", event.target.value, 0)}
                                            placeholder="от"
                                            value={this.state["product:InterestRate"][0]}
                                        />
                                    </Col>

                                    <Col span={2}/>

                                    <Col span={11}>
                                        <Input
                                            onChange={event => this.onFieldChange("product:InterestRate", event.target.value, 1)}
                                            placeholder="до"
                                            value={this.state["product:InterestRate"][1]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Продукты</Col>
                            <Col span={16}>
                                {
                                    this.state["product:Name"]
                                        ? this.state["product:Name"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("product:Name", event.target.value, index)}
                                                    placeholder="Введите продукт"
                                                    value={this.state["product:Name"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("product:Name", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("product:Name")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Офисы</Col>
                            <Col span={16}>
                                {
                                    this.state["office:Name"]
                                        ? this.state["office:Name"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("office:Name", event.target.value, index)}
                                                    placeholder="Введите офис"
                                                    value={this.state["office:Name"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("office:Name", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("office:Name")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Регионы</Col>
                            <Col span={16}>
                                {
                                    this.state["office:Region"]
                                        ? this.state["office:Region"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("office:Region", event.target.value, index)}
                                                    placeholder="Введите регион"
                                                    value={this.state["office:Region"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("office:Region", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("office:Region")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-simple">
                            <Col className="bold" span={8}>Города</Col>
                            <Col span={16}>
                                {
                                    this.state["office:City"]
                                        ? this.state["office:City"].map((_, index) =>
                                            <div
                                                className="multiple-element-container"
                                                key={index}
                                            >
                                                <Input
                                                    key={index}
                                                    onChange={event => this.onFieldChange("office:City", event.target.value, index)}
                                                    placeholder="Введите город"
                                                    value={this.state["office:City"][index]}
                                                />
                                                <MinusCircleOutlined
                                                    className="postfix"
                                                    key={`remover${index}`}
                                                    onClick={() => this.onRemoveField("office:City", index)}
                                                />
                                            </div>)
                                        : <></>
                                }
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => this.appendField("office:City")}
                                    size="small"
                                    type="dashed"
                                >
                                    Выбрать ещё
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={2} />
                </Row>

                <Row className="row" gutter={20}>
                    <Col>
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={this.updateUrl}
                            type="primary"
                        >
                            Применить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="postfix"
                            danger
                            icon={<ClearOutlined />}
                            onClick={() => this.clearAll(this.updateUrl)}
                            type="primary"
                        >
                            Очистить
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default withRouter(DepositsFilter);
