import request from "@/utils/request.js";
import { fromForm, upload, getItem, editItem, deleteItem } from "./crud.js";

const url = "/template";

export default url;

export function getDetails(id)
{
    return getItem(url, id);
}

export function getTemplates(entityUrl)
{
    return request({
        url: `${entityUrl.slice(1)}${url}s`,
        method: "get"
    });
}

export function uploadTemplate(file, data)
{
    return upload(url, fromForm(file, data));
}

export function update(data)
{
    return editItem(url, data);
}

export function remove(guid)
{
    return deleteItem(url, guid);
}

export function generateTemplateFromDraft(entityUrl, guid, draft)
{
    return request({
        url: `${entityUrl.slice(1)}/generate/${guid}`,
        method: "post",
        data: draft
    });
}

export function generateTemplate(entityUrl, entityId, guid)
{
    return request({
        url: `${entityUrl.slice(1)}/${entityId}/generate/${guid}`,
        method: "get"
    });
}

export function downloadEmptyTemplate(guid)
{
    return request({
        url: `${url.slice(1)}/download/${guid}`,
        method: "get"
    });
}
