import roles, { Administrators } from "../roles";

const Default = {
    Data: Administrators,
    SetupColumn: Administrators
};

const Modified = {
    ...Default,
    Add: Administrators,
    Export: [roles.SuperAdministrator]
};

export const Products = {
    All: Default,
    Deposit: Modified,
    Loan: Modified
};
