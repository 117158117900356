import { addItem } from "./crud.js";

const url = "/message";

export default url;

export function create(data)
{
    return addItem(url, data);
}
