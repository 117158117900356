import { isMobile } from "react-device-detect";

/*
 * Отображаемое название проекта.
 */
export const projectName = "DIDCRM";

/*
 * Используемая версия API.
 */
export const apiVersion = "v1.0";

export const dateFormat = "DD.MM.YYYY";

export const transportableDateFormat = "YYYY-MM-DD";

export const timeFormat = "HH:mm";

export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

/*
 * Задержка в мс перед вызовом поиска после изменения поля ввода.
 */
export const searchPing = 1500;

/*
 * Первая доступная для выбора в календарях дата.
 */
export const firstDate = "1920-01-01";

/*
 * Типы шаблонов (кому предназначаются).
 */
export const templateTypes = {
    unknown: 0,
    forCustomers: 1,
    forEmployees: 2,
    forDeposits: 4,
    forLoans: 8,
    forDepositOperations: 16,
    forLoanOperations: 32,
    forCooperationOperations: 64
};

export const documentType = {
    unknown: 0,
    other: 1,
    passport: 2,
    contract: 3,
    order: 4
};

export const filterType = {
    keysIntersection: 1,
    contains: 2,
    equals: 3,
    fromTo: 4
};

export const userType = {
    /*
     * Супер администратор.
     */
    superAdministrator: 1,

    /*
     * Администратор.
     */
    administrator: 2,

    /*
     * Региональный менеджер.
     */
    regionalManager: 3,

    /*
     * Руководитель офиса.
     */
    officeManager: 4,

    /*
     * Менеджер по сбережения.
     */
    depositManager: 5,

    /*
     * Менеджер по займам.
     */
    loanManager: 6,

    /*
     * Скоринг менеджер.
     */
    scoringManager: 7,

    /*
     * Клиент.
     */
    customer: 8
};

/*
 * Классы денежных операций.
 */
export const opClass = {
    /*
     * Приход.
     */
    positive: 1,

    /*
     * Расход.
     */
    negative: 2
};

export const opType = {
    interestWithdrawal: 40,
    partialWithdrawal: 50,
    closeDeposit: 60,
    closeLoan: 130
};

/*
 * Типы операций программы Благодарю.
 */
export const loyaltyOpType = {
    /*
     * Начислить баллы.
     */
    accruePoints: 140,

    /*
     * Списать баллы.
     */
    writeOffPoints: 150,

    /*
     * Баллы на сбережение.
     */
    pointsToDeposit: 160,

    /*
     * Баллы на займ.
     */
    pointsToLoan: 170,

    /*
     * Подарить баллы.
     */
    donatePoints: 180,

    /*
     * Выдать наличными.
     */
    cashOut: 190
};

export const productType = {
    /*
     * Сберегательная программа.
     */
    deposit: 1,

    /*
     * Займ.
     */
    loan: 2
};

/*
 * Типы обращений HelpDesk.
 */
export const appealCategory = {
    /*
     * Заявка.
     */
    application: 1,

    /*
     * Обращение.
     */
    appeal: 2
};

export const orderServer = {
    ascend: "Asc",
    descend: "Desc"
};

export const orderFront = {
    Asc: "ascend",
    Desc: "descend"
};

export const applicationSource = {
    /*
     * Мобильное приложение.
     */
    mobile: 1,

    /*
     * Веб.
     */
    web: 2,

    other: 3
};

export const nameParts = {
    /*
     * Имя.
     */
    firstName: 1,

    /*
     * Фамилия.
     */
    lastName: 2,

    /*
     * Отчество.
     */
    middleName: 4
};

/*
 * Типы залога.
 */
export const pledgeType = {
    /*
     * Авто.
     */
    car: 0,

    /*
     * Недвижимость.
     */
    realty: 1,

    /*
     * Другое.
     */
    other: 2
};

/*
 * Каналы оповещения.
 */
export const notifyBy = {
    none: 0,

    /*
     * e-mail.
     */
    mail: 2,

    /*
     * Push.
     */
    push: 4,

    /*
     * SMS.
     */
    sms: 8
};

/*
 * Модели начисления процентов.
 */
export const interestCalculationModel = {
    /*
     * Ежемесячно.
     */
    monthly: 1,

    /*
     * В дату открытия.
     */
    onOpening: 2
};

/*
 * Гендерная принадлежность.
 */
export const genderType = {
    unknown: 0,
    female: 1,
    male: 2
};

export const maskChar = "_";

export const phoneMask = "+7 999 999-99-99";

export const dateMask = "99.99.9999";

export const addressDropdownWidth = isMobile || 400;
