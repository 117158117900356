/* eslint-disable */

import Loadable from "react-loadable";
import Loading from "@/components/Loading";
import { UserPanel } from "@/config/permissions";

/*
 * Заглушка
 */

//const NotImplemented = Loadable({
//    loader: () => import("@/views/notImplemented"),
//    loading: Loading,
//});

/*
 * Клиент
 */

const Customers = Loadable({
    loader: () => import("@/views/customers"),
    loading: Loading,
});
const FreeCustomers = Loadable({
    loader: () => import("@/views/customers/FreeCustomers.jsx"),
    loading: Loading,
});
const CustomersArchive = Loadable({
    loader: () => import("@/views/customers/CustomersArchive.jsx"),
    loading: Loading,
});
const Customer = Loadable({
    loader: () => import("@/views/customers/customer"),
    loading: Loading,
});
const CustomerCreate = Loadable({
    loader: () => import("@/views/customers/customer/Create.jsx"),
    loading: Loading,
});
const CustomerUpdate = Loadable({
    loader: () => import("@/views/customers/customer/Create.jsx"),
    loading: Loading,
});

/*
 * Заявки
 */

const ApplicationsNew = Loadable({
    loader: () => import("@/views/applications/ApplicationsNew.jsx"),
    loading: Loading,
});
const ApplicationsArchive = Loadable({
    loader: () => import("@/views/applications/ApplicationsArchive.jsx"),
    loading: Loading,
});
const Applications = Loadable({
    loader: () => import("@/views/applications"),
    loading: Loading,
});

const DepositApplicationCreate = Loadable({
    loader: () => import("@/views/applications/deposit/Create.jsx"),
    loading: Loading,
});

const DepositApplications = Loadable({
    loader: () => import("@/views/applications/deposit"),
    loading: Loading,
});

const LoanApplicationCreate = Loadable({
    loader: () => import("@/views/applications/loan/Create.jsx"),
    loading: Loading,
});

const LoanApplications = Loadable({
    loader: () => import("@/views/applications/loan"),
    loading: Loading,
});

/*
 * Займы
 */

const LoanDetails = Loadable({
    loader: () => import("@/views/customers/customer/loan/Details.jsx"),
    loading: Loading,
});
const LoanArchive = Loadable({
    loader: () => import("@/views/loans/LoanArchive.jsx"),
    loading: Loading,
});
const LoansOverdue = Loadable({
    loader: () => import("@/views/loans/LoansOverdue.jsx"),
    loading: Loading,
});
const Loans = Loadable({
    loader: () => import("@/views/loans"),
    loading: Loading,
});

/*
 * Сбережения
 */

const DepositDetails = Loadable({
    loader: () => import("@/views/customers/customer/deposit/Details.jsx"),
    loading: Loading,
});
const DepositArchive = Loadable({
    loader: () => import("@/views/deposits/DepositArchive.jsx"),
    loading: Loading,
});
const Deposits = Loadable({
    loader: () => import("@/views/deposits"),
    loading: Loading,
});

/*
 * Совершённые операции
 */

const DepositOperations = Loadable({
    loader: () => import("@/views/deposits/operations"),
    loading: Loading,
});
const LoanOperations = Loadable({
    loader: () => import("@/views/loans/operations"),
    loading: Loading,
});
const Operations = Loadable({
    loader: () => import("@/views/operations"),
    loading: Loading,
});

/*
 * Предстоящие операции
 */

const DepositUpcoming = Loadable({
    loader: () => import("@/views/deposits/operations/upcoming"),
    loading: Loading,
});
const LoanUpcoming = Loadable({
    loader: () => import("@/views/loans/operations/upcoming"),
    loading: Loading,
});
const Upcoming = Loadable({
    loader: () => import("@/views/operations/upcoming"),
    loading: Loading,
});

/*
 * ...
 */

//const Alert = Loadable({
//    loader: () => import("@/views/notImplemented"),
//    loading: Loading,
//});

/*
 * Обращения
 */

const HelpDeskCreate = Loadable({
    loader: () => import("@/views/helpDesk/room/Create.jsx"),
    loading: Loading,
});
const HelpDesk = Loadable({
    loader: () => import("@/views/helpDesk"),
    loading: Loading,
});
const HelpDeskDetails = Loadable({
    loader: () => import("@/views/helpDesk/room/Details.jsx"),
    loading: Loading,
});

/*
 * История уведомлений клиента
 */
const Notifications = Loadable({
    loader: () => import("@/views/notifications"),
    loading: Loading,
});

/*
 * Карта маршрутов
 */
const routeMap = [
    { path: "/customer/free",               component: FreeCustomers,               roles: UserPanel.Customers.Free.Data },
    { path: "/customer/archive",            component: CustomersArchive,            roles: UserPanel.Customers.Archive.Data },
    { path: "/customer/create",             component: CustomerCreate,              roles: UserPanel.Customers.Customer.Profile.Assign },
    { path: "/customer/:id/update",         component: CustomerUpdate,              roles: UserPanel.Customers.Customer.Profile.Edit },
    { path: "/customer/:id",                component: Customer,                    roles: UserPanel.Customers.Customer.Profile.Profile },
    { path: "/customer",                    component: Customers,                   roles: UserPanel.Customers.All.Data },

    { path: "/applications/new",            component: ApplicationsNew,             roles: UserPanel.Applications.New.Data },
    { path: "/applications/archive",        component: ApplicationsArchive,         roles: UserPanel.Applications.Archive.Data },
    { path: "/applications/deposit/create", component: DepositApplicationCreate,    roles: UserPanel.Applications.Deposit.Data },
    { path: "/applications/deposit",        component: DepositApplications,         roles: UserPanel.Applications.Deposit.Data },
    { path: "/applications/loan/create",    component: LoanApplicationCreate,       roles: UserPanel.Applications.Loan.Data },
    { path: "/applications/loan",           component: LoanApplications,            roles: UserPanel.Applications.Loan.Data },
    { path: "/applications",                component: Applications,                roles: UserPanel.Applications.All.Data },

    { path: "/loan/:id/details",            component: LoanDetails,                 roles: UserPanel.Loans.Section },
    { path: "/loan/archive",                component: LoanArchive,                 roles: UserPanel.Loans.Archive.Data },
    { path: "/loan/overdue",                component: LoansOverdue,                roles: UserPanel.Loans.Overdue.Data },
    { path: "/loan",                        component: Loans,                       roles: UserPanel.Loans.All.Data },

    { path: "/deposit/:id/details",         component: DepositDetails,              roles: UserPanel.Deposits.Deposit.Information.Data },
    { path: "/deposit/archive",             component: DepositArchive,              roles: UserPanel.Deposits.Archive.Data },
    { path: "/deposit",                     component: Deposits,                    roles: UserPanel.Deposits.All.Data },

    { path: "/operations/deposit",          component: DepositOperations,           roles: UserPanel.CommitedOperations.Data },
    { path: "/operations/loan",             component: LoanOperations,              roles: UserPanel.CommitedOperations.Data },
    { path: "/operations",                  component: Operations,                  roles: UserPanel.CommitedOperations.Data },
    
    { path: "/upcoming/deposit",            component: DepositUpcoming,             roles: UserPanel.OperationsUpcoming.Data },
    { path: "/upcoming/loan",               component: LoanUpcoming,                roles: UserPanel.OperationsUpcoming.Data },
    { path: "/upcoming",                    component: Upcoming,                    roles: UserPanel.OperationsUpcoming.Data },

    //{ path: "/alert",                       component: Alert,                       roles: [admin] },

    { path: "/helpdesk/create",             component: HelpDeskCreate,              roles: UserPanel.HelpDesk.All.Data },
    { path: "/helpdesk/:id/room",           component: HelpDeskDetails,             roles: UserPanel.HelpDesk.All.Open },
    { path: "/helpdesk",                    component: HelpDesk,                    roles: UserPanel.HelpDesk.All.Data  },

    { path: "/notifications",               component: Notifications,               roles: UserPanel.Customers.Customer.Notifications.Data }
];

export default routeMap;
