import { useMemo } from "react";
import { Select } from "antd";

export function useOffice(officesCatalog, officeId)
{
    const officeRegion = useMemo(
        () => officesCatalog
            ?.find(x => x.Id === officeId)
            ?.Region ?? "Сперва выберите офис",
        [officesCatalog, officeId]
    );
    const officeCity = useMemo(
        () => officesCatalog
            ?.find(x => x.Id === officeId)
            ?.City ?? "Сперва выберите офис",
        [officesCatalog, officeId]
    );
    const officeSelectOptions = useMemo(
        () => Object.keys(officesCatalog)
            .map(key => (
                <Select.Option
                    key={key}
                    value={officesCatalog[key].Id}
                >
                    <span>
                        {officesCatalog[key].Name}
                        <br/>{officesCatalog[key].OrgName}
                    </span>
                </Select.Option>
            )),
        [officesCatalog]
    );

    return {
        officeRegion,
        officeCity,
        officeSelectOptions
    };
}
