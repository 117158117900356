import { UserPanel } from "@/config/permissions";

const {
    Profile,
    Operations,
    Appeals,
    Applications,
    Loans,
    Deposits,
    Notifications,
    Journal,
    Loyalty
} = UserPanel.Customers.Customer;

const clientMenuConfig = [
    {
        title: "Профиль",
        path: "/profile",
        roles: Profile.Data
    },
    {
        title: "Операции",
        path: "/operations",
        roles: Operations.Data
    },
    {
        title: "Заявки",
        path: "/applications",
        roles: Applications.Data
    },
    {
        title: "Сбережения",
        path: "/deposit",
        roles: Deposits.Data
    },
    {
        title: "Займы",
        path: "/loan",
        roles: Loans.Data
    },
    {
        title: "Благодарю",
        path: "/loyalty",
        roles: Loyalty.Data
    },
    {
        title: "Обращения",
        path: "/helpdesk",
        roles: Appeals.Data
    },
    {
        title: "Уведомления",
        path: "/notifications",
        roles: Notifications.Data
    },
    {
        title: "Журнал",
        path: "/journal",
        roles: Journal.Data
    }
];

export default clientMenuConfig;
