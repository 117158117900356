import { Administrators } from "../roles";

export const News = {
    All: {
        Data: Administrators,
        Search: Administrators,
        Filter: Administrators,
        SetupColumn: Administrators,
        Add: Administrators
    },
    News: {
        Data: Administrators
    }
};
