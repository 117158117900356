import React from "react";
import { Form as AntdForm } from "antd";

import "@/styles/form.less";

import { Label } from "../Label";

export const Form = React.forwardRef(({ children, ...props }, ref) =>
{
    return (
        <AntdForm
            labelCol={{
                span: 8
            }}
            ref={ref}
            wrapperCol={{
                span: 16
            }}
            {...props}
        >
            {children}
        </AntdForm>
    );
});

Form.displayName = "FormComponent";

export function FormItem({ label = "", isBold = true, errors = [], fieldName = "", rules = [], setError = Function, children, ...props })
{
    return (
        <AntdForm.Item
            colon={false}
            label={
                label &&
                    <Label isBold={isBold} text={label} />
            }
            labelAlign="left"
            {...props}
            rules={[
                () => ({
                    validator(_, value)
                    {
                        if (errors.length)
                        {
                            return Promise.reject(errors);
                        }

                        if (value)
                        {
                            if (errors.length)
                            {
                                return Promise.reject(errors);
                            }

                            return Promise.resolve();
                        }

                        return Promise.resolve();
                    }
                }),
                ...rules
            ]}
            validateTrigger="onChange"
        >
            {children}
        </AntdForm.Item>
    );
}

export function ErrorList({ errors = [] })
{
    if (!errors.length)
    {
        return null;
    }

    return (
        <>
            {
                errors.map((error, idx) => (
                    <div
                        className="ant-form-item-explain-error"
                        key={idx}
                    >
                        {error}
                    </div>
                ))
            }
        </>
    );
}
