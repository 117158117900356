import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { store } from "@/store";

const parseJwt = token =>
{
    try
    {
        return JSON.parse(atob(token.split(".")[1]));
    }
  catch (e)
    {
        return null;
    }
};

const AuthVerify = props =>
{
    props.history.listen(() =>
    {
        const { logout } = props;

        const { user: { token } } = store.getState();

        if (token)
        {
            const decodedJwt = parseJwt(token);

            if (decodedJwt?.exp * 1000 <= Date.now())
            {
                logout();
            }
        }
    });

    return <></>;
}

export default withRouter(AuthVerify);
