import { upload as uploadCrud } from "./crud.js";
import customerUrl from "./customer.js";
import { download } from "./document.js";

const url = "/loyalty";

export default url;

export const perCustomersUrl = `${url}/customers`;

export function forCustomerUrl(customer)
{
    return `${customerUrl}/${customer}/loyalties`;
}

export function create(data, file)
{
    const formData = new FormData();

    if (file)
    {
        formData.append("file", file);
    }

    Object.keys(data).forEach(key =>
        data[key] && formData.append(key, data[key]));

    return uploadCrud(url, formData);
}

export function downloadDocument(guid)
{
    return download(url, guid);
}
