import isArray from "lodash/isArray";
import qs from "qs";

import request from "@/utils/request.js";

export function fromForm(files, data, filesName = "files")
{
    const formData = new FormData();

    files.forEach(file =>
        formData.append(filesName, file));

    Object.entries(data).forEach(([key, value]) =>
    {
        if (value)
        {
            if (isArray(value))
            {
                value.forEach(item => formData.append(key, item));

                return;
            }

            formData.append(key, value);
        }
    });

    return formData;
}

export function getList(url, data)
{
    return request({
        url,
        method: "get",
        data
    });
}

export function getListByParams(url, params)
{
    return request({
        url,
        method: "get",
        params,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
    });
}

export function getItem(url, id)
{
    return request({
        url: `${url}/${id}`,
        method: "get"
    });
}

export function deleteItem(url, id)
{
    return request({
        url: `${url}/${id}`,
        method: "delete"
    });
}

export function editItem(url, data)
{
    return request({
        url,
        method: "patch",
        data
    });
}

export function editByString(url, data)
{
    return request({
        url,
        method: "patch",
        headers: { "Content-Type": "application/json-patch+json" },
        data: JSON.stringify(data)
    });
}

export function addItem(url, data)
{
    return request({
        url,
        method: "post",
        data
    });
}

function requestViaForm(url, data, method)
{
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) =>
    {
        if (value != null)
        {
            if (isArray(value))
            {
                value.forEach(item => formData.append(key, JSON.stringify(item)));
            }
            else
            {
                formData.append(key, value);
            }
        }
    });

    return request({
        url,
        method,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData
    });
}

export function postViaForm(url, data)
{
    return requestViaForm(url, data, "post");
}

export function patchViaForm(url, data)
{
    return requestViaForm(url, data, "patch");
}

export function download(url)
{
    return request({
        url,
        method: "get",
        responseType: "blob" // Important
    });
}

export function downloadByParams(url, params)
{
    return request({
        url,
        method: "get",
        responseType: "blob",
        params,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
    });
}

export function getParametrizedUrl(url, params)
{
    return `${url}?${qs.stringify(params, { arrayFormat: "repeat" })}`;
}

export function upload(url, data)
{
    const result = addItem(url, data);

    request.defaults.headers.post["Content-Type"] = "multipart/form-data";

    return result;
}

export function confirmation(url, data, params)
{
    return request({
        url,
        method: "post",
        headers: { "Content-Type": "application/json-patch+json" },
        data: JSON.stringify(data),
        params,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
    });
}
