import { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Button,
    Select,
    Col,
    Row,
    Spin,
    Space,
    Upload
} from "antd";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";

import { loyaltyOpType } from "@/config/constants";
import { toggleSettingPanel } from "@/store/actions";
import PresetedInput from "@/components/PresetedInput";
import useLoyaltyForm from "./hooks/useLoyaltyForm";
import { useForm, Form, FormItem } from "@/components/Form";
import PhoneInput from "@/components/Form/PhoneInput";

const EditLoyalty = props =>
{
    const { data: { CustomerId } } = props;

    const { errors, onCatchRequestError } = useForm();

    const {
        handleInputChange,
        handleFieldChange,
        presets,
        deposits,
        loans,
        loading,
        operationType,
        targetId,
        phone,
        customerName,
        amount,
        findByPhone,
        documentUpload,
        onCreate,
        opTypeChanged,
        handlePhoneUpdate
    } = useLoyaltyForm(CustomerId, props, onCatchRequestError);

    const depositOptions = useMemo(
        () => Object.keys(deposits)
            .map(key =>
                <Select.Option
                    key={key}
                    value={deposits[key].Id}
                >
                    Договор №{deposits[key].Id}
                </Select.Option>
            ),
        [deposits]
    );
    const loanOptions = useMemo(
        () => Object.keys(loans)
            .map(key =>
                <Select.Option
                    key={key}
                    value={loans[key].Id}
                >
                    Договор №{loans[key].Id}
                </Select.Option>
            ),
        [loans]
    );

    const targetEl = useMemo(
        () =>
        {
            switch (operationType)
            {
            case loyaltyOpType.pointsToDeposit:
                return (
                    <FormItem
                        errors={errors?.TargetId}
                        label="Сбережение"
                        name="targetId"
                        onChange={value => handleFieldChange("targetId", value)}
                    >
                        <Select
                            className="full-width"
                            onChange={value => handleFieldChange("targetId", value)}
                            placeholder="Выберите сбережение"
                            value={targetId}
                        >
                            {depositOptions}
                        </Select>
                    </FormItem>
                );
            case loyaltyOpType.pointsToLoan:
                return (
                    <FormItem
                        errors={errors?.TargetId}
                        label="Займ"
                        name="targetId"
                        onChange={value => handleFieldChange("targetId", value)}
                    >
                        <Select
                            className="full-width"
                            placeholder="Выберите займ"
                            value={targetId}
                        >
                            {loanOptions}
                        </Select>
                    </FormItem>
                );
            case loyaltyOpType.donatePoints:
                return (
                    <>
                        <PhoneInput byPhone={handlePhoneUpdate}/>
                        <FormItem
                            label="Получатель"
                            name="customerName"
                        >
                            <span className="placeholder">
                                {customerName}
                            </span>
                        </FormItem>
                    </>
                );
            case loyaltyOpType.cashOut:
                return (
                    <Row
                        align="middle"
                        className="row-simple"
                    >
                        <Col className="bold" span={8}>Кассовый ордер</Col>
                        <Col span={16}>
                            <Upload
                                {...documentUpload}
                            >
                                <Button
                                    icon={<PlusOutlined />}
                                    size="small"
                                    type="dashed"
                                >
                                    Прикрепить документ
                                </Button>
                            </Upload>
                        </Col>
                    </Row>
                );
            default:
                break;
            }
        },
        [operationType, findByPhone, phone, customerName]
    );

    return (
        <div>
            <Spin spinning={loading}>
                <Form
                    name="loyalty"
                    initialValues={{customerName}}
                >
                    <Col span={24}>
                        <FormItem
                            errors={errors?.OperationType}
                            label="Тип операции"
                            name="operationType"
                        >
                            <Select
                                className="full-width"
                                onChange={opTypeChanged}
                                placeholder="Выберите тип"
                                value={operationType}
                            >
                                <Select.Option value={loyaltyOpType.accruePoints}>Начислить баллы</Select.Option>
                                <Select.Option value={loyaltyOpType.writeOffPoints}>Списать баллы</Select.Option>
                                <Select.Option value={loyaltyOpType.pointsToDeposit}>Баллы на сбережение</Select.Option>
                                <Select.Option value={loyaltyOpType.pointsToLoan}>Баллы на займ</Select.Option>
                                <Select.Option value={loyaltyOpType.donatePoints}>Подарить баллы</Select.Option>
                                <Select.Option value={loyaltyOpType.cashOut}>Выдать наличными</Select.Option>
                            </Select>
                        </FormItem>
                        {targetEl}
                        {
                            operationType &&
                                <FormItem
                                    errors={errors?.Amount}
                                    label="Сумма"
                                    name="amount"
                                >
                                    <PresetedInput
                                        amount={amount}
                                        onInputChange={handleInputChange}
                                        presets={presets}
                                    />
                                </FormItem>
                        }
                    </Col>
                </Form>
                {
                    operationType && (
                        <Row style={{ marginTop: 20 }}>
                            <Col align="end" span={4}>
                                <Space>
                                    <Button
                                        className="green-button"
                                        icon={<CheckOutlined />}
                                        onClick={onCreate}
                                        type="primary"
                                    >
                                        Создать
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    )
                }
            </Spin>
        </div>
    );
};

export default connect(
    null,
    { toggleSettingPanel }
)(withRouter(EditLoyalty));
