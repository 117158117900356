import { connect } from "react-redux";
import { Drawer } from "antd";

import { toggleSettingPanel } from "@/store/actions";
import { Selector } from "./Selector.jsx";
import "./index.less";

const RightPanel = props =>
{
    const { settingPanelVisible, toggleSettingPanel, data, modalType } = props;

    const { body, title: drawerTitle } = Selector(modalType, data, toggleSettingPanel);

    const title = drawerTitle ? { title: <h2 className="bold drawer-title">{drawerTitle}</h2> } : undefined;

    return (
        <div className="rightSettings">
            <Drawer
                onClose={() => toggleSettingPanel()}
                placement="right"
                visible={Boolean(modalType) && settingPanelVisible}
                width={538}
                {...title}
            >
                {body}
            </Drawer>
        </div>
    );
};

export default connect(
    state => ({ ...state.app }),
    { toggleSettingPanel }
)(RightPanel);
