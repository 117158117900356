import { Employees } from "./employees";
import { Offices } from "./offices";
import { News } from "./news";
import { Products } from "./products";
import { Templates } from "./templates";
import { Loyalty } from "./loyalty";
import { Journal } from "./journal";

import { SuperAdminAndAdminAndOfficeManager } from "../roles";

export const Administration = {
    Section: SuperAdminAndAdminAndOfficeManager,
    Employees,
    Offices,
    News,
    Products,
    Templates,
    Loyalty,
    Journal
};
