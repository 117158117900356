import ReactDOM from "react-dom";
import App from "./App.jsx";
import "antd/dist/antd.less";
import "@/styles/index.less";

/*
 * Установка русской локализации.
 */
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");

ReactDOM.render(<App />, document.getElementById("root"));
