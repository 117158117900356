import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { message, Spin, Row, Col, Checkbox, Button, Divider, Card, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { getProfile } from "@/api/user.js";
import onCatchRequestError from "@/components/OnCatchRequestError";
import { setBreadcrumbs } from "@/store/actions";
import { applyPhoneMask } from "@/utils/converters.js";

import "@/styles/menu.less";

class Profile extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            loading: true
        };

        this.props.setBreadcrumbs(this.props.location.pathname, "Профиль", 0);

        getProfile()
            .then(response =>
            {
                const { meta, data, errors } = response.data;

                if (meta.status === 200)
                {
                    this.setState(data);
                }
                else
                {
                    message.error(errors[0]);
                }
            })
            .catch(onCatchRequestError)
            .finally(() => this.setState({ loading: false }));
    }

    render()
    {
        const {
            loading,
            Email, Phone, Type, NotifyAbout
        } = this.state;

        return (
            <div className="app-container">
                <Spin spinning={loading}>
                    <Row span={20}>
                        <Col span={16}>
                            <h2 className="bold">Профиль</h2>
                        </Col>
                        <Col align="end" span={8}>
                            <Space>
                                <Link to={{ pathname: "/profile/update" }}>
                                    <Button icon={<EditOutlined />} type="primary">
                                        Редактировать
                                    </Button>
                                </Link>
                            </Space>
                        </Col>
                    </Row>
                    <Row className="row-simple" gutter={[20, 20]}>
                        <Col span={8}>
                            <Card title="Общая информация">
                                <Row className="row-simple">
                                    <Col className="bold" span={12}>Тип пользователя</Col>
                                    <Col span={12}>{Type}</Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={12}>Электронная почта</Col>
                                    <Col span={12}>{Email}</Col>
                                </Row>
                                <Row className="row">
                                    <Col className="bold" span={12}>Телефон</Col>
                                    <Col span={12}>{Phone && applyPhoneMask(Phone)}</Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col span={14}/>
                    </Row>
                    <Row className="row-simple" gutter={[20, 20]}>
                        <Col span={8}>
                            <Card title="Сроки оповещений">
                                <Row className="row">
                                    <Col className="bold" span={12}>Оповещения включены</Col>
                                    <Col span={12}>
                                        <Checkbox checked={NotifyAbout?.IsEnabled} disabled={true} />
                                    </Col>
                                </Row>

                                <Divider/>

                                <Row className="row-simple">
                                    <Col className="bold" span={12}>Уведомлять об операциях</Col>
                                    <Col span={12}>
                                        <Checkbox checked={NotifyAbout?.Operations} disabled={true} />
                                    </Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={12}>Напоминать о платежах</Col>
                                    <Col span={12}>
                                        <Checkbox checked={NotifyAbout?.Payments} disabled={true} />
                                    </Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={12}>Оповещать об акциях</Col>
                                    <Col span={12}>
                                        <Checkbox checked={NotifyAbout?.Promotions} disabled={true} />
                                    </Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={12}>Оповещать о новостях</Col>
                                    <Col span={12}>
                                        <Checkbox checked={NotifyAbout?.News} disabled={true} />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col className="bold" span={12}>Оповещать о сообщениях</Col>
                                    <Col span={12}>
                                        <Checkbox checked={NotifyAbout?.Messages} disabled={true} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={14} />
                    </Row>
                </Spin>
            </div>
        );
    }
}

export default connect(
    null,
    { setBreadcrumbs }
)(Profile);

Profile.displayName = "ProfileView";
