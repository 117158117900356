import { lowerFirst, transform } from "lodash";
import React, { useCallback, useEffect } from "react";
import { message } from "antd";

import useInputEntity from "@/hooks/useInputEntity";
import { confirmCode } from "@/store/toggleSettingPanel-types.js";
import { getProfile, update, updateEmail, updatePhone, updatePassword } from "@/api/user.js";

const KEYS = new Set([
    "email", "phone", "notifyAbout"
]);

const useProfileForm = (props, onCatchRequestError) =>
{
    const {
        state,
        handleLoadingSet,
        handleLoadingReset,
        handleDataLoaded,
        handleInputChange,
        handleFieldBulkChange
    } = useInputEntity({
        loading: false,
        preloadedThreshold: 1,
        email: props.email ?? "",
        phone: props.phone ?? "",
        initialValues: props,
        passwordForm: React.createRef()
    });

    const transformFromServer = source => transform(source, (result, value, key) =>
    {
        const resultKey = lowerFirst(key);
        if (!KEYS.has(resultKey))
        {
            return;
        }

        result[resultKey] = value;
    });

    useEffect(
        () =>
        {
            getProfile()
                .then(response =>
                {
                    const { meta, data, errors } = response.data;

                    if (meta.status === 200)
                    {
                        const prevState = transformFromServer(data);
                        handleFieldBulkChange(prevState);
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleDataLoaded);
        },
        [handleDataLoaded, handleFieldBulkChange]
    );

    const onEmailChanged = useCallback(
        () =>
        {
            handleLoadingSet();

            const { email } = state;

            updateEmail(email)
                .then(response =>
                {
                    const { status, statusText } = response;

                    if (status === 200)
                    {
                        const { toggleSettingPanel, confirmEmailUpdate } = props;

                        message.success("Код подтверждения отправлен на e-mail");

                        toggleSettingPanel(
                            {
                                confirmationRequest: confirmEmailUpdate,
                                data: email,
                                onSuccess: () =>
                                {
                                    message.success("Обновлено");
                                    toggleSettingPanel();
                                    window.location.reload();
                                }
                            },
                            confirmCode);
                    }
                    else
                    {
                        message.error(statusText);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, handleLoadingReset, state, props]
    );

    const onPhoneChanged = useCallback(
        () =>
        {
            handleLoadingSet();

            const { phone } = state;

            updatePhone(phone)
                .then(response =>
                {
                    const { status, statusText } = response;

                    if (status === 200)
                    {
                        const { toggleSettingPanel, confirmPhone } = props;

                        message.success("Код подтверждения отправлен по СМС");

                        toggleSettingPanel(
                            {
                                confirmationRequest: confirmPhone,
                                data: phone,
                                onSuccess: () =>
                                {
                                    message.success("Обновлено");
                                    toggleSettingPanel();
                                    window.location.reload();
                                }
                            },
                            confirmCode);
                    }
                    else
                    {
                        message.error(statusText);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, handleLoadingReset, state, props]
    );

    const onPasswordChanged = useCallback(
        values =>
        {
            handleLoadingSet();

            const { CurrentPassword, NewPassword } = values;

            updatePassword({ CurrentPassword, NewPassword })
                .then(response =>
                {
                    const { status, statusText } = response;

                    if (status === 200)
                    {
                        message.success("Обновлено");

                        window.location.reload();
                    }
                    else
                    {
                        message.error(statusText);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, handleLoadingReset]
    );

    const onNotifyChanged = useCallback(
        event =>
        {
            handleLoadingSet();

            state.notifyAbout[event.target.name] = event.target.checked;

            update({ notifyAbout: state.notifyAbout })
                .then(response =>
                {
                    const { status, statusText } = response;

                    if (status === 200)
                    {
                        message.success("Обновлено");
                    }
                    else
                    {
                        message.error(statusText);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, handleLoadingReset, state]
    );

    const onNotifyEnableChanged = useCallback(
        event =>
        {
            const { target: { checked } } = event;
            if (!checked)
            {
                const { notifyAbout } = state;
                Object.keys(notifyAbout).forEach(key => notifyAbout[key] = false);
            }

            onNotifyChanged(event);
        }
    );

    return {
        loading: state.loading,
        handleInputChange,
        handleLoadingSet,
        handleLoadingReset,

        passwordForm: state.passwordForm,
        email: state.email,
        phone: state.phone,
        notifyAbout: state.notifyAbout,
        initialValues: state.initialValues,

        onEmailChanged,
        onPhoneChanged,
        onPasswordChanged,
        onNotifyEnableChanged,
        onNotifyChanged
    };
};

export default useProfileForm;
