import React from "react";
import PropTypes from "prop-types";
import { AutoComplete as AntdAutoComplete, Input } from "antd";
import cx from "classnames";

import "./index.less";

export function AutoComplete({
    className,
    options,
    placeholder,
    children,
    isAddress,
    ...rest
})
{
    return (
        <AntdAutoComplete
            className={cx("full-width", className)}
            filterOption={(inputValue, option) => isAddress || option.value?.toUpperCase?.()?.indexOf(inputValue.toUpperCase()) !== -1}
            options={Object.values(options).map(value => ({ value }))}
            {...rest}
        >
            {
                children ||
                <Input placeholder={placeholder} />
            }
        </AntdAutoComplete>
    );
}

AutoComplete.propTypes = {
    className: PropTypes.string.isRequired,
    options: PropTypes.object,
    placeholder: PropTypes.string,
    isAddress: PropTypes.bool
};

AutoComplete.propTypes = {
    className: "",
    isAddress: false
};

AutoComplete.displayName = "AutoComplete";
