import { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { message, Spin, Row, Col, Button, Divider, Typography } from "antd";
import { PhoneOutlined, CloseOutlined, CheckOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { getDetails, assign, status } from "@/api/depositApplication.js";
import { toggleSettingPanel } from "@/store/actions";
import FormattedDateTime from "@/components/FormattedDateTime";
import onCatchRequestError from "@/components/OnCatchRequestError";
import SmartLink from "@/components/SmartLink";
import { UserPanel } from "@/config/permissions";
import { applyPhoneMask } from "@/utils/converters.js";

const { Title } = Typography;

class Details extends Component
{
    constructor(props)
    {
        super(props);

        const { data: { id }, role } = this.props;

        this.state = {
            loading: true,
            id,
            role
        };

        getDetails(id)
            .then(response =>
            {
                const { meta, data, errors } = response.data;

                if (meta.status === 200)
                {
                    this.setState(data);
                }
                else
                {
                    message.error(errors[0]);
                }
            })
            .catch(onCatchRequestError)
            .finally(() => this.setState({ loading: false }));

        this.onAssign = this.onAssign.bind(this);
        this.onVerdict = this.onVerdict.bind(this);
    }

    onAssign()
    {
        const { id } = this.state;

        assign(id)
            .then(response =>
            {
                const { status, statusText } = response;

                if (status === 200)
                {
                    message.success("Заявка прикреплена");

                    window.location.reload();
                }
                else
                {
                    message.error(statusText);
                }
            })
            .catch(onCatchRequestError);
    }

    onVerdict(value)
    {
        const { id } = this.state;

        status({ Value: value, Id: id })
            .then(response =>
            {
                const { status, statusText } = response;

                if (status === 200)
                {
                    message.success("Решение по заявке принято");

                    window.location.reload();
                }
                else
                {
                    message.error(statusText);
                }
            })
            .catch(onCatchRequestError);
    }

    render()
    {
        const {
            loading, role, id, DepositId, Raw,
            Status, CreatedAt, Source, Phone, LastName, FirstName, MiddleName, CustomerId, Product, Duration, Amount,
            UTMSource, UTMMedium, UTMCampaign, UTMContent, UTMTerm, FormBlockNumber, UniqueApplicationNumber
        } = this.state;

        return <>
            {
                UserPanel.Applications.New.Data.includes(role) &&
                    (
                        Status === "Новая"
                            ? <Row className="row-simple">
                                <Button
                                    className="prefix"
                                    onClick={this.onAssign}
                                    type="primary"
                                >
                                    Взять заявку
                                </Button>
                            </Row>
                            : Status === "В обработке"
                                ? <Row className="row-simple" gutter={32}>
                                    <Col span={6}>
                                        <Button
                                            className="green-button"
                                            icon={<CheckOutlined />}
                                            onClick={e => this.onVerdict(true)}
                                            type="primary"
                                        >
                                            Одобрить
                                        </Button>
                                    </Col>
                                    <Col span={6}>
                                        <Button
                                            className="red-button"
                                            icon={<CloseOutlined />}
                                            onClick={e => this.onVerdict(false)}
                                            type="primary"
                                        >
                                            Отклонить
                                        </Button>
                                    </Col>
                                    <Col span={12}/>
                                </Row>
                                : Status === "Одобрена" &&
                                (DepositId && DepositId !== 0
                                    ? <Link
                                            className="hovered-link"
                                            to={{ pathname: `/customer/${CustomerId}/deposit/${DepositId}/details` }}
                                    >
                                        Перейти на сбережение
                                    </Link>
                                    : CustomerId && CustomerId !== 0 &&
                                        <Row className="row-simple">
                                            <Link
                                                to={{
                                                    pathname: `/customer/${CustomerId}/deposit/create`,
                                                    params: {
                                                        ProductId: Product.Key,
                                                        Duration: Raw.Duration,
                                                        Amount: Raw.Amount,
                                                        ApplicationId: id
                                                    }
                                                }}
                                            >
                                                <Button
                                                    className="prefix"
                                                    type="primary"
                                                >
                                                    Открыть сбережение
                                                </Button>
                                            </Link>
                                        </Row>
                                )
                    )
            }
            <Spin spinning={loading}>
                <Col span={20}>
                    <Row className="row-simple" gutter={[0, 10]}>
                        <Col className="bold" span={8}>Заявка №</Col>
                        <Col span={16}>{id}</Col>
                    </Row>
                    {
                        !!CreatedAt && (
                            <Row className="row-simple" gutter={[0, 10]}>
                                <Col className="bold" span={8}>Дата и время</Col>
                                <Col span={16}>
                                    <FormattedDateTime dateTime={CreatedAt} />
                                </Col>
                            </Row>
                        )
                    }
                    <Row className="row-simple" gutter={[0, 10]}>
                        <Col className="bold" span={8}>Фамилия</Col>
                        <Col span={16}>{LastName}</Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Телефон</Col>
                        <Col span={16}>
                            <a
                                className="hovered-link"
                                href={`callto:${Phone && applyPhoneMask(Phone)}`}
                            >
                                <PhoneOutlined className="prefix" />
                                <span>{Phone && applyPhoneMask(Phone)}</span>
                            </a>
                        </Col>
                    </Row>
                    <Row className="row-simple" gutter={[0, 10]}>
                        <Col className="bold" span={8}>Фамилия</Col>
                        <Col span={16}>{LastName}</Col>
                    </Row>
                    <Row className="row-simple" gutter={[0, 10]}>
                        <Col className="bold" span={8}>Имя</Col>
                        <Col span={16}>{FirstName}</Col>
                    </Row>
                    <Row className="row-simple" gutter={[0, 10]}>
                        <Col className="bold" span={8}>Отчество</Col>
                        <Col span={16}>{MiddleName}</Col>
                    </Row>
                    <Row className="row">
                        {
                            CustomerId && CustomerId !== 0
                                ? <Link
                                        className="hovered-link"
                                        to={{ pathname: `/customer/${CustomerId}/profile` }}
                                >
                                    <UserOutlined className="prefix" />
                                    <span>Перейти на карточку клиента</span>
                                </Link>
                                : <Link
                                        className="hovered-link"
                                        to={{
                                        pathname: "/customer/create",
                                        params: { Phone,
                                            LastName,
                                            FirstName,
                                            MiddleName,
                                            ApplicationId: id,
                                            ApplicationEntityType: 1 }
                                    }}
                                >
                                    <span>Создать клиента</span>
                                </Link>
                        }
                    </Row>

                    <Divider />

                    <Row className="row-simple">
                        <Col className="bold" span={8}>Откуда</Col>
                        <Col span={16}>{Source}</Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Продукт</Col>
                        <Col span={16}>
                            {
                                Product &&
                                    <SmartLink
                                        className="hovered-link"
                                        to={{ pathname: `/depositproduct/${Product.Key}` }}
                                    >
                                        <span>{Product.Value}</span>
                                    </SmartLink>
                            }
                        </Col>
                    </Row>
                    <Row className="row-simple">
                        <Col className="bold" span={8}>Срок</Col>
                        <Col span={16}>{Duration}</Col>
                    </Row>
                    <Row className="row">
                        <Col className="bold" span={8}>Сумма</Col>
                        <Col span={16}>{Amount}</Col>
                    </Row>
                    {
                        UserPanel.Applications.New.Data.includes(role) &&
                            <>
                                <Divider/>
                                <Row className="row-simple">
                                    <Col className="bold" span={8}>UTM Source</Col>
                                    <Col span={16}>{UTMSource}</Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={8}>UTM Medium</Col>
                                    <Col span={16}>{UTMMedium}</Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={8}>UTM Campaign</Col>
                                    <Col span={16}>{UTMCampaign}</Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={8}>UTM Content</Col>
                                    <Col span={16}>{UTMContent}</Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={8}>UTM Term</Col>
                                    <Col span={16}>{UTMTerm}</Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={8}>Номер блока с формой</Col>
                                    <Col span={16}>{FormBlockNumber}</Col>
                                </Row>
                                <Row className="row-simple">
                                    <Col className="bold" span={8}>Уникальный номер заявки</Col>
                                    <Col span={16}>{UniqueApplicationNumber}</Col>
                                </Row>
                            </>
                    }
                </Col>
                <Col span={4} />
            </Spin>
        </>;
    }
}

export default connect(
    state => state.user,
    { toggleSettingPanel }
)(withRouter(Details));
