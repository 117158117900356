import React from "react";
import { connect } from "react-redux";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "./index.less";

const BreadCrumb = ({ data }) =>
{
    return (
        <div className="breadcrumb-container">
            <Breadcrumb>
                {
                    data.map(item =>
                        item &&
                            <Breadcrumb.Item key={item.title}>
                                <Link to={item.path}>
                                    {item.title}
                                </Link>
                            </Breadcrumb.Item>
                    )
                }
            </Breadcrumb>
        </div>
    );
};

export default connect(
    state => state.breadcrumbs
)(BreadCrumb);
