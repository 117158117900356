import request from "@/utils/request.js";
import { fromForm, upload as uploadCrud } from "./crud.js";

const url = "docs";

export function upload(entityUrl, ownerId, file, data)
{
    return uploadCrud(
        `${entityUrl.slice(1)}${url}/${ownerId}`,
        fromForm(file, data));
}

export function download(entityUrl, guid)
{
    return request({
        url: `${entityUrl.slice(1)}${url}/download/${guid}`,
        method: "get"
    });
}

export function downloadArchive(entityUrl, owner)
{
    return request({
        url: `${entityUrl.slice(1)}${url}/${owner}/download`,
        method: "get"
    });
}
