import Cookies from "js-cookie";

const key = "User";

export function getUser()
{
    const user = Cookies.get(key);

    return user
        ? JSON.parse(user)
        : {};
}

export function setUser(user)
{
    return Cookies.set(key, user);
}

export function removeUser()
{
    return Cookies.remove(key);
}
