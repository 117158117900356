import { upperFirst, transform } from "lodash";
import { useEffect, useCallback } from "react";
import { message } from "antd";

import { getAmount, create, uploadDocument, getTemplates, downloadTemplateFromDraft } from "@/api/cooperation.js";
import { getList } from "@/api/crud.js";
import { downloadStaticPromise } from "@/api/downloadStatic.js";
import { catalogUrl } from "@/api/office.js";
import { documentType, opClass } from "@/config/constants";
import cashierUrl from "@/api/cashier.js";
import useInputEntity from "@/hooks/useInputEntity";

const KEYS = new Set([
    "executionMode", "operationClass",
    "customerId", "officeId", "cashierId"
]);

const useDivvyForm = (id, isInCooperation, props, onCatchRequestError) =>
{
    const {
        state,
        handleLoadingSet,
        handleLoadingReset,
        handleDataLoaded,
        handleFieldChange,
        handleFieldInnerRemove,
        handleInputChange
    } = useInputEntity({
        preloadedThreshold: 4,
        customerId: id,
        templates: [],
        operationTypes: [],
        officesCatalog: [],
        cashiersCatalog: [],
        operationClass: isInCooperation ? "Расход" : "Приход",
        executionMode: "Через кассу", // FIXME: Need to remove after implement the catalog
        officeId: "",
        cashierId: "",
        divvyAmount: ""
    });

    const transformToServer = source => transform(source, (result, value, key) =>
    {
        if (!KEYS.has(key))
        {
            return;
        }

        const resultKey = upperFirst(key);
        let resultValue;

        switch (key)
        {
        case "operationClass":
            resultValue = isInCooperation ? opClass.negative : opClass.positive;
            break;

        default:
            resultValue = value;
            break;
        }

        result[resultKey] = resultValue;
    });

    useEffect(
        () =>
        {
            getList(cashierUrl)
                .then(response =>
                {
                    const { meta, data: { rows }, errors } = response.data;

                    if (meta.status === 200)
                    {
                        handleFieldChange("cashiersCatalog", rows);
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleDataLoaded);
        },
        [handleDataLoaded, handleFieldChange]
    );

    useEffect(
        () =>
        {
            getTemplates()
                .then(response =>
                {
                    const { meta, data, errors } = response.data;

                    switch (meta.status)
                    {
                    case 200:
                        handleFieldChange("templates", data);
                        break;

                    // если шаблонов нет - ничего не делаем
                    case 404:
                        break;

                    default:
                        message.error(errors[0]);
                        break;
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleDataLoaded);
        },
        [handleDataLoaded, handleFieldChange]
    );

    useEffect(
        () =>
        {
            getList(catalogUrl)
                .then(response =>
                {
                    const { meta: { status }, data, errors } = response.data;

                    if (status === 200)
                    {
                        handleFieldChange("officesCatalog", data);
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleDataLoaded);
        },
        [handleDataLoaded, handleFieldChange]
    );

    useEffect(
        () =>
        {
            getAmount()
                .then(response =>
                {
                    const { meta: { status }, data, errors } = response.data;

                    if (status === 200)
                    {
                        handleFieldChange("divvyAmount", data.Value);
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleDataLoaded);
        },
        [handleDataLoaded, handleFieldChange]
    );

    const handleCreate = useCallback(
        () =>
        {
            handleLoadingSet();

            const operation = transformToServer(state);

            create(operation)
                .then(response =>
                {
                    const { meta, data, errors } = response.data;

                    if (meta.status === 200)
                    {
                        // прикрепляем кассовый ордер
                        uploadDocument(data.Id, [state.document], { DocumentType: documentType.order })
                            .then(response =>
                            {
                                const { meta: { status }, errors } = response.data;

                                if (status === 200)
                                {
                                    message.success("Операция добавлена");

                                    const { toggleSettingPanel } = props;
                                    toggleSettingPanel();

                                    window.location.reload();
                                }
                                else
                                {
                                    message.error(errors[0]);
                                }
                            })
                            .catch(onCatchRequestError)
                            .finally(handleLoadingReset);
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, handleLoadingReset, handleFieldChange, state]
    );

    const documentUpload = {
        onRemove: () => handleFieldInnerRemove("document", undefined),
        beforeUpload: file =>
        {
            handleFieldChange("document", file);

            return false;
        },
        maxCount: 1,
        document
    };

    const handleGetDoc = useCallback(
        key =>
        {
            const operation = transformToServer(state);

            handleLoadingSet();

            downloadStaticPromise(downloadTemplateFromDraft(key, operation));

            handleLoadingReset();
        },
        [handleLoadingSet, handleLoadingReset, state]
    );

    return {
        loading: state.loading,
        handleLoadingSet,
        handleLoadingReset,
        handleInputChange,
        handleFieldChange,

        customerId: state.customerId,
        operationTypes: state.operationTypes,
        officesCatalog: state.officesCatalog,
        cashiersCatalog: state.cashiersCatalog,
        operationClass: state.operationClass,
        executionMode: state.executionMode,
        officeId: state.officeId,
        cashierId: state.cashierId,
        divvyAmount: state.divvyAmount,
        templates: state.templates,

        handleGetDoc,
        handleCreate,
        documentUpload
    };
};

export default useDivvyForm;
