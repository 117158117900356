import {
    TeamOutlined,
    InboxOutlined,
    DollarOutlined,
    PropertySafetyOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    // BulbOutlined,
    MessageOutlined
} from "@ant-design/icons";

import { UserPanel } from "@/config/permissions";

const {
    Customers,
    Applications,
    Loans,
    Deposits,
    HelpDesk,
    CommitedOperations,
    OperationsUpcoming
} = UserPanel;

const menuList = [
    {
        title: "Клиенты",
        path: "/customer",
        icon: <TeamOutlined />,
        roles: Customers.All.Data,
        children: [
            {
                title: "Все клиенты",
                path: "/customer",
                roles: Customers.All.Data
            },
            {
                title: "Свободные клиенты",
                path: "/customer/free",
                roles: Customers.Free.Data
            },
            {
                title: "Архив клиентов",
                path: "/customer/archive",
                roles: Customers.Archive.Data
            },
            {
                title: "Создание",
                path: "/customer/create",
                roles: Customers.All.Add,
                notInMenu: true
            }
        ]
    },
    {
        title: "Заявки",
        path: "/applications",
        icon: <InboxOutlined />,
        roles: Applications.All.Data,
        children: [
            {
                title: "Все заявки",
                path: "/applications",
                roles: Applications.All.Data
            },
            {
                title: "Заявки по сбережениям",
                path: "/applications/deposit",
                roles: Applications.Deposit.Data
            },
            {
                title: "Заявки по займам",
                path: "/applications/loan",
                roles: Applications.Loan.Data
            },
            {
                title: "Новые заявки",
                path: "/applications/new",
                roles: Applications.New.Data
            },
            {
                title: "Архив заявок",
                path: "/applications/archive",
                roles: Applications.Archive.Data
            }
        ]
    },
    {
        title: "Займы",
        path: "/loan",
        icon: <DollarOutlined />,
        roles: Loans.Section,
        children: [
            {
                title: "Все займы",
                path: "/loan",
                roles: Loans.Section
            },
            {
                title: "Займы с просрочкой",
                path: "/loan/overdue",
                roles: Loans.Overdue.Data
            },
            {
                title: "Архив займов",
                path: "/loan/archive",
                roles: Loans.Archive.Data
            },
            {
                title: "Предстоящие платежи",
                path: "/upcoming/loan",
                roles: Loans.UpcomingPayments.Data
            },
            {
                title: "Совершённые платежи",
                path: "/operations/loan",
                roles: Loans.CommittedOperations.Data
            }
        ]
    },
    {
        title: "Сбережения",
        path: "/deposit",
        icon: <PropertySafetyOutlined />,
        roles: Deposits.Section,
        children: [
            {
                title: "Все сбережения",
                path: "/deposit",
                roles: Deposits.Section
            },
            {
                title: "Архив сбережений",
                path: "/deposit/archive",
                roles: Deposits.Archive.Data
            },
            {
                title: "Предстоящие выплаты",
                path: "/upcoming/deposit",
                roles: Deposits.UpcomingPayments.Data
            },
            {
                title: "Совершённые выплаты",
                path: "/operations/deposit",
                roles: Deposits.CommittedOperations.Data
            }
        ]
    },
    {
        title: "Совершенные операции",
        path: "/operations",
        icon: <CheckCircleOutlined />,
        roles: CommitedOperations.Data
    },
    {
        title: "Предстоящие операции",
        path: "/upcoming",
        icon: <ClockCircleOutlined />,
        roles: OperationsUpcoming.Data
    },
    // {
    //    title: "Оповещение клиентов",
    //    path: "/alert",
    //    icon: <BulbOutlined />,
    //    roles: [admin]
    // },
    {
        title: "Запросы и обращения",
        path: "/helpdesk",
        icon: <MessageOutlined />,
        roles: HelpDesk.All.Data
    }
];

export default menuList;
