import { upload as uploadCrud, getItem, deleteItem } from "./crud.js";
import { download, upload } from "./document.js";
import { getTemplates as getOperationsTemplates, generateTemplateFromDraft, generateTemplate } from "./template.js";

const url = "/depositoperations";
const docsUrl = "/depositops";

export default url;

export const upcomingUrl = "/upcoming";

const opTypes = `${url}/operation-types`;

export function create(data, cashOrder)
{
    const formData = new FormData();

    formData.append("cashOrder", cashOrder);

    Object.keys(data).forEach(key =>
        formData.append(key, data[key]));

    return uploadCrud(url, formData);
}

export function getDetails(id)
{
    return getItem(url, id);
}

export function remove(id)
{
    return deleteItem(url, id);
}

export function uploadDocument(ownerId, file, data)
{
    return upload(docsUrl, ownerId, file, data);
}

export function downloadDocument(guid)
{
    return download(docsUrl, guid);
}

export function getTemplates()
{
    return getOperationsTemplates(url);
}

export function downloadTemplateFromDraft(guid, draft)
{
    return generateTemplateFromDraft(url, guid, draft);
}

export function downloadTemplate(id, guid)
{
    return generateTemplate(url, id, guid);
}
