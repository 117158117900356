import roles, { Administrators } from "../roles";

export const Offices = {
    All: {
        Data: Administrators,
        Search: Administrators,
        Filter: Administrators,
        SetupColumn: Administrators,
        Export: [roles.SuperAdministrator],
        Add: Administrators
    },
    Office: {
        Data: Administrators,
        Edit: Administrators,
        Close: Administrators
    }
};

