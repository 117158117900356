import request from "@/utils/request.js";
import { getItem, getListByParams, fromForm, upload as uploadCrud, editItem, deleteItem } from "./crud.js";
import depositsUrl from "./deposit.js";
import loansUrl from "./loan.js";
import { download, upload, downloadArchive } from "./document.js";
import { getTemplates as getCustomersTemplates, generateTemplate } from "./template.js";

const url = "/customer";

export const freeUrl = `${url}/free`;

export const archiveUrl = `${url}/archive`;

export const notificationsUrl = `${url}/notifications`;

export default url;

export function create(data, passportScans)
{
    let formData = fromForm(passportScans, data, "passportScans");

    return uploadCrud(url, formData);
}

export function update(data)
{
    return editItem(url, data);
}

export function remove(id)
{
    return deleteItem(url, id);
}

export function assign(id)
{
    const formData = new FormData();

    formData.append("id", id);

    return editItem(`${url}/assign`, formData);
}

export function getName(id)
{
    return request({
        url: `${url}/${id}/name`,
        method: "get"
    });
}

export function getProfile(id)
{
    return getItem(url, id);
}

export function byPhone(phone)
{
    return request({
        url: `${url}/by-phone`,
        method: "get",
        params: { phone }
    });
}

export function getTemplates()
{
    return getCustomersTemplates(url);
}

export function getReferrers()
{
    return request({
        url: `${url}/referrers`,
        method: "get"
    });
}

export function getOpenDeposits(id)
{
    return getListByParams(
        `${url}/${id}${depositsUrl}s`,
        { onlyOpen: true }
    );
}

export function getOpenLoans(id)
{
    return getListByParams(
        `${url}/${id}${loansUrl}s`,
        { onlyOpen: true }
    );
}

export function uploadDocument(ownerId, file, data)
{
    return upload(url, ownerId, file, data);
}

export function downloadDocument(guid)
{
    return download(url, guid);
}

export function downloadAllInArchive(guid)
{
    return downloadArchive(url, guid);
}

export function downloadTemplate(id, guid)
{
    return generateTemplate(url, id, guid);
}

