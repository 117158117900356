import { Input } from "antd";
import ReactInputMask from "react-input-mask";
import PropTypes from "prop-types";

import { phoneMask, maskChar } from "@/config/constants";

function MaskedInput({ isDisabled, ...props })
{
    return !isDisabled
        ? <ReactInputMask
            mask={phoneMask}
            maskChar={maskChar}
            {...props}
        >
            { inputProps => <Input {...inputProps} /> }
        </ReactInputMask>
        : <Input {...props} disabled={isDisabled} />;
}

MaskedInput.propTypes = {
    isDisabled: PropTypes.bool
};

export default MaskedInput;
