import { transform } from "lodash";
import { useEffect, useCallback } from "react";
import { message } from "antd";

import { getOpenDeposits, getOpenLoans, byPhone } from "@/api/customer.js";
import { create } from "@/api/loyalty.js";
import { getPresets } from "@/api/loyaltySetting.js";
import useInputEntity from "@/hooks/useInputEntity";

const KEYS = new Set([
    "operationType", "amount", "customerId", "targetId"
]);

const transformToServer = source => transform(source, (result, value, key) =>
{
    if (!KEYS.has(key))
    {
        return;
    }

    result[key] = value;
});

const useLoyaltyForm = (CustomerId, props, onCatchRequestError) =>
{
    const {
        state,
        handleLoadingSet,
        handleLoadingReset,
        handleDataLoaded,
        handleFieldChange,
        handleInputChange
    } = useInputEntity({
        loading: false,
        customerId: CustomerId,
        preloadedThreshold: 3,
        presets: [],
        deposits: [],
        loans: [],
        operationType: "",
        targetId: "",
        phone: "",
        customerName: "",
        amount: "",
        document: null
    });

    const handlePhoneUpdate = useCallback(
        phone =>
        {
            handleLoadingSet();

            byPhone(phone)
                .then(response =>
                {
                    const { meta: { status }, data, errors } = response.data;

                    switch (status)
                    {
                    case 200:
                        handleFieldChange("targetId", data.Id);
                        handleFieldChange("customerName", data.ShortName);
                        break;

                    case 404:
                        message.error("Клиент с указанным телефоном не найден");
                        break;

                    default:
                        message.error(errors[0]);
                        break;
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, onCatchRequestError, handleLoadingReset, handleFieldChange]
    );

    useEffect(
        () =>
        {
            getPresets()
                .then(response =>
                {
                    const { meta: { status }, data, errors } = response.data;

                    if (status === 200)
                    {
                        handleFieldChange("presets", data);
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleDataLoaded);
        },
        [handleDataLoaded, handleFieldChange]
    );
    useEffect(
        () =>
        {
            getOpenDeposits(CustomerId)
                .then(response =>
                {
                    const { meta: { status }, data, errors } = response.data;

                    switch (status)
                    {
                    case 200:
                        handleFieldChange("deposits", data.rows);
                        break;

                    case 404:
                        handleFieldChange("deposits", []);
                        break;

                    default:
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleDataLoaded);
        },
        [handleDataLoaded, handleFieldChange]
    );

    useEffect(
        () =>
        {
            getOpenLoans(CustomerId)
                .then(response =>
                {
                    const { meta: { status }, data, errors } = response.data;

                    switch (status)
                    {
                    case 200:
                        handleFieldChange("loans", data.rows);
                        break;

                    case 404:
                        handleFieldChange("loans", []);
                        break;

                    default:
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleDataLoaded);
        },
        [handleDataLoaded, handleFieldChange]
    );

    const onCreate = useCallback(
        () =>
        {
            handleLoadingSet();

            const loyalty = transformToServer(state);

            create(loyalty, state.document)
                .then(response =>
                {
                    const { meta: { status }, errors } = response.data;

                    if (status === 200)
                    {
                        message.success("Операция программы лояльности выполнения");

                        const { toggleSettingPanel } = props;
                        toggleSettingPanel();

                        window.location.reload();
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, handleLoadingReset, state]
    );

    const opTypeChanged = useCallback(
        value =>
        {
            handleFieldChange("targetId", undefined);

            handleFieldChange("operationType", value);
        },
        [handleFieldChange]
    );

    const documentUpload = {
        onRemove: () => handleFieldChange("document", undefined),
        beforeUpload: file =>
        {
            handleFieldChange("document", file);

            return false;
        },
        maxCount: 1,
        document
    };

    return {
        loading: state.loading,
        handleLoadingSet,
        handleLoadingReset,
        handleInputChange,
        handleFieldChange,

        phone: state.phone,
        presets: state.presets,
        deposits: state.deposits,
        loans: state.loans,
        operationType: state.operationType,
        targetId: state.targetId,
        customerName: state.customerName,
        amount: state.amount,
        customerId: state.customerId,

        handlePhoneUpdate,
        documentUpload,
        onCreate,
        opTypeChanged
    };
};

export default useLoyaltyForm;
