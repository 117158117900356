import { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "@/views/layout";
import Login from "@/views/auth/login";
// import Registration from "@/views/auth/registration";
import ConfirmedRecovery from "@/views/auth/recovery/Confirmed.jsx";
import Recovery from "@/views/auth/recovery";
import Error404 from "@/views/error/404";
import AuthVerify from "@/utils/authVerify";
import { logout } from "@/store/actions";

class Router extends Component
{
    render()
    {
        const { token } = this.props;

        return (
            <BrowserRouter>
                <Switch>
                    <Route component={Login} exact path="/login" />
                    {/* <Route component={Registration} exact path="/registration" /> */}
                    <Route component={ConfirmedRecovery} exact path="/recovery/confirmed" />
                    <Route component={Error404} exact path="/404" />
                    <Route component={Recovery} path="/recovery" />
                    <Route
                        path="/"
                        render={ () =>
                            token
                                ? <Layout />
                                : <Redirect to="/login" />
                        }
                    />
                </Switch>
                <AuthVerify logout={this.props.logout} />
            </BrowserRouter>
        );
    }
}

export default connect(
    state => state.user,
    { logout }
)(Router);
