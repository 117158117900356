import request from "@/utils/request.js";
import { addItem } from "./crud.js";
import { upload } from "./document.js";
import { getTemplates as getOperationsTemplates, generateTemplateFromDraft } from "./template.js";

const url = "/cooperation";

export default url;

export function create(data)
{
    return addItem(url, data);
}

export function getAmount()
{
    return request({
        url: `${url}/amount`,
        method: "get"
    });
}

export function getTemplates()
{
    return getOperationsTemplates(url);
}

export function downloadTemplateFromDraft(guid, draft)
{
    return generateTemplateFromDraft(url, guid, draft);
}

export function uploadDocument(ownerId, file, data)
{
    return upload(url, ownerId, file, data);
}
