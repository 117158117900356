import { Customers } from "./customers";
import { Applications } from "./applications";
import { Loans } from "./loans";
import { Deposits } from "./deposit";
import { HelpDesk } from "./helpDesk";
import { OperationsUpcoming } from "./operationsUpcoming";
import { CommitedOperations } from "./commitedOperations";

import { Employees } from "../roles";

export const UserPanel = {
    Section: Employees,
    Customers,
    Applications,
    Loans,
    Deposits,
    HelpDesk,
    CommitedOperations,
    OperationsUpcoming
};
