import request from "@/utils/request.js";
import { upload as uploadCrud, editItem, getItem, getList } from "./crud.js";
import { download, upload, downloadArchive } from "./document.js";
import { getTemplates as getDepositsTemplates, generateTemplateFromDraft, generateTemplate } from "./template.js";
import isArray from "lodash/isArray";

const url = "/deposit";

export const archiveUrl = `${url}/archive`;

export default url;

export function create(data, contract, cashOrder)
{
    const formData = new FormData();

    formData.append("contract", contract);
    formData.append("cashOrder", cashOrder);

    Object.entries(data).forEach(([key, value]) =>
    {
        if (value)
        {
            if (isArray(value))
            {
                value.forEach(item => formData.append(key, item));

                return;
            }

            formData.append(key, value);
        }
    });

    return uploadCrud(url, formData);
}

export function update(data)
{
    return editItem(url, data);
}

export function getDetails(id)
{
    return getItem(url, id);
}

export function getCustomerDetails(id)
{
    return request({
        url: `${url}/${id}/for-customer`,
        method: "get"
    });
}

export function getTemplates()
{
    return getDepositsTemplates(url);
}

export function getClosingAmount(id)
{
    return request({
        url: `${url}/${id}/closing-amount`,
        method: "get"
    });
}

export function uploadDocument(ownerId, file, data)
{
    return upload(url, ownerId, file, data);
}

export function downloadDocument(guid)
{
    return download(url, guid);
}

export function downloadAllInArchive(guid)
{
    return downloadArchive(url, guid);
}

export function downloadTemplateFromDraft(guid, draft)
{
    return generateTemplateFromDraft(url, guid, draft);
}

export function downloadTemplate(id, guid)
{
    return generateTemplate(url, id, guid);
}

export function getAllowedTypes(id)
{
    return getList(`${url}/${id}/allowed-op-types`);
}
