import request from "@/utils/request.js";
import { upload as uploadCrud, getItem, editItem } from "./crud.js";
import { download, upload, downloadArchive } from "./document.js";
import { getTemplates as getLoansTemplates, generateTemplateFromDraft, generateTemplate } from "./template.js";

const url = "/loan";

export const archiveUrl = `${url}/archive`;

export const overdueUrl = `${url}/overdue`;

export default url;

export function create(data, contract, cashOrder)
{
    const formData = new FormData();

    formData.append("contract", contract);
    formData.append("cashOrder", cashOrder);

    Object.keys(data).forEach(key =>
    {
        if (data[key])
        {
            formData.append(key, data[key]);
        }
    });

    return uploadCrud(url, formData);
}

export function getDetails(id)
{
    return getItem(url, id);
}

export function getCustomerDetails(id)
{
    return request({
        url: `${url}/${id}/for-customer`,
        method: "get"
    });
}

export function update(data)
{
    return editItem(url, data);
}

export function getTemplates()
{
    return getLoansTemplates(url);
}

export function getClosingAmount(id)
{
    return request({
        url: `${url}/${id}/closing-amount`,
        method: "get"
    });
}

export function uploadDocument(ownerId, file, data)
{
    return upload(url, ownerId, file, data);
}

export function downloadDocument(guid)
{
    return download(url, guid);
}

export function downloadAllInArchive(guid)
{
    return downloadArchive(url, guid);
}

export function downloadTemplateFromDraft(guid, draft)
{
    return generateTemplateFromDraft(url, guid, draft);
}

export function downloadTemplate(id, guid)
{
    return generateTemplate(url, id, guid);
}
